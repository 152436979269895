@if (!isMobile) {
  <div class="manage-bin-modal" rtlDiv>
    <div class="manage-bin-header">{{ 'TITLES.MANAGE_TRUCKS' |  translate }}</div>
    <form [formGroup]="ElForm">
      <div class="manage-bit-body">
        <div class="bin-name-container">
          <div class="sub-title">{{ 'TITLES.TRUCK_ID' | translate }}</div>
          @if (translationsObj) {
            <div class="bin-name-input truck-name-field">
              <input formControlName="truckId" type="text" class="input-bin-name" name="truckName" />
            </div>
          }
        </div>
        <div class='bin-name-container' rtlDiv>
          <div class='sub-title' rtlDiv>{{ 'TITLES.SITE_NAME' | translate }}</div>
          <mat-form-field class='workplan-input-manage' rtlDiv>
            <mat-select formControlName="siteName" class="deep" name="site" rtlDiv>
              @for (item of sitesListData; track item) {
                <mat-option class="no-checkbox list-item" [value]="item" rtlDiv>{{item.site_name}}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="bin-name-container">
          <div class="sub-title">{{'TITLES.TRUCK_CAPACITY' | translate }}</div>
          <div class="bin-name-input">
            <input formControlName="truckCapacity" type="number" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}"
            name="truckName" /></div>
          </div>
          <div class="bin-name-container">
            <div class="sub-title">{{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</div>
            <div class="bin-name-input">
              <input formControlName="maxNumOfBinsToCollect" type="number" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" />
            </div>
          </div>
          <div class="accordion-dates">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="box-shadow" hideToggle>
                <mat-expansion-panel-header class="acc-hover">
                  <mat-panel-title class="custom-dates"> 
                    {{'TITLES.CUSTOM_DATES' | translate }}
                  </mat-panel-title>
                  <mat-panel-description class="panel-desc">
                    @if (panelOpenState) {
                      <img src="assets/images/inventory/chevron-up.svg" alt="" rtlDiv>
                    }
                    @if (!panelOpenState) {
                      <img src="assets/images/inventory/chevron-down.svg" alt="" rtlDiv>
                    }
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="flex-container-titles">
                  <div class="title-font">{{'NEW_WORKPLAN.DAY' | translate }}</div>
                  <div class="title-font">{{'SITE_MANAGMENT.BINS' | translate }}</div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.SUNDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customSunday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="sunday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.MONDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customMonday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="monday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.TUESDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customTuesday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="tuesday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.WEDNESDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customWednesday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="wednesday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.THURSDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customThursday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="thursday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.FRIDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customFriday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="friday" />
                  </div>
                </div>
                <div class="flex-container">
                  <div class="day-title">{{'NEW_WORKPLAN.SATURDAY' | translate }}</div>
                  <div class="input-frame">
                    <input formControlName="customSaturday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="saturday" />
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="bin-name-container">
            <div class="sub-title">{{'TITLES.LICENSE_PLATE' | translate }}</div>
            <div class="bin-name-input">
              <input type="number" formControlName="truckLicensePlate" class="input-bin-name" name="truckName" />
            </div>
          </div>
          <div class="bin-name-container">
            <div class="sub-title" rtlDiv>{{ 'LIST_COLUMNS.ROUTE_TYPE' | translate }}</div>
            <mat-form-field class='workplan-input-manage' rtlDiv>
              <mat-select formControlName="routeType" class="deep" name="route" rtlDiv>
                @for (item of routeListData; track item) {
                  <mat-option class="no-checkbox list-item" [value]="item" rtlDiv>
                    {{currentLang != 'iw' ? item.optimization_type_name : item.optimization_type_name_hebrew}}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
    </form>
      <div class='main-btns-wrapper'>
        <div class='btns-container' rtlDiv>
          <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
          <div [ngClass]="{'apply-btn' : ElForm.valid,'not-apply-btn' : !ElForm.valid}" (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
        </div>
      </div>
    </div>
  }

  @if (isMobile) {
    <div>
      <div class="mobile-modal-title" rtlDiv>
        <div class="dflex" (click)="closeModal()">
          <img [ngClass]="{'rotate-arrow':currentLang == 'iw'}" src="assets/images/sidemenu/back-arrow.svg" alt=""/>
        </div>
        <div>
          <span class="main-title">{{ 'TITLES.MANAGE_TRUCKS' | translate }}</span>
        </div>
      </div>
      <div class="mobile-popup-container" rtlDiv>
        <form [formGroup]="ElForm">
          <div class="mobile-prop-frame">
            <div class="events-type-container">
              <div class="mobile-bin-name-container" rtlDiv>
                <div class="mobile-sub-title">{{ 'TITLES.TRUCK_ID' | translate }}</div>
                <div class="event-type-input event-types" rtlDiv>
                  @if (translationsObj) {
                    <div class="bin-name-input">
                      <input formControlName="truckId" type="text" class="input-bin-name" name="truckName" rtlDiv/>
                    </div>
                  }
                </div>
              </div>
              <div rtlDiv>
                <div class='mobile-sub-title' rtlDiv>{{ 'TITLES.SITE_NAME' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <mat-select disableOptionCentering formControlName="siteName" class="deep" name="site" rtlDiv>
                    @for (item of sitesListData; track item) {
                      <mat-option class="list-item" [value]="item" rtlDiv>{{item.site_name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mobile-bin-name-container">
                <div class="mobile-sub-title">{{'TITLES.TRUCK_CAPACITY' | translate }}</div>
                <div class="event-type-input event-types" rtlDiv>
                  <div class="bin-name-input">
                    <input formControlName="truckCapacity" type="number" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" rtlDiv/>
                  </div>
                </div>
              </div>
              <div class="mobile-bin-name-container">
                <div class="mobile-sub-title">{{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</div>
                <div class="event-type-input event-types" rtlDiv>
                  <div class="bin-name-input">
                    <input rtlDiv formControlName="maxNumOfBinsToCollect" type="text" [ngClass]="{'disable-input-value': PartialManagement,'input-bin-name': !PartialManagement}" name="truckName" />
                  </div>
                </div>
              </div>
              <div class="accordion-dates">
                <mat-accordion>
                  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="box-shadow" hideToggle>
                    <mat-expansion-panel-header class="acc-hover">
                      <mat-panel-title class="custom-dates"> 
                        {{'TITLES.CUSTOM_DATES' | translate }}
                      </mat-panel-title>
                      <mat-panel-description class="panel-desc">
                        @if (panelOpenState) {
                          <img src="assets/images/inventory/chevron-up.svg" alt="" rtlDiv>
                        }
                        @if (!panelOpenState) {
                          <img src="assets/images/inventory/chevron-down.svg" alt="" rtlDiv>
                        }
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="flex-container-titles">
                      <div class="title-font">{{'NEW_WORKPLAN.DAY' | translate }}</div>
                      <div class="title-font">{{'SITE_MANAGMENT.BINS' | translate }}</div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.SUNDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customSunday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="sunday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.MONDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customMonday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="monday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.TUESDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customTuesday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="tuesday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.WEDNESDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customWednesday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="wednesday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.THURSDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customThursday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="thursday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.FRIDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customFriday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="friday" />
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="day-title">{{'NEW_WORKPLAN.SATURDAY' | translate }}</div>
                      <div class="input-frame">
                        <input formControlName="customSaturday" [ngClass]="{'disable-input-value': PartialManagement}" class="input-val" type="number" name="saturday" />
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="mobile-bin-name-container">
                <div class="mobile-sub-title">{{'TITLES.LICENSE_PLATE' | translate }}</div>
                <div class="event-type-input event-types" rtlDiv>
                  <div class="bin-name-input">
                    <input type="number" formControlName="truckLicensePlate" class="input-bin-name" name="truckName" rtlDiv/>
                  </div>
                </div>
              </div>
              <div>
                <div class="mobile-sub-title" rtlDiv>{{ 'LIST_COLUMNS.ROUTE_TYPE' | translate }}</div>
                <mat-form-field class="event-type-input event-types" rtlDiv>
                  <mat-select disableOptionCentering formControlName="routeType" class="deep" name="route" rtlDiv>
                    @for (item of routeListData; track item) {
                      <mat-option class="list-item" [value]="item" rtlDiv>
                        {{currentLang != 'iw' ? item.optimization_type_name : item.optimization_type_name_hebrew}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <div class='mobile-main-btns-wrapper'>
          <div class='btns-container' rtlDiv>
            <div class='cancel-btn' (click)="closeModal()">{{ 'MANAGE_BIN.CANCEL' | translate }}</div>
            <div [ngClass]="{'apply-btn' : ElForm.valid,'not-apply-btn' : !ElForm.valid}" (click)='saveWorkplan()'>{{ 'MANAGE_BIN.SAVE_CHANGES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  }
