@if (!isMobile) {
  <div>
    <div class="truck-acivity-container">
      <mat-card class="truck-acivity">
        <div class='spinner-container' [hidden]="!spinnerActive">
          <div class="spinner"></div>
        </div>
        <mat-card-content class="activity-content" [hidden]="spinnerActive">
          <div class="truck-acivity-frame">
            <div class="truck-acivity-frame">
              <div class='drop-down-main-wrapper active-trucks'>
                <div class="drop-down-btn" (click)="openDropDownList('active-sites')">
                  <div class="truck-acivity-frame">
                    <img class='drop-down-icon' rtlDiv src="../assets/images/workplans-page/site_icon.svg" alt="truck_icon"> {{
                      'TRUCK_MAP.ACTIVE_SITE' | translate }}
                      <span class='current-active-truck' title="{{currentSite.plan ? currentSite.plan.site_name : ''}}">: {{currentSite.plan ? currentSite.plan.formatted_name : ''}}</span>
                  </div>
                  <img class="drop-down-btn-arrows btn-arrows-active-truck top"
                    src="assets/images/dashboard/dropdown_arrow.svg" alt="" rtlDiv />
                  </div>
                  <!-- *ngIf="!closeDropDown" -->
                  <div class="drop-down-list-container active-trucks" [hidden]="!showActiveSitesDropDownList">
                    <div class="search-bin-container">
                      <input id="search_site" class="search-input" placeholder="{{'TRUCK_MAP.CHANGE_SITE_SEARCH' | translate }}"
                        (input)="searchSite($event.target)" />
                        <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                      </div>
                      @for (item of activeSitesToDisplay; track item) {
                        <div class="drop-down-list-item active-trucks sites-list" (click)="selectSite(item, true)"
                          rtlDiv>
                          {{(item.plan.actual_plan_start_time != null && item.plan.actual_plan_start_time != "0000-00-00 00:00:00" ? item.plan.actual_plan_start_time : item.plan.plan_start_time) | date:'HH:mm'}} {{item.plan.site_name}}
                          <div class='line-separator'></div>
                        </div>
                      }
                    </div>
              </div>
              <div class='drop-down-main-wrapper active-trucks'>
                <div class="drop-down-btn" (click)="openDropDownList('active-trucks')">
                  <div class="truck-acivity-frame">
                    <img class='drop-down-icon' rtlDiv src="../assets/images/workplans-page/truck_driver_icon.svg" alt="truck_icon"> {{
                      'TRUCK_MAP.ACTIVE_TRUCK' | translate }}
                      <span class='current-active-truck'>:{{
                      'TRUCK_MAP.TRUCK' | translate }} {{currentTruck ? currentTruck.tablet_id : ''}}</span>
                  </div>
                  <img class="drop-down-btn-arrows btn-arrows-active-truck top"
                    src="assets/images/dashboard/dropdown_arrow.svg" alt="" rtlDiv />
                  </div>
                  <!-- *ngIf="!closeDropDown" -->
                  <div class="drop-down-list-container active-trucks" [hidden]="!showActiveTrucksDropDownList">
                    <div class="search-bin-container">
                      <input id="search_truck" class="search-input" placeholder="{{'TRUCK_MAP.CHANGE_TRUCK_SEARCH' | translate }}"
                        (input)="searchTruck($event.target)" />
                        <img class='search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                      </div>
                      @for (item of activeTrucksToDisplay; track item) {
                        <div class="drop-down-list-item active-trucks" (click)="selectTruck(item, true)"
                          rtlDiv>
                          {{'TRUCK_MAP.TRUCK' | translate }}_{{item.tablet_id}}
                          <div class='line-separator'></div>
                        </div>
                      }
                    </div>
              </div>
                @if (isAdmin && eventSelected !=undefined && eventSelected.userGenerated == 'currentActive') {
                  <div class='drop-down-main-wrapper add-remove-bins' rtlDiv>
                    <div class="drop-down-btn change-bin" (click)="openDropDownList('add-remove-bins')">
                      {{'TRUCK_MAP.CHANGE_BIN' | translate }}
                    </div>
                  </div>
                }
            </div>
              </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        }

        @if (isMobile) {
          <div>
            <div class="md-accordion" id="activeBinsAc" role="tablist" aria-multiselectable="true">
              <div class="card mobile-truck-location">
                <div class="card-header mobile-header">
                  <div class="mobile-flex flex-between">
                    <span class="mobile-title"> {{ 'TITLES.WORKPLAN' | translate }}</span>
                    <div class="mobile-flex-center">
                      @if (isAdmin && eventSelected !=undefined && eventSelected.userGenerated == 'currentActive') {
                        <div class='drop-down-main-wrapper add-remove-bins' rtlDiv>
                          <div class="drop-down-btn mobile-change-bin" (click)="openDropDownList('add-remove-bins')">
                          {{'TRUCK_MAP.CHANGE_BIN' | translate }}
                        </div>
                      </div>
                    }
                  </div>
                  <!----------------------------------------->
                </div>
              </div>
              <div class="collapse show" role="tabpanel" data-parent="#activeBinsAc">
                <div class="card-body"  id="truck-map-container">
                  <div [ngClass]="{'mobilehidden': !spinnerActive}" class="spinner-border mobile-spinner" role="status">
                    <span class="sr-only"></span>
                  </div>
                  <div class="mobile-content" [ngClass]="{'mobilehidden': spinnerActive}">
                    <div>
                      <div class="mobile-manager-title">
                        <div class="mobile-active-site-img-container" rtlDiv>
                          <img src="../assets/images/workplans-page/site_icon.svg" alt="truck_icon">
                        </div>
                        <span class="mobile-active-site-title" rtlDiv>{{'TRUCK_MAP.ACTIVE_SITE' | translate }}</span>
                      </div>
                      <div class='mobile-active-site-container' rtlDiv>
                        <div class="mobile-active-site-frame" (click)="openDropDownList('active-sites')">
                          <div class="dflex">
                            <div class="mobile-active-site-dropDown-title" rtlDiv>
                              <span class='mobile-current-active-site' title="{{currentSite.plan ? currentSite.plan.site_name : ''}}" rtlDiv>{{currentSite.plan ? currentSite.plan.formatted_name : ''}}</span>
                            </div>
                          </div>
                          <div class="mobile-active-site-arrow" rtlDiv>
                            <img class="mobile-active-site-arrow-img"
                              src="assets/images/dashboard/dropdown_arrow.svg" alt="" rtlDiv />
                            </div>
                          </div>
                          <div class="mobile-active-site-dropDown" [hidden]="!showActiveSitesDropDownList">
                            <div class="mobile-active-site-search-container">
                              <input id="search_site" class="mobile-active-site-search" placeholder="{{'TRUCK_MAP.CHANGE_SITE_SEARCH' | translate }}"
                                (input)="searchSite($event.target)" />
                                <img class='mobile-search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                              </div>
                              <div class="mobile-active-sites-list" rtlDiv>
                                @for (item of activeSitesToDisplay; track item) {
                                  <div (click)="selectSite(item, true)" class="mobile-active-site-item-container">
                                    <div class="mobile-active-site-item">{{(item.plan.actual_plan_start_time != null && item.plan.actual_plan_start_time != "0000-00-00 00:00:00"? item.plan.actual_plan_start_time : item.plan.plan_start_time) | date:'HH:mm'}} {{item.plan.site_name}}</div>
                                    @if (activeSitesToDisplay.indexOf(item) < activeSitesToDisplay.length-1) {
                                      <div class='mobile-line-seperator'></div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="mobile-manager-title">
                            <div class="mobile-active-site-img-container" rtlDiv>
                              <img src="../assets/images/workplans-page/truck_driver_icon.svg" alt="truck_icon">
                            </div>
                            <span class="mobile-active-site-title" rtlDiv>{{'TRUCK_MAP.ACTIVE_TRUCK' | translate }}</span>
                          </div>
                          <div class='mobile-active-truck-container' rtlDiv>
                            <div class="mobile-active-site-frame" (click)="openDropDownList('active-trucks')">
                              <div class="dflex">
                                <div class="mobile-active-site-dropDown-title" rtlDiv>
                                  <span class='mobile-current-active-site' rtlDiv>{{'TRUCK_MAP.TRUCK' | translate }} {{currentTruck ? currentTruck.tablet_id : ''}}</span>
                                </div>
                              </div>
                              <div class="mobile-active-site-arrow" rtlDiv>
                                <img class="mobile-active-site-arrow-img"
                                  src="assets/images/dashboard/dropdown_arrow.svg" alt="" rtlDiv />
                                </div>
                              </div>
                              <div class="mobile-active-site-dropDown" [hidden]="!showActiveTrucksDropDownList" rtlDiv>
                                <div class="mobile-active-site-search-container" rtlDiv>
                                  <input id="search_truck" class="mobile-active-site-search" placeholder="{{'TRUCK_MAP.CHANGE_TRUCK_SEARCH' | translate }}"
                                    (input)="searchTruck($event.target)" />
                                    <img class='mobile-search-icon' src="assets/images/reports/dropdown_search_icon.svg" alt="" rtlDiv />
                                  </div>
                                  <div class="mobile-active-sites-list" rtlDiv>
                                    @for (item of activeTrucksToDisplay; track item) {
                                      <div class="mobile-active-site-item-container" (click)="selectTruck(item, true)" rtlDiv>
                                        <div class="mobile-active-site-item">{{'TRUCK_MAP.TRUCK' | translate }}_{{item.tablet_id}}</div>
                                        @if (activeTrucksToDisplay.indexOf(item) < activeTrucksToDisplay.length-1) {
                                          <div class='mobile-line-seperator'></div>
                                        }
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
