import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewRef } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { TranslatorService } from '../../services/translator_service';
import { ApiQuery } from '../../web-services/api/api.query';
import { ResponsiveService } from '../../services/responsive.service';
import { Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from '../../services/util.service';
import { ApiService } from '../../web-services/api/api.service';
import { Papa } from 'ngx-papaparse';
import { ApiStore } from '../../web-services/api/api.store';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { TableUtil } from '../../tableUtil';
import { ManageClusterModalComponent } from './manage-cluster-modal/manage-cluster-modal.component';
import { ManageUserModalComponent } from './manage-user-modal/manage-user-modal.component';
import { ManageBinsModalComponent } from './manage-bins-modal/manage-bins-modal.component';
import moment from 'moment';
import { AddClusterModalComponent } from './add-cluster-modal/add-cluster-modal.component';
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { ManageClustersModalComponent } from './manage-clusters-modal/manage-clusters-modal.component';
import { DeleteClustersModalComponent } from './delete-clusters-modal/delete-clusters-modal.component';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { ManageTruckModalComponent } from './manage-truck-modal/manage-truck-modal.component';
import { ManageBinModalComponent } from './manage-bin-modal/manage-bin-modal.component';
import { ManageSiteModalComponent } from './manage-site-modal/manage-site-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from '../pagination/pagination.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { ClusterDataModalComponent } from './cluster-data-modal/cluster-data-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddTruckModalComponent } from './add-truck-modal/add-truck-modal.component';
import { DeleteTrucksModalComponent } from './delete-trucks-modal/delete-trucks-modal.component';
import { GenericColumnsManagingModalComponent } from '../generic-columns-managing-modal/generic-columns-managing-modal.component';

@Component({
  selector: 'app-inventory',
  standalone: true,
  imports: [TranslateModule,MatDividerModule,MatTabsModule,MatCardModule,CommonModule,PaginationComponent,MatCheckboxModule,
    MatTableModule,MatToolbarModule,LottieComponent,FormsModule,ReactiveFormsModule,MatSortModule,RTLDivDirectiveDirective,
	NgbModule,RouterModule],
  templateUrl: './inventory.component.html',
  animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		  ])
	  ],
  styleUrl: './inventory.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryComponent {
	isBinDeviceIdColChecked: any;
	isBinCollectionModeChecked: any;
	isBinCollectionDaysChecked: any;
	expandedElement: any;
	isBinPredictedTimeForMaxAllowedCapacityChecked: any;
	isBlackStatusChecked: any;
	isBinIdColChecked: any;
	isBinSiteColChecked: any;
	isBinNameColChecked: any;
	isNeighborhoodChecked: any;
	isBinTypeColChecked: any;
	isBinInstallDateColChecked: any;
	isBinAvgRateColChecked: any;
	isBinAvgUsageRateChecked: any;
	isBinCollectionFrequencyChecked: any;
	isAvgDailyRateDunamColChecked: any;
	binFullInfo: any;
	TruckFullInfo:any;
	sitesFullInfo:any;
	usersFullInfo:any;
	clustersFullInfo:any;
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	selection = new SelectionModel<Element>(true, []);
	truckSelection = new SelectionModel<Element>(true, []);
	siteSelection = new SelectionModel<Element>(true, []);
	userSelection = new SelectionModel<Element>(true, []);
	clusterSelection = new SelectionModel<Element>(true, []);
	showlist:boolean = false;
	dataSource: any = new MatTableDataSource<any>();
	trucksDataSource :any = new MatTableDataSource<any>();
	sitesDataSource :any = new MatTableDataSource<any>();
	usersDataSource:any = new MatTableDataSource<any>();
	clustersDataSource :any= new MatTableDataSource<any>();
	binsData:Array<any> = [];
	trucksData:Array<any> = [];
	sitesData:Array<any> = [];
	usersData:Array<any> = [];
	clustersData:Array<any> = [];
	displayedColumns = ['select', 'name','site_name', 'id','device_id','type', 'address','neighborhood','cluster_id','description','size','installation_Date', 'last_collection','last_refill', 'last_update', 'status','avg_rate','avg_usage_rate','collection_frequency','emptying_dunam','predicted_time_for_max_allowed_capacity','black_status','collection_mode','collection_days', 'manage'];
	displayedTrucksColumns = ['select', 'truck_id', 'driver_name', 'site_name','truck_volume','max_capacity','license_plate','delete','edit'];
	displayedSitesColumns = ['select', 'site_name','Distribution_method','Plan_start_time','Plan_finish_time','max_allowed_capacity','Max_uncollected_days','Avg_capacity_for_future_workplan','Max_days_gap_for_future_workplan','Set_Fixed_Days_To_Collect','Set_Max_Volume','edit'];
	displayedUsersColumns = ['select', 'first_name', 'last_name', 'user_email','user_phone','user_company','user_permission','user_alert','delete','edit'];
	displayedClustersColumns = ['select', 'cluster_id', 'cluster_name', 'site_name','cluster_type','min_capacity','max_capacity','bins_number','delete','edit'];
	columnsToDisplay = ['name','cluster_id', 'status','address','description','size','last_collection','last_refill','last_update','neighborhood','site_name','id','type','installation_Date','avg_rate','avg_usage_rate','collection_frequency','emptying_dunam','predicted_time_for_max_allowed_capacity','collection_mode','device_id','arrowDown'];
	truckscolumnsToDisplay = ['select','truck_id', 'max_capacity','arrowDown'];
	clusterscolumnsToDisplay = ['select','cluster_id', 'cluster_name', 'arrowDown'];
	neighborhood:boolean = false;
	binSiteCol:boolean = false;
	binCollectionFrequencyCol:boolean = false;
	binAvgUsageRateCol:boolean = false;
	binIdCol:boolean = false;
	binNameCol:boolean = false;
	binTypeCol:boolean = false;
	binInstallDateCol:boolean = false;
	binAvgRateCol:boolean = false;
	avgDailyRateDunamCol:boolean = false;
	binCollectionModeCol : boolean = false;
	binPredictedTimeForMaxAllowedCapacityCol: any;
	binBlackStatusCol : boolean = false;
	binCollectionDaysCol : boolean = false;
	binDeviceIdCol: boolean = false;
	dataTableLength = 0;
	trucksTableLength = 0;
	sitesTableLength = 0;
	usersTableLength = 0;
	clustersTableLength = 0;
	tempFilter = '';
	tempTruckFilter = '';
	tempSiteFilter = '';
	tempUserFilter = '';
	tempClusterFilter = '';
	startIndexTableData = 0;
	endIndexTableData = 9;
	startIndexTableDataTrucks = 0;
	endIndexTableDataTrucks = 9;
	startIndexTableDataSites = 0;
	endIndexTableDataSites = 9;
	startIndexTableDataUsers = 0;
	endIndexTableDataUsers = 9;
	startIndexTableDataClusters = 0;
	endIndexTableDataClusters = 9;
	selectedSite:any[] = [];
	currentLang:any;
	translationsObj:any;
	isMobile: boolean = false;
	spinnerExist : boolean = false;
	capacityDisplayType: number = 0;
	spinnerActive:boolean = true;
	spinnerTrucksActive:boolean = true;
	spinnerSitesActive:boolean = true;
	spinnerUsersActive:boolean = true;
	spinnerClustersActive:boolean = true;
	filteredBinsData:Array<object> = [];
	filteredTrucksData:Array<object> = [];
	filteredSitesData:Array<object> = [];
	filteredUsersData:Array<object> = [];
	filteredClustersData:Array<object> = [];
	usageType = 1;
	user_role_id = 0;
	ElForm: FormGroup;
	editBlackStatusFlag:boolean = true;
	blackStatusValue:number = 90;
	binsBackUp:Array<any> = [];
	showLoadingForCurrentCapacityAnalysis:boolean = true;
	showLoadingForCollectionDays:boolean = true;
	private readonly destroy$ = new Subject();
	options: AnimationOptions = {
		path: "/assets/images/skeleton.json"
	};
	displayedFields = {
		isAll : true
	};
	clusterData: Array<any> = [];
	placement_tooltip: string;
	inventorySelectedValues = {
		isBinNameChecked: false,
		isAddressChecked: false,
		isClusterIdChecked: false,
		isBinDescChecked: false,
		isQuarterAcreChecked: false,
		isLastCollectionChecked: false,
		isLastRefillChecked: false,
		isLastUpdateChecked: false,
		isStatusChecked: false,
		isNeighborhoodChecked: false,
		isBinSiteColChecked: false,
		isBinIdColChecked: false,
		isBinTypeColChecked: false,
		isBinInstallDateColChecked: false,
		isBinAvgRateColChecked: false,
		isBinAvgUsageRateChecked: false,
		isBinCollectionFrequencyChecked: false,
		isAvgDailyRateDunamColChecked: false,
		isBinPredictedTimeForMaxAllowedCapacityCol: false,
		isBinCollectionModeChecked: false,
		isBinDeviceIdColChecked: false
	};
	

	constructor(private translator: TranslatorService, private papa: Papa,
		private utilService: UtilService, private router: Router,private responsiveService: ResponsiveService,private apiQuery:ApiQuery,
		private apiService:ApiService,private dialog: MatDialog,private fb: FormBuilder,private apiStore:ApiStore,
		private cd: ChangeDetectorRef) {
			this.ElForm = this.fb.group({
				blackStatusBin :new FormControl(90)
			});
	}

	ngOnInit() {
		this.onResize();
		this.responsiveService.checkWidth();
		this.apiService?.GetDynamicColumns()?.subscribe((column:any) => {
			if(column.DynamicColumns != null && column.DynamicColumns.length > 0){
				_.each(column.DynamicColumns, (item) => {	
					if(item.is_activate_for_current_user){
						this.showColumn(item["dynamic_column_id"].toString());
					}				
				});
			}				
		});

		this.apiQuery.user$.subscribe(user => {
			if (!user) return;
			this.capacityDisplayType = user["user_default_capacity_type_id"];
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	

			if(this.usageType == 1){
				this.inventorySelectedValues.isBinNameChecked = true;
				this.inventorySelectedValues.isStatusChecked = true;
			}else{
				this.inventorySelectedValues.isStatusChecked = true;
				this.inventorySelectedValues.isClusterIdChecked = true;
			}
			
			this.user_role_id = user["user_role_id"];
		});

		combineLatest(
			this.apiQuery.filteredBins$,
			this.apiQuery.sitesClustersList$,
			this.apiQuery.ticketsList$
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(([bins,clusters,tickets])=>{						
				this.selection.clear();
				this.spinnerActive = true;		
				this.cd.detectChanges();
				if(bins.length == 0) return;
				this.binsBackUp = bins;
				this.translator.currentLangEmitter$.pipe(takeUntil(this.destroy$))
				.subscribe(async value=>{						
				  this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
				  this.currentLang = value;	
				  this.placement_tooltip = this.currentLang != 'iw' ? 'top-left' : 'top-right';
				  this.spinnerClustersActive = true;	
				  this.cd.detectChanges();	
				  if(clusters.length == 0) return;
				  this.clustersFullInfo = clusters;	
				  this.clusterSelection.clear();
				  if(this.binsBackUp.length == 0) return;
				  this.spinnerClustersActive = false;	
									  
				  const clustersFiltered = _.map(clusters, b => {
					  return {
						  cluster_id: b["cluster_id"],
						  cluster_name:b["cluster_name"],		
						  site_name: b["site_name"],
						  cluster_type_name: b["cluster_type_name"],
						  min_required_capacity_for_collection: b["min_required_capacity_for_collection"],
						  cluster_max_allowed_capacity: b["cluster_max_allowed_capacity"],
						  number_of_bins_in_cluster: _.filter(this.binsBackUp, bin => bin["cluster_id"] == b["cluster_id"] && bin["site_id"] == b["site_id"]).length,
						  cluster_type_name_hebrew:b["cluster_type_name_hebrew"],
						  cluster_type_id: b["cluster_type_id"],
						  site_id: b["site_id"],
						  bins: _.filter(this.binsBackUp, bin => bin["cluster_id"] == b["cluster_id"] && bin["site_id"] == b["site_id"])
					  };
				  });	
				  this.clustersData = !_.isEmpty(clusters[0]) ? clustersFiltered : [];									
				  this.clustersTableLength = this.clustersData.length;
				  this.clustersDataSource = new MatTableDataSource<any>(clustersFiltered);
				  this.sortClustersData({ direction: 'desc', active: 'cluster_name' });
				  
				  _.each(_.groupBy(this.binsBackUp, item => `${item.cluster_id},${item.site_id}`) , (clusters) => {
					  _.each(clusters, (bin) => {
						  bin["number_of_bins_in_cluster"] = clusters.length;
					  });
				  });
				  _.each(this.binsBackUp, (bin) => {
					  if(bin.number_of_bins_in_cluster > 1){
						  let chosenCluster = this.clustersData.filter(
							  (cluster) =>
								cluster["site_id"] == bin.site_id &&
								cluster["cluster_id"] == bin.cluster_id
						  );
						  if(chosenCluster.length > 0){
							  let collection_rate = 0;
							  let avg_collection_rate = 0;

							  let collection_volume = 0;
							  let avg_collection_volume = 0;
							  let cluster_avg = 0;
							  _.each(chosenCluster[0]['bins'], (bin) => {
								  collection_rate += bin["Bin_live_Data"].bin_collection_or_refill_rate;
								  collection_volume += bin["Bin_live_Data"].average_collections_capacity_in_percent;
								  cluster_avg += bin["Bin_live_Data"].fill_level_percent;
							  });
							  avg_collection_rate = collection_rate / bin.number_of_bins_in_cluster;
							  chosenCluster[0].avg_collection_rate = avg_collection_rate;
							  avg_collection_volume = collection_volume / bin.number_of_bins_in_cluster;
							  chosenCluster[0].avg_collection_volume = avg_collection_volume;
							  chosenCluster[0].cluster_avg = cluster_avg / bin.number_of_bins_in_cluster;

							  bin["chosenCluster"] = chosenCluster[0];
						  }
					  }
				  });

				  _.each(this.binsBackUp, (bin) => {
					if(this.usageType == 1){
						bin["calcDiff_lastCollection"] = this.calcDiff(bin["Bin_live_Data"].last_collection_time);
					}else{
						bin["calcDiff_lastRefill"] = this.calcDiff(bin["Bin_live_Data"].last_collection_time);
					}
					bin["calcDiff_lastUpdate"] = this.calcDiff(bin["Bin_live_Data"].Transmission_data.last_transmission_time);
				  });

				  let openedTickets = [];	
				  if(tickets.length == 0){return}
				  _.each(tickets, (item) => {					
					  if(item["TicketsList"]){
						  if(item["TicketsList"]["open"]!=null){			
							  _.each(item["TicketsList"]["open"], (ticket) => {	
								  ticket.site_name = item["site_name"];
								  ticket.site_id = item["site_id"];
								  ticket.status = 1;
								  this.getRelevantBin(bins,ticket,"1");
								  openedTickets.push(ticket);		
							  });		
						  }	
					  }		
				  });
				  _.each(this.binsBackUp, (bin) => {
					  let isOpenTicket = _.filter(openedTickets, ticket => ticket.bin_id == bin.bin_id && ticket.site_id == bin.site_id);
					  if(isOpenTicket.length > 0){
						  bin["isOpenTicket"] = true;
					  }else{
						  bin["isOpenTicket"] = false;
					  }
				  });

				  if(this.binCollectionDaysCol){
					this.getCollectionDays(true);
				  }else if(this.binBlackStatusCol){
					this.getBlackStatus();
				  }else{
					this.initBinsTable(this.binsBackUp);	
				  }
				  this.spinnerActive = false;		
				  this.cd.detectChanges();
				});
			});

		this.apiQuery.filteredTrucks$.subscribe(trucks => {		
			this.spinnerTrucksActive = true;		
			this.cd.detectChanges();
			if(trucks.length == 0) return;
			this.spinnerTrucksActive = false;
			this.TruckFullInfo = trucks;
			this.truckSelection.clear();
			const trucksFiltered = _.map(trucks, (b:any) => {
				return {
					truck_id: b["tablet_id"],
					driver_name: b["current_assigned_driver_name"],
					site_name: b["site_name"],
					truck_volume:b["max_capacity_in_liters"],		
					max_capacity:b["max_capacity_in_bins"],
					license_plate: b["assigned_truck_license_plate"],
					id:	b["tablet_id"]
				};
			});		
			this.trucksData = !_.isEmpty(trucks[0]) ? trucksFiltered : [];			
			this.trucksTableLength = this.trucksData.length;
			this.trucksDataSource = new MatTableDataSource<any>(trucksFiltered);

			if(this.isMobile){
				if(this.trucksDataSource.filteredData.length > 0){
					this.spinnerExist = true;
				}else{
					this.spinnerExist = false;			
				}
			}
			this.sortTrucksData({ direction: 'desc', active: 'site_name' });
			this.cd.detectChanges();		
		});

		this.apiQuery.sitesListData$.subscribe((sitesList) => {
			this.spinnerSitesActive = true;		
			if(sitesList.length == 0) return;
			this.spinnerSitesActive = false;
			this.sitesFullInfo = sitesList; 

			const sitesFiltered = _.map(sitesList, (b:any) => {
				return {
					site_name: b["site_name"],
					preferred_bin_distribution_method_name:b["preferred_bin_distribution_method_name"],
					preferred_plan_start_time: b["shifts"] ? b["shifts"][0]["start_time"] : '',
					preferred_plan_finish_time: b["shifts"] ? moment(b["shifts"][b["shifts"].length-1]["start_time"],"HH:mm:ss").add(Math.abs(b["shifts"][b["shifts"].length-1]["duration"]),'hours').format('HH:mm:ss') : '',
					site_max_allowed_capacity:b["site_max_allowed_capacity"],
					days_gap_for_no_collection_alert:b["days_gap_for_no_collection_alert"],
					avg_capacity_in_percent_future_work_plan_alert: b["avg_capacity_in_percent_future_work_plan_alert"],
					site_days_gap_for_future_work_plan: b["site_days_gap_for_future_work_plan"],
					customer_work_plan_collection_schedule_policy_name:b["customer_work_plan_collection_schedule_policy_name"],
					is_use_site_max_allowed_capacity:b["is_use_site_max_allowed_capacity"] == 'true' ? 1 : 0,
					customer_work_plan_collection_schedule_policy_name_hebrew:b["customer_work_plan_collection_schedule_policy_name_hebrew"],					
					preferred_bin_distribution_method_name_hebrew:b["preferred_bin_distribution_method_name_hebrew"]
				};
			});	

			this.sitesData = sitesFiltered;			
			this.sitesTableLength = this.sitesData.length;
			this.sitesDataSource = new MatTableDataSource<any>(sitesFiltered);

			if(this.isMobile){
				if(this.sitesDataSource.filteredData.length > 0){
					this.spinnerExist = true;
				}else{
					this.spinnerExist = false;			
				}
			}
			this.sortSitesData({ direction: 'desc', active: 'site_name' });
		});	

		this.apiQuery.filteredUsers$.subscribe(users => {		
			this.spinnerUsersActive = true;	
			this.cd.detectChanges();	
			if(users.length == 0) return;
			this.spinnerUsersActive = false;		
			this.usersFullInfo = users;			
			const usersFiltered = _.map(users, (b:any) => {
				return {
					first_name: b["first_name"],
					last_name: b["last_name"],
					user_email_address: b["user_email_address"],
					user_phone_number:b["user_phone_number"],		
					user_company:b["user_company"],
					user_role_name: b["user_role_name"],
					user_role_name_hebrew:	b["user_role_name_hebrew"],
					user_alert_info: b["user_alert_info"],
					user_role_id: b["user_role_id"],
					user_sites_Info: b["user_sites_Info"],
					user_id: b["user_id"],
					user_name: b["user_name"],
					usage_type_id: b["usage_type_id"],
					capacity_display_type_id: b["capacity_display_type_id"],
					account_id: b["account_id"]
				};
			});		
			this.usersData = !_.isEmpty(users[0]) ? usersFiltered : [];									
			this.usersTableLength = this.usersData.length;
			this.usersDataSource = new MatTableDataSource<any>(usersFiltered);
			this.sortUsersData({ direction: 'desc', active: 'user_id' });
			this.cd.detectChanges();
		});
	}

	ngAfterViewInit(){
		this.dataSource.sort = this.sort;
		this.sitesDataSource.sort = this.sort;
		this.trucksDataSource.sort = this.sort;
		this.usersDataSource.sort = this.sort;		
	}

	ngOnDestroy(){
		this.destroy$.next(true);
		this.destroy$.complete();
		if (!(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

	changeSubmitClickedStatus(el) {
		let wrappers = document.getElementsByClassName('mat-mdc-tab-body-wrapper') as HTMLCollectionOf<HTMLElement>;
		_.each(wrappers, (wrapper) => {
			wrapper.style.overflow = 'unset';
		});
		this.openClusterInfo(el);
	}

	getRelevantBin(bins,ticket,status){
		if(_.groupBy(ticket["ticket_id_info"], "status_id")[status]){
			if(ticket["ticketData"].ticket_event_type_id == 3){
				if(_.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name != ''){
					const relevant_bin = bins.filter(bin => bin["site_id"] == ticket.site_id && bin["bin_name"] == _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].installed_bin_name);
					if(relevant_bin.length > 0){
						ticket.bin_id = relevant_bin[0]["bin_id"];
					}else{
						ticket.bin_id = 0;
					}
				}else{
					ticket.bin_id = 0;
				}
			}else{
				ticket.bin_id = _.groupBy(ticket["ticket_id_info"], "status_id")[status][_.groupBy(ticket["ticket_id_info"], "status_id")[status].length - 1].bin_id;
			}
		}
	}

	openClusterInfo(el) {
		this.clusterData = el["chosenCluster"];
		this.openClusterInfoPopup();
	}
	
	openClusterInfoPopup() {
		let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = this.currentLang != "iw" ? "clusterInfo-container-ltr" : "clusterInfo-container-rtl";
		}
		this.dialog.open(ClusterDataModalComponent, {
		  panelClass: class_name,
		  data: {
			item: this.clusterData
		  },
		});
	}

	submitBlackStatus(){
		if(this.ElForm.getRawValue()['blackStatusBin'] < 90 || this.ElForm.getRawValue()['blackStatusBin'] > 100){
			return;
		}else{
			this.blackStatusValue = this.ElForm.getRawValue()['blackStatusBin'];
			this.getBlackStatus();
		}
	}

	initBinsTable(bins:any){
		let binsFiltered = [];
		this.binFullInfo = bins;

		if(this.usageType == 1){
			binsFiltered = _.map(bins, b => {
				return {
					bin_name: b["bin_name"],
					site_name: b["site_name"],
					bin_id: b["bin_id"],
					device_id: b["device_id"],
					bin_type:b["bin_type_name"],
					address: b["Bin_location"].bin_address,
					neighborhood:b["Bin_location"].bin_neighbourhood,
					lat: b["Bin_location"].bin_latitude,
					lon: b["Bin_location"].bin_longitude,
					description: b["bin_description"],
					bin_installation_date: this.currentLang != 'iw' ? this.utilService.convertDateEnglishWithoutTime(b["bin_installation_date"]) : this.utilService.convertDateHebrewWithoutTime(b["bin_installation_date"]),
					last_collection: b["Bin_live_Data"].last_collection_time,
					last_update: b["Bin_live_Data"].Transmission_data.last_transmission_time,
					status: b["Bin_live_Data"].fill_level_percent,		
					avg_rate : b["Bin_live_Data"].average_daily_fill_rate_in_percent,		
					avg_usage_rate : moment(b["Bin_live_Data"].Transmission_data.expected_transmission_time).format("YYYY-MM-DD 00:00:00").valueOf() < moment().format("YYYY-MM-DD 00:00:00").valueOf() ? 0 : (b["Bin_live_Data"].average_daily_usage_rate_in_percent? Number(b["Bin_live_Data"].average_daily_usage_rate_in_percent) : -1),
					collection_mode : Number(b["Bin_Restrictions"]["collection_mode"]),
					removed_reason: b["Bin_Restrictions"]["collection_mode"] == 1 ? b["Bin_Restrictions"]["removed_bin_reason_type_name"] : "",
					collection_frequency : b["Bin_live_Data"].bin_collection_or_refill_rate ? Number(b["Bin_live_Data"].bin_collection_or_refill_rate.toFixed(0)) : -1,
					predicted_time_for_max_allowed_capacity: this.currentLang != 'iw' ? (b["Bin_live_Data"].predicted_time_for_max_allowed_capacity ? this.utilService.convertDateEnglishWithoutTime(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity) : '') : (b["Bin_live_Data"].predicted_time_for_max_allowed_capacity ? this.utilService.convertDateHebrewWithoutTime(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity):''),
					blackStatus_csv: '',
					collection_days_csv: '',
					removed_reason_hebrew: b["Bin_Restrictions"]["collection_mode"] == 1 ? b["Bin_Restrictions"]["removed_bin_reason_type_name_hebrew"] : "",
					collection_days: b["collection_days"],
					active_collection_days: b["active_collection_days"],
					green_status_level_percent: b["Bin_thresholds"].green_status_fill_level_in_percent,
					yellow_status_level_percent: b["Bin_thresholds"].yellow_status_fill_level_in_percent,
					red_status_level_percent: b["Bin_thresholds"].red_status_fill_level_in_percent,
					bin_max_volume_in_liters: b["Bin_thresholds"].bin_max_volume_in_liters,
					bin_capacity_to_display: this.capacityDisplayType != 0 ? b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100 + "%" : b["Bin_live_Data"].fill_level_percent + "%",
					Bin_Restrictions : b["Bin_Restrictions"],
					Bin_thresholds: b["Bin_thresholds"],
					bin_color : b["Bin_live_Data"]["color_status_name"],
					cluster_id : b["cluster_id"],
					site_id: b["site_id"],
					over_flow_dates: b["over_flow_dates"] ? b["over_flow_dates"][0].event_num_of_day : 0,
					analysis_time_span_in_days: b["analysis_time_span_in_days"] ? b["analysis_time_span_in_days"] : 0,
					cluster_avg: b["cluster_avg"],
					number_of_bins_in_cluster: b["number_of_bins_in_cluster"],
					calcDiff_lastCollection: b["calcDiff_lastCollection"],
					calcDiff_lastUpdate: b["calcDiff_lastUpdate"],
					installation_DateTime_value : moment(b["bin_installation_date"]).format("YYYY-MM-DD HH:mm:ss").valueOf(),
					predicted_time_for_max_allowed_capacity_value : moment(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity).format("YYYY-MM-DD HH:mm:ss").valueOf(),
					chosenCluster: b["chosenCluster"],
					isOpenTicket: b["isOpenTicket"]
				};
			});
		}else{
			binsFiltered = _.map(bins, b => {
				return {
					bin_name: b["bin_name"],
					site_name: b["site_name"],
					bin_id: b["bin_id"],
					device_id: b["device_id"],
					bin_type:b["bin_type_name"],
					address: b["Bin_location"].bin_address,
					neighborhood:b["Bin_location"].bin_neighbourhood,
					lat: b["Bin_location"].bin_latitude,
					lon: b["Bin_location"].bin_longitude,
					description: b["bin_description"],
					size:b["plot_size"] != 0 ? b["plot_size"] : '',
					bin_installation_date: this.currentLang != 'iw' ? this.utilService.convertDateEnglishWithoutTime(b["bin_installation_date"]) : this.utilService.convertDateHebrewWithoutTime(b["bin_installation_date"]),
					last_refill:b["Bin_live_Data"].last_collection_time,
					last_update: b["Bin_live_Data"].Transmission_data.last_transmission_time,
					bin_capacity_to_display: this.capacityDisplayType != 0 ? b["Bin_live_Data"].fill_level_percent * b["Bin_thresholds"].bin_max_capacity_in_liters / 100 + "L" : b["Bin_live_Data"].fill_level_percent + "L",
					avg_rate : b["Bin_live_Data"].average_daily_fill_rate_in_liters,		
					emptying_dunam : b["Bin_live_Data"].average_daily_fill_rate_in_liters_per_dunam,
					removed_reason: b["Bin_Restrictions"]["collection_mode"] == 1 ? b["Bin_Restrictions"]["removed_bin_reason_type_name"] : "",
					predicted_time_for_max_allowed_capacity: this.currentLang != 'iw' ? this.utilService.convertDateEnglishWithoutTime(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity) : this.utilService.convertDateHebrewWithoutTime(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity),
					blackStatus_csv: '', 
					collection_days_csv: '',
					removed_reason_hebrew: b["Bin_Restrictions"]["collection_mode"] == 1 ? b["Bin_Restrictions"]["removed_bin_reason_type_name_hebrew"] : "",
					collection_days: b["collection_days"],
					active_collection_days: b["active_collection_days"],
					collection_mode : Number(b["Bin_Restrictions"]["collection_mode"]),
					green_status_level_percent: b["Bin_thresholds"].green_status_fill_level_in_percent,
					yellow_status_level_percent: b["Bin_thresholds"].yellow_status_fill_level_in_percent,
					red_status_level_percent: b["Bin_thresholds"].red_status_fill_level_in_percent,
					bin_max_volume_in_liters: b["Bin_thresholds"].bin_max_volume_in_liters,
					status: b["Bin_live_Data"].fill_level_percent,
					Bin_Restrictions : b["Bin_Restrictions"],
					Bin_thresholds: b["Bin_thresholds"],
					bin_color : b["Bin_live_Data"]["color_status_name"],
					cluster_id : b["cluster_id"],
					site_id: b["site_id"],
					over_flow_dates: b["over_flow_dates"] ? b["over_flow_dates"][0].event_num_of_day : 0,
					analysis_time_span_in_days: b["analysis_time_span_in_days"] ? b["analysis_time_span_in_days"] : 0,
					cluster_avg: b["cluster_avg"],
					number_of_bins_in_cluster: b["number_of_bins_in_cluster"],
					calcDiff_lastRefill: b["calcDiff_lastRefill"],
					calcDiff_lastUpdate: b["calcDiff_lastUpdate"],
					installation_DateTime_value : moment(b["bin_installation_date"]).format("YYYY-MM-DD HH:mm:ss").valueOf(),
					predicted_time_for_max_allowed_capacity_value : moment(b["Bin_live_Data"].predicted_time_for_max_allowed_capacity).format("YYYY-MM-DD HH:mm:ss").valueOf(),
					chosenCluster: b["chosenCluster"],
					isOpenTicket: b["isOpenTicket"]
				};
			});
		}
		this.binsData = binsFiltered;			
		this.dataTableLength = this.binsData.length;
		this.dataSource = new MatTableDataSource<any>(binsFiltered);

		if(this.isMobile){
			if(this.dataSource.filteredData.length > 0){
				this.spinnerExist = true;
			}else{
				this.spinnerExist = false;			
			}
		}
		if(this.usageType == 1){
			this.sortData({ direction: 'desc', active: 'bin_name' });
		}else{
			this.sortData({ direction: 'asc', active: 'cluster_id' });
		}
		this.cd.detectChanges();
	}

	editBlackStatus(){
		this.editBlackStatusFlag = false;
	}

	showDropDown(){
		let wrappers = document.getElementsByClassName('mat-mdc-tab-body-wrapper') as HTMLCollectionOf<HTMLElement>;
		_.each(wrappers, (wrapper) => {
			wrapper.style.overflow = 'auto';
		});
		this.editBlackStatusFlag = true;
	}

	showColumn(value:any){
		switch(value) {
			case '1':
				this.neighborhood = true;
			  break;
			case '2':
				this.binIdCol = true;
			  break;
			case '3':
				this.binTypeCol = true;
				break;	
			case '4':
				this.binAvgRateCol = true;
				break;
			case '5':
				this.binCollectionModeCol = true;
				break;
			case '6':
				this.binNameCol = true;
				break;
			case '7':
				this.binDeviceIdCol = true;
				break;
			case '8':
				this.binCollectionDaysCol = false;
				break;
			case '9':
				this.avgDailyRateDunamCol = true;
				break;
			case '10':
				this.binSiteCol = true;
				break;
			case '11':
				this.binAvgUsageRateCol = true;
				break;
			case '12':
				this.binCollectionFrequencyCol = true;
				break;
			case '13':
				this.binInstallDateCol = true;
				break;
			case '14':
				this.binPredictedTimeForMaxAllowedCapacityCol = false;
				break;
			case '15':
				this.binBlackStatusCol = false;
				break;
		  }
	}

	setColumnForUser(id:any,command:any){
		let dataToSend:any = {};
		if(command){
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 1;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}else{
			dataToSend["dynamic_column_id_list"] = [id];
			dataToSend["is_dynamic_columns_active"] = 0;
			this.apiService.SetUserDynamicColumns(dataToSend);
		}		
	}

	parseDateFormat(dateStr:any){
		return this.currentLang == 'iw' ? this.utilService.convertDateHebrew(dateStr) : this.utilService.convertDateEnglish(dateStr);
	}

	toggleEditable(value:any) {
		this.neighborhood = value;
		this.setColumnForUser("1",this.neighborhood);
   }

   toggleEditableId(value:any){
	   this.binIdCol = value;
	   this.setColumnForUser("2",this.binIdCol);
   }

   toggleEditableSite(value:any){
	this.binSiteCol = value;
	this.setColumnForUser("10",this.binSiteCol);
   } 

   toggleEditableCollectionFrequency(value:any){
	this.binCollectionFrequencyCol = value;
	this.setColumnForUser("12",this.binCollectionFrequencyCol);
   }

   toggleEditableAvgUsageRate(value:any){
	this.binAvgUsageRateCol = value;
	this.setColumnForUser("11",this.binAvgUsageRateCol);
   }

   toggleEditableName(value:any){
	this.binNameCol = value;
	this.setColumnForUser("6",this.binNameCol);
   }

   toggleEditableType(value:any){
	this.binTypeCol = value;
	this.setColumnForUser("3",this.binTypeCol);
   }

   toggleEditableAvgRate(value:any){
	this.binAvgRateCol = value;
	this.setColumnForUser("4",this.binAvgRateCol);
   }

   toggleEditableAvgDailyRateDunam(value:any){
	this.avgDailyRateDunamCol = value;
	this.setColumnForUser("9",this.avgDailyRateDunamCol);
   }

   toggleEditablePredictedTimeForMaxAllowedCapacity(value:any){
	this.binPredictedTimeForMaxAllowedCapacityCol = value;
	this.setColumnForUser("14",this.binPredictedTimeForMaxAllowedCapacityCol);
   }

   toggleEditableBlackStatus(value:any){
	this.binBlackStatusCol = value;
	if(value){
		this.getBlackStatus();
	}
   }

   toggleEditableCollectionMode(value:any){
	this.binCollectionModeCol = value;
	this.setColumnForUser("5",this.binCollectionModeCol);
   }

   toggleEditableCollectionDays(value:any){
	this.binCollectionDaysCol = value;
	if(value){
		this.getCollectionDays(true);
	}
   }

   getCollectionDays(flag:any){
	if(flag){
		this.showLoadingForCollectionDays = true;
	}
	this.apiService.GetBinsWeeklyCollectionSchedule(0)?.subscribe((v:any) => {
		if(flag){
			this.showLoadingForCollectionDays = false;
		}
		_.each(this.binsBackUp, (basicBin) => {
			let collectionDays = [
				{
					sunday: 0,
					monday: 0,
					tuesday: 0,
					wednesday: 0,
					thursday: 0,
					friday: 0,
					saturday: 0
				}
			];
			_.each(v.SitesWeeklyCollectionSchedule, (site) => {
				_.each(site.bins_weekly_collections_list, (bin) => {
					_.each(bin, (item) => {                
						if(item.bin_id == basicBin["bin_id"] && site.site_id == basicBin["site_id"]){
							let count = 0;
							collectionDays[0].sunday = item.sunday;
							collectionDays[0].monday = item.monday;
							collectionDays[0].tuesday = item.tuesday;
							collectionDays[0].wednesday = item.wednesday;
							collectionDays[0].thursday = item.thursday;
							collectionDays[0].friday = item.friday;
							collectionDays[0].saturday = item.saturday;
							_.each(Object.values(collectionDays[0]), (value) => {
								if(value == 1){
									count++;
								}
							});
							basicBin["collection_days"] = collectionDays;
							basicBin["active_collection_days"] = count;
						}
					}); 
				});    
			});

			if(basicBin["collection_days"] == null){
				basicBin["collection_days"] = collectionDays;
				basicBin["active_collection_days"] = 0;
			}
		});
		if(flag){
			this.initBinsTable(this.binsBackUp);
		}
	});
   }

   resetBlackStatus(){
	_.each(this.binsBackUp, (basicBin) => {
		basicBin["over_flow_dates"] = null;
		basicBin["analysis_time_span_in_days"] = null;
	});
   }

   getBlackStatus(){
	this.resetBlackStatus();
	this.showLoadingForCurrentCapacityAnalysis = true;
	let chosenSites:any = [];
	_.each(JSON.parse(sessionStorage.getItem("chosenSites")), (site) => {
		chosenSites.push(site.site_id);
	});
	let request = {
		site_id: chosenSites,
		capacity_in_percent: this.ElForm.getRawValue()['blackStatusBin']
	};
	this.apiService.getCurrentCapacityAnalysis(request)?.subscribe((v:any) => {
		this.showLoadingForCurrentCapacityAnalysis = false;
		_.each(this.binsBackUp, (basicBin) => {
			_.each(v.CapacityAnalysisInfo, (info) => {
				_.each(info.binsWorkPlanCapacityAnalysis, (item) => {
					if(item.bin_id == basicBin["bin_id"] && info.site_id == basicBin["site_id"]){
						basicBin["over_flow_dates"] = item.over_flow_dates;
						basicBin["analysis_time_span_in_days"] = info.analysis_time_span_in_days;
					}
				});
			});
			if(basicBin["over_flow_dates"] == null){
				basicBin["over_flow_dates"] = 0;
				basicBin["analysis_time_span_in_days"] = 0;
			}
		});
		_.each(_.groupBy(this.binsBackUp, item => `${item.cluster_id},${item.site_id}`) , (clusters) => {
			let cluster_avg = 0;
			_.each(clusters, (bin) => {
				if(this.capacityDisplayType != 0){
					cluster_avg += bin["Bin_live_Data"].fill_level_percent * bin["Bin_thresholds"].bin_max_capacity_in_liters / 100;
				}else{
					cluster_avg += bin["Bin_live_Data"].fill_level_percent;
				}	
			});
			_.each(clusters, (bin) => {
				bin["cluster_avg"] = cluster_avg / clusters.length;
				bin["number_of_bins_in_cluster"] = clusters.length;
			});
		});
		this.initBinsTable(this.binsBackUp);
	});
   }

   toggleEditableDeviceId(value:any){
	this.binDeviceIdCol = value;
	this.setColumnForUser("7",this.binDeviceIdCol);
   }

   toggleEditableInstallation(value:any){
	this.binInstallDateCol = value;
	this.setColumnForUser("13",this.binInstallDateCol);
   }

	displayList(){		  
		this.showlist = !this.showlist;
	  }

	getTableData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsData);
		tempSearch.filter = this.tempFilter;
		this.filteredBinsData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		this.dataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.dataSource;
	}

	getTrucksData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.trucksData);
		tempSearch.filter = this.tempTruckFilter;
		this.filteredTrucksData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataTrucks; index <= this.endIndexTableDataTrucks; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.trucksDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		this.trucksDataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.trucksDataSource;
	}

	getSitesData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.sitesData);
		tempSearch.filter = this.tempSiteFilter;
		this.filteredSitesData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataSites; index <= this.endIndexTableDataSites; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.sitesDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		return this.sitesDataSource;
	}

	getUsersData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.usersData);
		tempSearch.filter = this.tempUserFilter;
		this.filteredUsersData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataUsers; index <= this.endIndexTableDataUsers; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.usersDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		return this.usersDataSource;
	}

	getClustersData = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.clustersData);

		const filterPredicate = (data :any, filter:any) => {
			try {
				const dataStr = data.cluster_name.toLowerCase().trim() + data.site_name + data.cluster_type_name + data.min_required_capacity_for_collection
				+ data.cluster_max_allowed_capacity + data.cluster_type_name_hebrew + data.cluster_id + data.cluster_type_id + 
				data.site_id + data.bins.reduce((acc:any,value:any) => {
				  return acc.toLowerCase() + Object.values(value).reduce((acc1 : any,value1:any) => {
					return acc1.toLowerCase() + value1;
				  },"");
				},"");        
				return dataStr.indexOf(filter) != -1; 
			  } catch (error) {
				return true;
			  }
		  }
	
		  tempSearch.filteredData = tempSearch.filteredData.filter(data => filterPredicate(data,this.tempClusterFilter));
		  this.filteredClustersData = tempSearch.filteredData;
			for (let index = this.startIndexTableDataClusters; index <= this.endIndexTableDataClusters; index++) {
				if (tempSearch.filteredData[index]) {
					tableData.push(tempSearch.filteredData[index]);
				}
			}
		  this.clustersDataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);	
		  this.clustersDataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);             
		  return this.clustersDataSource;
	}

	onPageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
	}

	onTrucksPageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataTrucks = dataTableIndexes.startIndex;
		this.endIndexTableDataTrucks = dataTableIndexes.endIndex;
	}

	onSitesPageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataSites = dataTableIndexes.startIndex;
		this.endIndexTableDataSites = dataTableIndexes.endIndex;
	}

	onUsersPageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataUsers = dataTableIndexes.startIndex;
		this.endIndexTableDataUsers = dataTableIndexes.endIndex;
	}

	onClustersPageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataClusters = dataTableIndexes.startIndex;
		this.endIndexTableDataClusters = dataTableIndexes.endIndex;
	}

	sortTrucksData(sort: Sort) {
		const data = this.trucksData.slice();
		if (!sort.active || sort.direction === '') {
			this.trucksData = data;
			return;
		}
		this.trucksData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	sortClustersData(sort: Sort) {
		const data = this.clustersData.slice();
		if (!sort.active || sort.direction === '') {
			this.clustersData = data;
			return;
		}
		this.clustersData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	sortUsersData(sort: Sort) {
		let alertsArr :any = [];
		const data = this.usersData.slice();
		if (!sort.active || sort.direction === '') {
			this.usersData = data;
			return;
		}
		this.usersData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});

		if(this.usersData.length > 0 && ((sort.active === 'user_alert'))){
			_.each(this.usersData, (item) => {				
				if(item.user_alert_info == "" || item.user_alert_info == null || item.user_alert_info.length == 0){
					alertsArr.push(item);
				}
			});
			this.usersData = _.remove(this.usersData, function(n) { 
				return n.user_alert_info != "" && n.user_alert_info != null && n.user_alert_info.length != 0;
			})

			if(sort.direction === 'asc'){
				this.usersData = [...alertsArr, ...this.usersData]
			}else{
				this.usersData = [...this.usersData, ...alertsArr]
			} 
		}
	}

	sortSitesData(sort: Sort) {
		const data = this.sitesData.slice();
		if (!sort.active || sort.direction === '') {
			this.sitesData = data;
			return;
		}
		this.sitesData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	sortData(sort: Sort) {
		let emptyLastCollectionArr :any = [];
		let emptyLastUpdateArr :any = [];
		let emptyNeighborhood :any = [];
		const data = this.binsData.slice();
		if (!sort.active || sort.direction === '') {
			this.binsData = data;
			return;
		}
		this.binsData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			if ((sort.active === 'last_collection') || (sort.active === 'last_update')) {
				return sort.active ? this.compareDates(a[sort.active], b[sort.active], isAsc) : 0;
			} else {
				return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
			}
		});

		if(this.binsData.length > 0 && ((sort.active === 'last_collection'))){
			_.each(this.binsData, (item) => {
				if(item.last_collection == "" || item.last_collection == null){
					emptyLastCollectionArr.push(item);
				}
			});
			this.binsData = _.remove(this.binsData, function(n) { 
				return n.last_collection != "" && n.last_collection != null;
			})

			if(sort.direction === 'asc'){
				this.binsData = [...emptyLastCollectionArr, ...this.binsData]
			}else{
				this.binsData = [...this.binsData, ...emptyLastCollectionArr]
			} 
		}else if(this.binsData.length > 0 && ((sort.active === 'last_update'))){
			_.each(this.binsData, (item) => {
				if(item.last_update == "" || item.last_update == null){
					emptyLastUpdateArr.push(item);
				}
			});
			this.binsData = _.remove(this.binsData, function(n) { 
				return n.last_update != "" && n.last_update != null;
			})

			if(sort.direction === 'asc'){
				this.binsData = [...emptyLastUpdateArr, ...this.binsData]
			}else{
				this.binsData = [...this.binsData, ...emptyLastUpdateArr]
			} 
		}else if(this.binsData.length > 0 && ((sort.active === 'neighborhood'))){
			_.each(this.binsData, (item) => {
				if(item.neighborhood == "" || item.neighborhood == null){
					emptyNeighborhood.push(item);
				}
			});
			this.binsData = _.remove(this.binsData, function(n) { 
				return n.neighborhood != "" && n.neighborhood != null;
			})

			if(sort.direction === 'asc'){
				this.binsData = [...emptyNeighborhood, ...this.binsData]
			}else{
				this.binsData = [...this.binsData, ...emptyNeighborhood]
			} 
		}
	}

	onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	  }

	compare(a:any, b:any, isAsc:any) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	compareDates(a:any, b:any, isAsc:any) {
		const date1 = moment(a);
		const date2 = moment(b);
		return (date1.isBefore(date2) ? -1 : 1) * (isAsc ? 1 : -1);
	}

	goToBinLivePage(bin: any) {
		this.router.navigate(['/binLiveInfoPage'], { queryParams: { pageName: 'inventory', binId: bin.bin_id } });
	}
	isAllSelected = (): boolean => {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	isTrucksAllSelected = (): boolean => {
		const numSelected = this.truckSelection.selected.length;
		const numRows = this.trucksDataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	isSitesAllSelected = (): boolean => {
		const numSelected = this.siteSelection.selected.length;
		const numRows = this.sitesDataSource.data.length;
		return numSelected === numRows;
	}

	isUsersAllSelected = (): boolean => {
		const numSelected = this.userSelection.selected.length;
		const numRows = this.usersDataSource.data.length;
		return numSelected === numRows;
	}

	isClustersAllSelected = (): boolean => {
		const numSelected = this.clusterSelection.selected.length;
		const numRows = this.clustersDataSource["AllData"].filteredData.length;
		return numSelected === numRows;
	}

	applyFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
	}

	applyTrucksFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempTruckFilter = filterValue;
		this.trucksDataSource.filter = filterValue;
	}

	
	applySitesFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempSiteFilter = filterValue;
		this.sitesDataSource.filter = filterValue;
	}

	applyUsersFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempUserFilter = filterValue;
		this.usersDataSource.filter = filterValue;
	}

	applyClustersFilter = (filterValue: any) => {
		filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempClusterFilter = filterValue;
		this.clustersDataSource.filter = filterValue;
	}

	masterToggle = () => {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource["AllData"].filteredData.map((row:any) =>
				this.selection.select(row))
	}

	masterTrucksToggle = () => {
		this.isTrucksAllSelected() ?
			this.truckSelection.clear() :
			this.trucksDataSource["AllData"].filteredData.map((row:any) =>
				this.truckSelection.select(row))
	}

	masterSitesToggle = () => {
		this.isSitesAllSelected() ?
			this.siteSelection.clear() :
			this.sitesDataSource.filteredData.map((row:any) =>
				this.siteSelection.select(row))
	}

	masterUsersToggle = () => {
		this.isUsersAllSelected() ?
			this.userSelection.clear() :
			this.usersDataSource.filteredData.map((row:any) =>
				this.userSelection.select(row))
	}

	masterClustersToggle = () => {
		this.isClustersAllSelected() ?
		this.clusterSelection.clear() :
		this.clustersDataSource["AllData"].filteredData.map((row:any) =>
			this.clusterSelection.select(row))
	}

	getImg(value:any){			
		if(value == 2){
			return "assets/images/dashboard/selected.svg";
		}else{
			return "assets/images/dashboard/notSelected.svg";
		}
	}

	getUserAlertsImg(value:any){	
		if(!value){
			return;
		}else if(value.length > 0){
			return "assets/images/dashboard/selected.svg";
		}else{
			return "assets/images/dashboard/notSelected.svg";
		}
	}

	getTitle(elm:any){
		if(elm.Bin_Restrictions.collection_mode == 1){
			return this.currentLang == 'iw' ? elm.Bin_Restrictions.removed_bin_reason_type_name_hebrew : elm.Bin_Restrictions.removed_bin_reason_type_name;
		}
	}

	openManageModalForMulti = () => {		
		if(this.selection.selected.length < 2){
			return;
		}
		this.dialog.open(ManageBinsModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBins : this.selection.selected} });
	}

	openManageClusterModal = (el:any) => {	
		let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = 'bulkBins-container';
		}	
		this.dialog.open(ManageClusterModalComponent, { panelClass: class_name , data: {chosenCluster : el} });
	}

	openManageUserModal = (el:any) => {
		this.dialog.open(ManageUserModalComponent, { panelClass: 'bulkBins-container' , data: {chosenUser : el} });
	}

	openAddClusterModal = () => {	
		let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = 'bulkBins-container';
		}
		this.dialog.open(AddClusterModalComponent, { panelClass: class_name, data: {chosenBins : []} });	
	}

	openAddUserModal = () => {
		this.dialog.open(AddUserModalComponent, { panelClass: 'bulkBins-container', data: {chosenUsers : []} });	
	}

	openAddTruckModal = () => {
		let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = 'bulkBins-container';
		}
		this.dialog.open(AddTruckModalComponent, { panelClass: class_name });		
	}

	openManageClustersModal = () => {	
		if(this.clusterSelection.selected.length < 2){
			return;
		}
		let class_name = '';
		if(this.isMobile){
			class_name = 'popup-container-mobile';
		}else{
			class_name = 'bulkBins-container';
		}	
		this.dialog.open(ManageClustersModalComponent, { panelClass: class_name, data: {chosenClusters : this.clusterSelection.selected} });	
	}

	openDeleteClustersModal = (el:any) => {
		let class_name = '';
		if(this.isMobile){
		  class_name = 'popup-mobile';
		}else{
		  class_name = 'bulkBins-container';
		}
		this.dialog.open(DeleteClustersModalComponent, { panelClass: class_name, data: {chosenClusters : !el.length ? [el] : el} });	
	}

	openDeleteUsersModal = (el:any) => {
		this.dialog.open(DeleteUserModalComponent, { panelClass: 'bulkBins-container', data: {chosenUsers : !el.length ? [el] : el} });	
	}

	openDeleteTrucksModal = (el:any) => {
		let class_name = '';
		if(this.isMobile){
		  class_name = 'popup-mobile';
		}else{
		  class_name = 'bulkBins-container';
		}
		this.dialog.open(DeleteTrucksModalComponent, { panelClass: class_name, data: {chosenTrucks : !el.length ? [el] : el} });	
	}

	openManageTruckModal = (truck:any) => {
		let class_name = '';
		if(this.isMobile){
		  class_name = 'popup-container-mobile';
		}else{
		  class_name = 'bulkBins-container';
		}
		let truckChosen = _.find(this.TruckFullInfo, function (item) {
			return (item.tablet_id === truck.id);
		});
		this.truckSelection.clear();
		this.dialog.open(ManageTruckModalComponent, { panelClass: class_name , data: {chosenTruck : truckChosen} });
	}

	openManageBinModal = (bin:any) => {
		this.getCollectionDays(false);
		let binChosen = _.find(this.binFullInfo, function (item) {
			return (item.bin_id === bin.bin_id)
		});
		this.selection.clear();
		this.dialog.open(ManageBinModalComponent, { panelClass: 'bulkBins-container' , data: {chosenBins : binChosen, properties : this.displayedFields} });
	}
	
	openManageSiteModal = (site:any) => {
		let siteChosen = _.find(this.sitesFullInfo, function (item) {
			return (item.site_name === site.site_name);
		});
		this.siteSelection.clear();
		this.dialog.open(ManageSiteModalComponent, { panelClass: 'bulkBins-container' , data: {chosenSites : siteChosen} });
	}

	addComma = (item:any) => {
		if(item.collection_days_csv!=''){
			item.collection_days_csv += ', ';
		}
	}

	headerForExcel = () => {
		let header = {};

		if(this.usageType == 1){
			if(this.binCollectionDaysCol){
				header = {
					'0' : this.translationsObj.LIST_COLUMNS.NAME, 
					'1' : this.translationsObj.TRUCK_MAP.SITE,
					'2' : this.translationsObj.LIST_COLUMNS.ID,
					'3' : this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
					'4' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
					'5' : this.translationsObj.LIST_COLUMNS.ADDRESS,
					'6' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
					'7' : this.translationsObj.LIST_COLUMNS.LAT,
					'8' : this.translationsObj.LIST_COLUMNS.LON,
					'9' : this.translationsObj.LIST_COLUMNS.DESCRIPTION, 
					'10' : this.translationsObj.LIST_COLUMNS.INSTALLATION_DATE,
					'11' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
					'12' : this.translationsObj.LIST_COLUMNS.LAST_UPDATE, 
					'13' : this.translationsObj.LIST_COLUMNS.STATUS_PERCENT,
					'14' : this.translationsObj.LIVE_INFO.EMPTY_RATE,
					'15' : this.translationsObj.LIVE_INFO.AVG_RATE,
					'16' : this.translationsObj.MANAGE_BIN.INCLUDE_IN_WORKPLAN,
					'17' : this.translationsObj.TITLES.REMOVAL_REASON,
					'18' : this.translationsObj.MANAGE_BIN.MONTHLY_COLLECTION_FREQ,
					'19' : this.translationsObj.LIST_COLUMNS.FULLNESS_FORECAST_DATE,
					'20' : this.translationsObj.MANAGE_BIN.BLACK_STATUS,
					'21' : this.translationsObj.MANAGE_BIN.BIN_COLLECTION_DAYS
				}
			}else{
				header = {
					'0' : this.translationsObj.LIST_COLUMNS.NAME, 
					'1' : this.translationsObj.TRUCK_MAP.SITE,
					'2' : this.translationsObj.LIST_COLUMNS.ID,
					'3' : this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
					'4' : this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
					'5' : this.translationsObj.LIST_COLUMNS.ADDRESS,
					'6' : this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
					'7' : this.translationsObj.LIST_COLUMNS.LAT,
					'8' : this.translationsObj.LIST_COLUMNS.LON,
					'9' : this.translationsObj.LIST_COLUMNS.DESCRIPTION, 
					'10' : this.translationsObj.LIST_COLUMNS.INSTALLATION_DATE,
					'11' : this.translationsObj.LIST_COLUMNS.LAST_COLLECTION, 
					'12' : this.translationsObj.LIST_COLUMNS.LAST_UPDATE, 
					'13' : this.translationsObj.LIST_COLUMNS.STATUS_PERCENT,
					'14' : this.translationsObj.LIVE_INFO.EMPTY_RATE,
					'15' : this.translationsObj.LIVE_INFO.AVG_RATE,
					'16' : this.translationsObj.MANAGE_BIN.INCLUDE_IN_WORKPLAN,
					'17' : this.translationsObj.TITLES.REMOVAL_REASON,
					'18' : this.translationsObj.MANAGE_BIN.MONTHLY_COLLECTION_FREQ,
					'19' : this.translationsObj.LIST_COLUMNS.FULLNESS_FORECAST_DATE,
					'20' : this.translationsObj.MANAGE_BIN.BLACK_STATUS
				}
			}
		}else{
			if(this.binCollectionDaysCol){
				header = {
					'0': this.translationsObj.LIST_COLUMNS.NAME, 
					'1': this.translationsObj.TRUCK_MAP.SITE,
					'2': this.translationsObj.LIST_COLUMNS.ID,
					'3' : this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
					'4': this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
					'5': this.translationsObj.LIST_COLUMNS.ADDRESS,
					'6': this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
					'7': this.translationsObj.LIST_COLUMNS.LAT,
					'8': this.translationsObj.LIST_COLUMNS.LON,
					'9': this.translationsObj.LIST_COLUMNS.DESCRIPTION,
					'10': this.translationsObj.LIST_COLUMNS.QUARTER_ACRE, 
					'11' : this.translationsObj.LIST_COLUMNS.INSTALLATION_DATE,
					'12': this.translationsObj.LIST_COLUMNS.LAST_REFILL, 
					'13': this.translationsObj.LIST_COLUMNS.LAST_UPDATE, 
					'14': this.translationsObj.LIST_COLUMNS.STATUS_PERCENT,
					'15': this.translationsObj.LIVE_INFO.EMPTY_RATE,
					'16': this.translationsObj.LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM,
					'17': this.translationsObj.TITLES.REMOVAL_REASON,
					'18' : this.translationsObj.LIST_COLUMNS.FULLNESS_FORECAST_DATE,
					'19' : this.translationsObj.MANAGE_BIN.BLACK_STATUS,
					'20' : this.translationsObj.MANAGE_BIN.BIN_COLLECTION_DAYS
				}
			}else{
				header = {
					'0': this.translationsObj.LIST_COLUMNS.NAME, 
					'1': this.translationsObj.TRUCK_MAP.SITE,
					'2': this.translationsObj.LIST_COLUMNS.ID,
					'3' : this.translationsObj.SITE_MANAGMENT.DEVICE_ID,
					'4': this.translationsObj.LIST_COLUMNS.BIN_TYPE, 
					'5': this.translationsObj.LIST_COLUMNS.ADDRESS,
					'6': this.translationsObj.LIST_COLUMNS.NEIGHBORHOOD,
					'7': this.translationsObj.LIST_COLUMNS.LAT,
					'8': this.translationsObj.LIST_COLUMNS.LON,
					'9': this.translationsObj.LIST_COLUMNS.DESCRIPTION,
					'10': this.translationsObj.LIST_COLUMNS.QUARTER_ACRE, 
					'11' : this.translationsObj.LIST_COLUMNS.INSTALLATION_DATE,
					'12': this.translationsObj.LIST_COLUMNS.LAST_REFILL, 
					'13': this.translationsObj.LIST_COLUMNS.LAST_UPDATE, 
					'14': this.translationsObj.LIST_COLUMNS.STATUS_PERCENT,
					'15': this.translationsObj.LIVE_INFO.EMPTY_RATE,
					'16': this.translationsObj.LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM,
					'17': this.translationsObj.TITLES.REMOVAL_REASON,
					'18' : this.translationsObj.LIST_COLUMNS.FULLNESS_FORECAST_DATE,
					'19' : this.translationsObj.MANAGE_BIN.BLACK_STATUS
				}
			}
		}
		return header;
	}

	public exportToCsv = () => {
		let dataToExport = null;
		if (this.selection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.selection.selected);
		} else {
			dataToExport = _.cloneDeep(this.binsData);
		}

		_.each(dataToExport, (item) => {
			if(this.currentLang == 'iw'){
				item.removed_reason = item.removed_reason_hebrew;
			}
			if(item.collection_mode == 2){
				item.collection_mode = this.translationsObj.MANAGE_BIN.INCLUDE_IN_WORKPLAN;
			}else{
				item.collection_mode = this.translationsObj.MANAGE_BIN.NOT_INCLUDE_IN_WORKPLAN;
			}

			if(item.over_flow_dates != 0){
				item.blackStatus_csv = item.over_flow_dates;
			}if(item.over_flow_dates >= item.analysis_time_span_in_days && item.over_flow_dates != 0){
				item.blackStatus_csv += "+";
			}
			if(item.number_of_bins_in_cluster > 1 && item.over_flow_dates != 0){
				if(this.usageType == 1){
					item.blackStatus_csv += " " + "("+Number(item.cluster_avg).toFixed(0)+"%"+")";
				}else{
					item.blackStatus_csv += " " + "("+Number(item.cluster_avg).toFixed(0)+"L"+")";
				}
			}

			if(item.collection_days && item.collection_days.length > 0){
				if(item.collection_days[0].sunday){
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.SUNDAY_COLLECTION;
				}
				if(item.collection_days[0].monday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.MONDAY_COLLECTION;
				}
				if(item.collection_days[0].tuesday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.TUESDAY_COLLECTION;
				}
				if(item.collection_days[0].wednesday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.WEDNESDAY_COLLECTION;
				}
				if(item.collection_days[0].thursday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.THURSDAY_COLLECTION;
				}
				if(item.collection_days[0].friday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.FRIDAY_COLLECTION;
				}
				if(item.collection_days[0].saturday){
					this.addComma(item);
					item.collection_days_csv += this.translationsObj.NEW_WORKPLAN.SATURDAY_COLLECTION;
				}
			}else{
				item.collection_days_csv = '';
			}
		});
		const header = this.headerForExcel();
		
		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])
		
		let source:any = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b:any) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcelWithFormatingCells(result, "Inventory Bins");
		}else{
			TableUtil.exportArrayToExcelWithFormatingCells(result, "מלאי המיכלים");
		}
	}

	public exportTrucksToCsv = () => {
		let dataToExport = null;
		if (this.truckSelection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.truckSelection.selected);
		} else {
			dataToExport = _.cloneDeep(this.trucksData);
		}

		const header = 
		{
			'0' : this.translationsObj.TITLES.TRUCK_ID, 
			'1' : this.translationsObj.TITLES.DRIVER_NAME,
			'2' : this.translationsObj.TITLES.SITE_NAME,
			'3' : this.translationsObj.TITLES.TRUCK_CAPACITY, 
			'4' : this.translationsObj.TITLES.MAX_NUM_OF_BINS_TO_COLLECT,
			'5' : this.translationsObj.TITLES.LICENSE_PLATE,
		}

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])

		let source:any = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b:any) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Trucks");
		}else{
			TableUtil.exportArrayToExcel(result, "משאיות");
		}
	}

	public exportSitesToCsv = () => {
		let dataToExport = null;
		if (this.siteSelection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.siteSelection.selected);
		} else {
			dataToExport = _.cloneDeep(this.sitesData);
		}

		_.each(dataToExport, (item) => {
			if(item.is_use_site_max_allowed_capacity){
				item.is_use_site_max_allowed_capacity = this.translationsObj.MANAGE_SITE.MAX_VOLUME_SITE;
			}else{
				item.is_use_site_max_allowed_capacity = this.translationsObj.MANAGE_SITE.MAX_VOLUME_BIN;
			}
			if(this.currentLang == 'iw'){
				item.preferred_bin_distribution_method_name = item.preferred_bin_distribution_method_name_hebrew;
				item.customer_work_plan_collection_schedule_policy_name = item.customer_work_plan_collection_schedule_policy_name_hebrew;	
			}
		});

		const header = 
		{
			'0' : this.translationsObj.TITLES.SITE_NAME, 
			'1' : this.translationsObj.LIST_COLUMNS.DISTRIBUTION_METHOD,
			'2' : this.translationsObj.LIST_COLUMNS.PLAN_START_TIME,
			'3' : this.translationsObj.LIST_COLUMNS.PLAN_FINISH_TIME, 
			'4' : this.translationsObj.MANAGE_SITE.MAXIMUM_VOLUME,
			'5' : this.translationsObj.LIST_COLUMNS.MAX_UNCOLLECTED_DAYS,
			'6' : this.translationsObj.LIST_COLUMNS.AVG_CAP_FUTURE_WORKPLAN,
			'7' : this.translationsObj.LIST_COLUMNS.MAX_DAYS_GAP_FOR_FUTURE_WORKPLAN,
			'8' : this.translationsObj.MANAGE_SITE.SET_FIXED_DAYS_TO_COLLECT,
			'9' : this.translationsObj.MANAGE_SITE.SET_MAX_VOLUME
		}

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])

		let source:any = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Sites");
		}else{
			TableUtil.exportArrayToExcel(result, "אתרים");
		}
	}

	public exportUsersToCsv = () => {
		let dataToExport = null;
		if (this.userSelection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.userSelection.selected);
		} else {
			dataToExport = _.cloneDeep(this.usersData);
		}

		_.each(dataToExport, (item) => {
			if(this.currentLang == 'iw'){
				item.user_role_name = item.user_role_name_hebrew;
			}
		});

		const header = 
		{
			'0' : this.translationsObj.LIST_COLUMNS.FIRST_NAME, 
			'1' : this.translationsObj.LIST_COLUMNS.LAST_NAME,
			'2' : this.translationsObj.LIST_COLUMNS.EMAIL,
			'3' : this.translationsObj.LIST_COLUMNS.PHONE, 
			'4' : this.translationsObj.LIST_COLUMNS.COMPANY,
			'5' : this.translationsObj.LIST_COLUMNS.PERMISSION
		}

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])

		let source:any = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b:any) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Users");
		}else{
			TableUtil.exportArrayToExcel(result, "משתמשים");
		}
	}

	public exportClustersToCsv = () => {
		let dataToExport = null;
		if (this.clusterSelection.selected.length > 0) {
			dataToExport = _.cloneDeep(this.clusterSelection.selected);
		} else {
			dataToExport = _.cloneDeep(this.clustersData);
		}

		_.each(dataToExport, (item) => {
			if(this.currentLang == 'iw'){
				item.cluster_type_name = item.cluster_type_name_hebrew;
			}
		});

		const header = 
		{
			'0' : this.translationsObj.LIST_COLUMNS.CLUSTERID, 
			'1' : this.translationsObj.LIST_COLUMNS.CLUSTER_NAME, 
			'2' : this.translationsObj.TITLES.SITE_NAME,
			'3' : this.translationsObj.LIST_COLUMNS.CLUSTER_TYPE,
			'4' : this.translationsObj.LIST_COLUMNS.MIN_ALLOWED_CAPACITY, 
			'5' : this.translationsObj.LIST_COLUMNS.MAX_ALLOWED_CAPACITY,
			'6' : this.translationsObj.LIST_COLUMNS.CLUSTER_SIZE
		}

		const flatData = dataToExport.reduce((b, a) => {	
			let res = _.slice(_.values(a), 0, Object.keys(header).length)									
			b.push(res);
			return b;
		}, [])

		let source:any = {
			flatData,
			header
		};	

		let result = source.flatData.map((details:any) => 
		Object.keys(details).reduce((a:any, b:any) => 
		(a[source.header[b]] = details[b], a), {}));			
		if(this.currentLang != 'iw'){
			TableUtil.exportArrayToExcel(result, "Clusters");
		}else{
			TableUtil.exportArrayToExcel(result, "מקבצים");
		}
	}
	
	calcDiff = (date: string) => {
		if (this.translationsObj && date) {
			if(date == "" || date == null){
				return "";
			}
			let diff_in_string = '';
			const original_date = moment(date);
			const date_time_now = moment();
			if(new Date(moment(date).format("YYYY-MM-DD HH:mm:ss")).getTime() > new Date(moment().format("YYYY-MM-DD HH:mm:ss")).getTime()){
				return this.translationsObj.COLLECTION_REPORT.NOW;
			}
			const diff_in_object: any = this.utilService.preciseDiffBetweenDates(original_date, date_time_now, true);
			if (diff_in_object.months > 0) {
				diff_in_string = diff_in_string + diff_in_object.months + ' ';
				if (diff_in_object.months == 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTH + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.MONTHS + ' ';
				}
				if (diff_in_object.days > 0) {
					if (diff_in_object.days == 1) {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.days + ' ' + this.translationsObj.COLLECTION_REPORT.DAY;
					} else {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.days + ' ' + this.translationsObj.COLLECTION_REPORT.DAYS;
					}
				}
			}else if (diff_in_object.days > 0) {
				diff_in_string = diff_in_string + diff_in_object.days + ' ';
				if (diff_in_object.days == 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAY + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.DAYS + ' ';
				}
				if (diff_in_object.hours > 0) {
					if (diff_in_object.hours == 1) {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.hours + ' ' + this.translationsObj.COLLECTION_REPORT.HOUR;
					} else {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.hours + ' ' + this.translationsObj.COLLECTION_REPORT.HOURS;
					}
				}
			}else if (diff_in_object.hours > 0) {
				diff_in_string = diff_in_string + diff_in_object.hours + ' ';
				if (diff_in_object.hours == 1) {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOUR + ' ';
				} else {
					diff_in_string += this.translationsObj.COLLECTION_REPORT.HOURS + ' ';
				}
				if (diff_in_object.minutes > 0) {
					if (diff_in_object.minutes == 1) {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.minutes + ' ' + this.translationsObj.COLLECTION_REPORT.MINUTE;
					} else {
						diff_in_string += this.translationsObj.COLLECTION_REPORT.AND + ' ' + diff_in_object.minutes + ' ' + this.translationsObj.COLLECTION_REPORT.MINUTES;
					}
				}
			}else if (diff_in_object.minutes > 0) {
				if (diff_in_object.minutes == 1) {
					diff_in_string += diff_in_object.minutes + ' ' + this.translationsObj.COLLECTION_REPORT.MINUTE;
				} else {
					diff_in_string += diff_in_object.minutes + ' ' + this.translationsObj.COLLECTION_REPORT.MINUTES;
				}
			}

			if (this.currentLang != 'iw') {
				diff_in_string += ' ' + this.translationsObj.COLLECTION_REPORT.AGO;
			}
			else {
				diff_in_string = this.translationsObj.COLLECTION_REPORT.AGO + ' ' + diff_in_string;
			}
			
			return diff_in_string;
		}
		
	}

	getProgressBarStyleInternal = (binInfo: any) => {		
		if(!this.isMobile){
			if (binInfo.bin_color == "GREEN") {
				return { 'background-color': '#14C264', 'width': binInfo.status + '%' };
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'background-color': '#FAB300', 'width': binInfo.status + '%' };
			}
			return { 'background-color': '#E01B09', 'width': binInfo.status + '%' };
		}else{
			if (binInfo.bin_color == "GREEN") {
				return { 'color': '#14C264'};
			} else if (binInfo.bin_color == "YELLOW") {
				return { 'color': '#FAB300' };
			}
			return { 'color': '#E01B09' };
		}
	}

	openColumnManaging(){		
		const dialogRef = this.dialog.open(GenericColumnsManagingModalComponent, { panelClass: 'popup-container-mobile' , data: {page : 'inventory', displayedColumns: this.inventorySelectedValues} });
		dialogRef.afterClosed().subscribe(result => {
			this.inventorySelectedValues  = result.values;
			if (!_.isEmpty(this.inventorySelectedValues)) {
				//init table with the new columns
				this.initBinsTable(this.binsBackUp);
			}
		});
	}	
}
