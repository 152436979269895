import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { DRIVERLIST_URL } from '../../../../environments/environment';
import { ResponsiveService } from '../../../services/responsive.service';
import { ApiService } from '../../../web-services/api/api.service';
import { ApiStore } from '../../../web-services/api/api.store';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ChangeBinToCollectModalComponent } from '../change-bin-to-collect-modal/change-bin-to-collect-modal.component';

@Component({
  selector: 'app-workplan-manager',
  host: {
		'(document:click)': 'handleClick($event)',
	},
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,MatCardModule,NgbModule,FormsModule,
    MatTableModule,MatSortModule,MatCheckboxModule],
  templateUrl: './workplan-manager.component.html',
  styleUrl: './workplan-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkplanManagerComponent {
  isMobile: Boolean;
	spinnerActive : boolean = true;
	@Input() dayClicked;
	@Input() dayEvents : Array<object>;
	@Input() eventSelected : any;
	@Output() dateSelected = new EventEmitter<string>(true);
	@Output() userGeneratedState = new EventEmitter<boolean>(false);
	@Output() truckDriverName = new EventEmitter<string>();
	@Output() tabletTeamsAllocationId = new EventEmitter<string>();
	currentTruck: any = {};
	activeTrucks: any = [];
	dailyWorkplanDetails: any;
	showActiveTrucksDropDownList: Boolean = false;
	@Output() chosenTruck = new EventEmitter<object>(true);
	@Input() isAdmin: boolean;
	@Output() chosenSite = new EventEmitter<object>();
	showActiveSitesDropDownList: Boolean = false;
	showAllBinsDropDownList: Boolean = false;
	activeSites: any = [];
	translate;
	currentLang;
	translationsObj;
    currentSite: any = {};
	activeSitesToDisplay: any = [];
	activeTrucksToDisplay: any = [];
	backupActiveTrucks: any;
	backupActiveSites: any;
	truckBins: any;
	backUptruckBins:any;
	usageType = 1;
	allTrucks: Array<object> = [];
	simId :string = '';
	baseUrl:string = '';
	currentSiteId;
	currentStartTime;
	currentEndTime;

	static getServerURL = () => {
		const url = _.find(DRIVERLIST_URL, sru => sru.is_selected).url;
		if (!url || url === '') {
			return '';
		}
		return url;
	}

  constructor(private responsiveService: ResponsiveService,private apiService:ApiService,
    private translator: TranslatorService,private apiStore:ApiStore,private dialog: MatDialog,
	private apiQuery:ApiQuery) {
      this.translate = this.translator;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	   
      })
    }

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
	this.baseUrl = WorkplanManagerComponent.getServerURL();
	this.apiQuery.user$.subscribe(user => {
		if (!user) return;

		if(user["usage_type_id"] != 1){
			this.usageType = user["usage_type_id"];
		}
	});
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}

  ngOnChanges(){		
		this.spinnerActive = true;
		if(this.dayEvents && this.eventSelected){	

			if(this.dayEvents[0] != undefined){
				let dateToDisplay = '';
				let day = new Date(this.dayEvents[0]["start"]).getDate();
				let month = new Date(this.dayEvents[0]["start"]).getMonth() + 1;
				let year = new Date(this.dayEvents[0]["start"]).getFullYear();
				dateToDisplay = day + '/' + month + '/' + year;				
				this.dateSelected.emit(dateToDisplay);			
			}else{
				if(this.dayClicked != undefined){
					let dateToDisplay = moment(this.dayClicked).format('DD/MM/YYYY');
					this.dateSelected.emit(dateToDisplay);			
				}
			}
			if(this.eventSelected.userGenerated == 'userGenerated'){
				this.userGeneratedState.emit(true);
			}else{
				this.userGeneratedState.emit(false);
				this.dailyWorkplanDetails = this.eventSelected["plan"];
				this.subscribeWorkplanTrucksDetails(this.eventSelected);							
				this.subscribeActiveSitesList(this.dayEvents);				
			}							

		}

	}

	copyLink(){
		navigator.clipboard.writeText(`${this.baseUrl}?truckId=${this.simId}`).then(() => {
			//console.log('Content copied to clipboard');
		},() => {
			//console.error('Failed to copy');
		});
	}

  public selectTruck = (truck: any, isUserSelected: boolean) => {
		_.each(this.allTrucks, (item) => {
			if (item["tablet_id"] == truck.tablet_id) {
				truck.sim_id = item["sim_id"];
				this.simId = item["sim_id"];
			}
		});
		if(this.isMobile){
			this.showActiveTrucksDropDownList = false;
		}
		if (isUserSelected === true && truck.tablet_id == this.currentTruck.tablet_id) {
			return;
		}
		else {
			this.dailyWorkplanDetails = this.eventSelected.plan;
			this.truckDriverName.emit("");
			this.tabletTeamsAllocationId.emit("");
			this.chosenTruck.emit(truck);		
			if(this.currentSite.plan.site_id != this.currentSiteId || 
				moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 00:00:01").valueOf() != this.currentStartTime ||
				moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 23:59:59").valueOf() != this.currentEndTime){
				this.currentSiteId = this.currentSite.plan.site_id;
				this.currentStartTime = moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 00:00:01");
				this.currentEndTime = moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 23:59:59");

				let request = {
					site_id : this.currentSite.plan.site_id,
					start_date : moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 00:00:01"),
					end_date : moment(this.currentSite.plan.plan_start_time).format("YYYY-MM-DD 23:59:59")
				}			
				this.apiService.getfilteredBinsCollectionsStats(request,2,JSON.parse(sessionStorage.getItem("chosenSites")));
			}
			if(this.eventSelected.plan.Site_plan_id != undefined){				
				this.apiService.getWorkPlanTrucksInfo(this.eventSelected.plan.Site_plan_id).subscribe((v:any) => {  										
					if(v.TrucksDetails.length > 0){
						_.each(v.TrucksDetails, (item) => {							
							if(item.tablet_id == truck.tablet_id){								
								if(item.current_assigned_driver_name == "unassigned truck"){
									this.truckDriverName.emit("-1");
								}else{
									this.truckDriverName.emit(item.current_assigned_driver_name);
								}					
								this.tabletTeamsAllocationId.emit(item.tablet_teams_allocation_id);					
							}
						});	
					}		
					else{
						this.truckDriverName.emit("-1");
						this.tabletTeamsAllocationId.emit("-1");					
					}	
				});   
			}

			this.spinnerActive = true;
			this.currentTruck = truck;
			
			if(this.currentTruck != null && this.currentTruck.Bins_collection_info != null){
				this.backUptruckBins = this.currentTruck.Bins_collection_info;
				this.subscribeTruckWorkplan(this.currentTruck.Bins_collection_info);
			}else{
				this.backUptruckBins = [];
				this.subscribeTruckWorkplan([]);
			}
			this.showActiveTrucksDropDownList = false;
		}
	}

	public selectSite = (site: any,isUserSelected: boolean) => {				
		if(site != null){					
			this.chosenSite.emit(site.plan);			
		}
		if(this.isMobile){
			this.showActiveSitesDropDownList = false;
		}
		if(isUserSelected && this.currentSite.plan.Site_plan_id == site.plan.Site_plan_id){
			return;
		}

		let activeSiteStrLength = 0;
		if (this.translationsObj) {
			activeSiteStrLength =  this.translationsObj.TRUCK_MAP.ACTIVE_SITE.length;
		}
		this.spinnerActive = true;
		this.currentSite = site;
		
		if(this.currentSite.plan && !this.currentSite.plan.formatted_name && this.currentSite.userGenerated == "daily"){
			(this.currentSite.plan.site_name.length + activeSiteStrLength) >= 21 ? this.currentSite.plan.formatted_name = (this.currentSite.plan.site_name.slice(0, 21 - activeSiteStrLength) + '...') : this.currentSite.plan.formatted_name = this.currentSite.plan.site_name;
		}

		if(this.currentSite.plan && !this.currentSite.plan.Vehicle_info && this.currentSite.userGenerated == "daily"){
			this.apiService.dailyWorkPlanInfo(this.currentSite);
            this.apiQuery.workPlanInfo$.subscribe((info:any) => {    
              if(info.length == 0 || _.isEmpty(info[0])){
                return;
              }			  
			  this.currentSite = info[0];
			  this.apiService.getUnAssignToWorkPlanBinsInfo(this.currentSite.plan.site_id);
			  this.activeTrucks = this.currentSite.plan.Vehicle_info;
			  this.backupActiveTrucks = _.cloneDeep(this.activeTrucks);
			  if(this.activeTrucks == undefined){
				return;
			  }
			  if (this.activeTrucks.length > 0) this.updateTrucksPerSite();
            });
		}else{
			this.apiService.getUnAssignToWorkPlanBinsInfo(this.currentSite.plan.site_id);
			this.activeTrucks = this.currentSite.plan.Vehicle_info;
			this.backupActiveTrucks = _.cloneDeep(this.activeTrucks);
			if(this.activeTrucks == undefined){
				return;
			}
			if (this.activeTrucks.length > 0) this.updateTrucksPerSite();
		}
	}

  public searchTruck = (filterValue) => {
    filterValue = filterValue.value;
		const filter = filterValue.trim().toLowerCase();
		if (filter === "") {
			this.activeTrucksToDisplay = _.cloneDeep(this.backupActiveTrucks);
		}
		else {
			this.activeTrucksToDisplay = [];
			_.each(this.backupActiveTrucks, (item) => {
				const str = "truck_" + item.tablet_id;
				if (_.includes(str, filter)) {
					this.activeTrucksToDisplay.push(item);
				}
			});

		}
	}

  public updateTrucksPerSite = () => {
		let truckPerSite: any = [];

		this.activeTrucks.forEach(function(activeTruckElement) {
			truckPerSite.push(activeTruckElement);
		});

		this.apiQuery.filteredTrucks$.subscribe(trucksList => {
			if (!trucksList) return;
			this.allTrucks = trucksList;
			_.each(trucksList, (item) => {
				if (item["site_id"] == this.currentSite.plan.site_id && !this.exist(truckPerSite,item)) {
					truckPerSite.push(item);
				}
			});
			
			this.activeTrucksToDisplay = truckPerSite;
			this.backupActiveTrucks = _.cloneDeep(truckPerSite);
			this.selectTruck(this.activeTrucksToDisplay[0], false);
		});
	}

	exist(arr,item){	
		let equal = arr.filter(value => {
			return (item["tablet_id"] == value["tablet_id"]);
		});	

		if(equal != null && equal.length > 0){
			return true;
		}else{		
			return false;
		}
	}

  public subscribeActiveSitesList = (sitesList: any[]) => {
		let maxAllowedDivlength = 21;
		let activeSiteStrLength = 0;
		if (this.translationsObj) {
			activeSiteStrLength =  this.translationsObj.TRUCK_MAP.ACTIVE_SITE.length;
		}

		let activeSitesArray: any = [];
		sitesList.forEach(function(siteListElement, siteListIndex, siteListArray) {
			if(siteListElement.plan.Site_plan_id != undefined){
				let siteStr: string = siteListElement.plan.site_name;
				if(siteStr!=undefined){
					(siteStr.length + activeSiteStrLength) >= maxAllowedDivlength ? siteListElement.plan.formatted_name = (siteStr.slice(0, maxAllowedDivlength - activeSiteStrLength) + '...') : siteListElement.plan.formatted_name = siteListElement.plan.site_name;
				}
				activeSitesArray.push(siteListElement);
			}
		});

		this.backupActiveSites = _.cloneDeep(activeSitesArray);
		this.activeSitesToDisplay = activeSitesArray;
		this.activeSites = sitesList;
		if (this.activeSitesToDisplay.length > 0) this.selectSite(this.eventSelected,false);
	}

  public searchSite = (filterValue) => {
    filterValue = filterValue.value;
		const filter = filterValue.trim().toLowerCase();
		if (filter === "") {
			this.activeSitesToDisplay = _.cloneDeep(this.backupActiveSites);
		}
		else {
			this.activeSitesToDisplay = [];
			let str = '';
			_.each(this.backupActiveSites, (item) => {
				if(item.plan){
					str = item.plan.site_name.trim().toLowerCase();				
				}else{
					str = item.site_name.trim().toLowerCase();				
				}
				if (_.includes(str, filter)) {
					this.activeSitesToDisplay.push(item);
				}
			});

		}
	}

    subscribeTruckWorkplan = (truckWorkPlan: any) => {
		this.spinnerActive = false;
		this.truckBins = truckWorkPlan;
	}

    subscribeWorkplanTrucksDetails = (workplanTrucksDetails: any) => {
		if (_.isEmpty(workplanTrucksDetails.Vehicle_info)) return;

		this.activeTrucks = workplanTrucksDetails.Vehicle_info;
		this.backupActiveTrucks = _.cloneDeep(this.activeTrucks);
	}

    openDropDownList = (list_name: string) => {
		if (list_name === 'active-trucks') {
			this.showActiveTrucksDropDownList = !this.showActiveTrucksDropDownList;
			this.showAllBinsDropDownList = false;
			this.showActiveSitesDropDownList = false;
			return;
		}
		if (list_name === 'add-remove-bins') {			
			this.showActiveTrucksDropDownList = false;
			this.truckBins = this.backUptruckBins;		
			let class_name = '';
			if(this.isMobile){
				class_name = 'popup-container-mobile';
			}else{
				class_name = 'bulkBins-container';
			}
			this.dialog.open(ChangeBinToCollectModalComponent, { panelClass: class_name , data: {currentSite : this.currentSite, dailyWorkplanDetails : this.dailyWorkplanDetails, currentTruck : this.currentTruck, truckBins : this.truckBins, backUptruckBins : this.backUptruckBins} });
			this.showAllBinsDropDownList = false;
			this.showActiveSitesDropDownList = false;
			return;
		}
		if (list_name === 'active-sites') {
			if(this.isMobile){
				this.showActiveTrucksDropDownList = false;
			}
			this.showActiveSitesDropDownList = !this.showActiveSitesDropDownList;
			this.showAllBinsDropDownList = false;
			return;
		}

		this.showActiveTrucksDropDownList = false;
		this.showActiveSitesDropDownList = false;
		this.showAllBinsDropDownList = !this.showAllBinsDropDownList;
	}

  handleClick(event) {
		if(!this.isMobile){
			let clickedComponent = event.target;
			let inside = false;
			do {
				if ((clickedComponent.className === "drop-down-btn") || (clickedComponent.className === "search-input")
					|| (clickedComponent.className === "drop-down-list-container") || (clickedComponent.className === "drop-down-btn change-bin") || (clickedComponent.className === "mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin") || (clickedComponent.className === "drop-down-list-container add-remove-bins bin-container-rtl rtl")
					|| (clickedComponent.className === "drop-down-list-container add-remove-bins bin-container-rtl")) {
					inside = true;
				}
				clickedComponent = clickedComponent.parentNode;
			} while (clickedComponent);
			if (inside) {
				return;
			}
			else {
				this.showActiveTrucksDropDownList = false;
				this.showActiveSitesDropDownList = false;
			}
		}
	}
}
