import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgModel, ReactiveFormsModule, Validators } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-add-truck-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,MatInputModule,NgbModule,MatExpansionModule],
  templateUrl: './add-truck-modal.component.html',
  styleUrl: './add-truck-modal.component.scss'
})
export class AddTruckModalComponent {
  ElForm: FormGroup;
  currentLang;
	translationsObj;
  routeListData: any[] = [];
	sitesListData: any[] = [];
	usageType = 1;
  PartialManagement: boolean = false;
  isMobile: Boolean;
  panelOpenState: boolean = false;

  constructor(private apiService:ApiService, private translator: TranslatorService,private apiQuery:ApiQuery,private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddTruckModalComponent>, private responsiveService: ResponsiveService) {
      this.dialogRef.disableClose = true;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      })

      this.ElForm = this.fb.group({
        siteName:new FormControl('', Validators.required),
        truckCapacity:new FormControl('', Validators.required),
        maxNumOfBinsToCollect:new FormControl('',Validators.required),
        customSunday:new FormControl(''),
        customMonday:new FormControl(''),
        customTuesday:new FormControl(''),
        customWednesday:new FormControl(''),
        customThursday:new FormControl(''),
        customFriday:new FormControl(''),
        customSaturday:new FormControl(''),
        truckLicensePlate:new FormControl('',Validators.required),
        routeType:new FormControl('',Validators.required)
      });
  }

  ngOnInit() {   
    this.onResize();
	  this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	
      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}

      if(this.PartialManagement){
        this.ElForm.controls['siteName'].disable();
        this.ElForm.controls['truckCapacity'].disable();
        this.ElForm.controls['maxNumOfBinsToCollect'].disable();
        this.ElForm.controls['routeType'].disable();                        
      }
		});    

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      if (sitesList.length > 0){
        this.subscribeSitesList(sitesList);
        if(sitesList.length == 1){
          this.ElForm.controls['siteName'].setValue(sitesList[0]);
        }
      }
    });

    this.apiQuery.optimizationTypes$.subscribe((optimizationTypes) => {
      if (optimizationTypes.length == 0){return;}      
      this.routeListData = optimizationTypes;   
    });
   }

   onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
   }
   
	subscribeSitesList = (sitesList: any[]) => {
    this.sitesListData = sitesList;   
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  saveWorkplan = () => {
    if(!this.ElForm.valid){
			return;
		}
    let request = {};    
    request["max_capacity_in_liters"] = Number(this.ElForm.getRawValue()['truckCapacity']);
    request["site_id"] = this.ElForm.getRawValue()['siteName'].site_id;    
    request["sim_id"] = '';
    request["max_capacity_in_bins"] = Number(this.ElForm.getRawValue()['maxNumOfBinsToCollect']);
    request["assigned_truck_license_plate"] = this.ElForm.getRawValue()['truckLicensePlate'];
    request["route_optimization_type_id"] = this.ElForm.getRawValue()['routeType'].optimization_type_id;    

    request["tablet_teams_allocation_id"] = 1;
    request["is_default_tablet"] = 1;
    request["tablet_name"] = '';
    request["driver_rest_time_in_min"] = 0;
    request["driver_rest_cycle_in_min"] = 0;

    request["tablet_daily_capacity_list"] = [];
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customSunday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customMonday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customTuesday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customWednesday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customThursday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customFriday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customSaturday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    this.apiService.insertNewTruckTabletInfo(request).subscribe((v:any) => {     
      if(v.updateStatus){
        let userGeneratedWorkplans = {
          site_id:0
        }
        this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
        this.closeModal();
      }
   });    
  }
}
