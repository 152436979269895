import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import _ from 'lodash';
import { TranslatorService } from '../../../services/translator_service';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../web-services/api/api.service';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-manage-truck-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,CommonModule,
    MatFormFieldModule,MatSelectModule,MatInputModule,NgbModule,MatExpansionModule],
  templateUrl: './manage-truck-modal.component.html',
  styleUrl: './manage-truck-modal.component.scss'
})
export class ManageTruckModalComponent {
  ElForm: FormGroup;
  currentLang;
	translationsObj;
  translateSub;
  routeListData: any[] = [];
	sitesListData: any[] = [];
	usageType = 1;
  PartialManagement: boolean = false;
  isMobile: Boolean;
  panelOpenState: boolean = false;

  constructor(private apiService:ApiService, private translator: TranslatorService,private apiQuery:ApiQuery,
    private dialogRef: MatDialogRef<ManageTruckModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
    private responsiveService: ResponsiveService,private fb: FormBuilder) {
      this.dialogRef.disableClose = true;
      this.translator.currentLangEmitter$
      .subscribe(async value=>{						
        this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
        this.currentLang = value;	
      });

      this.ElForm = this.fb.group({
          truckId:new FormControl('', Validators.required),
          siteName:new FormControl('', Validators.required),
          truckCapacity:new FormControl('', Validators.required),
          maxNumOfBinsToCollect:new FormControl('',Validators.required),
          customSunday:new FormControl(''),
          customMonday:new FormControl(''),
          customTuesday:new FormControl(''),
          customWednesday:new FormControl(''),
          customThursday:new FormControl(''),
          customFriday:new FormControl(''),
          customSaturday:new FormControl(''),
          truckLicensePlate:new FormControl('',Validators.required),
          routeType:new FormControl('',Validators.required)
        });
  }

  ngOnInit() {   
    this.onResize();
	  this.responsiveService.checkWidth();
    this.apiQuery.user$.subscribe(user => {
			if (!user) {
				return;
			}
			
			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}	

      if (user["user_role_id"] == 4) {
				this.PartialManagement = true;
			}
      
      if(this.PartialManagement){
        this.ElForm.controls['siteName'].disable();
        this.ElForm.controls['truckCapacity'].disable();
        this.ElForm.controls['maxNumOfBinsToCollect'].disable();
        this.ElForm.controls['routeType'].disable();  
        this.ElForm.controls['customSunday'].disable();                        
        this.ElForm.controls['customMonday'].disable();                        
        this.ElForm.controls['customTuesday'].disable();                        
        this.ElForm.controls['customWednesday'].disable();                        
        this.ElForm.controls['customThursday'].disable();                        
        this.ElForm.controls['customFriday'].disable();                        
        this.ElForm.controls['customSaturday'].disable();                        
      }
		});
    
    if(this.data.chosenTruck.tablet_id!=undefined){
      this.ElForm.controls['truckId'].setValue(this.data.chosenTruck.tablet_id)
    }else{
      this.ElForm.controls['truckId'].setValue(this.data.chosenTruck.truck_id)
    }
    
    this.ElForm.controls['truckId'].disable();                        

    if(this.data.chosenTruck.max_capacity_in_liters != undefined){
      this.ElForm.controls['truckCapacity'].setValue(this.data.chosenTruck.max_capacity_in_liters);
    }else{
      this.ElForm.controls['truckCapacity'].setValue(this.data.chosenTruck.truck_volume);
    }

    if(this.data.chosenTruck.max_capacity_in_bins != undefined){
      this.ElForm.controls['maxNumOfBinsToCollect'].setValue(this.data.chosenTruck.max_capacity_in_bins);
    }
    
    if(this.data.chosenTruck.assigned_truck_license_plate != undefined){
      this.ElForm.controls['truckLicensePlate'].setValue(this.data.chosenTruck.assigned_truck_license_plate);
    }     
    
    if(this.data.chosenTruck.tablets_daily_capacity.length > 0){
      this.ElForm.controls['customSunday'].setValue(this.data.chosenTruck.tablets_daily_capacity[0].max_capacity_in_bins);
      this.ElForm.controls['customMonday'].setValue(this.data.chosenTruck.tablets_daily_capacity[1].max_capacity_in_bins);
      this.ElForm.controls['customTuesday'].setValue(this.data.chosenTruck.tablets_daily_capacity[2].max_capacity_in_bins);
      this.ElForm.controls['customWednesday'].setValue(this.data.chosenTruck.tablets_daily_capacity[3].max_capacity_in_bins);
      this.ElForm.controls['customThursday'].setValue(this.data.chosenTruck.tablets_daily_capacity[4].max_capacity_in_bins);
      this.ElForm.controls['customFriday'].setValue(this.data.chosenTruck.tablets_daily_capacity[5].max_capacity_in_bins);
      this.ElForm.controls['customSaturday'].setValue(this.data.chosenTruck.tablets_daily_capacity[6].max_capacity_in_bins);
    }

    this.apiQuery.sitesListData$.subscribe((sitesList) => {
      if (sitesList.length > 0){
        this.subscribeSitesList(sitesList);
      }
    });

    this.apiQuery.optimizationTypes$.subscribe((optimizationTypes) => {
      if (optimizationTypes.length == 0){return;}      
      this.routeListData = optimizationTypes;
      if (this.routeListData.length > 0){
        _.each(this.routeListData, (item) => {
          if(this.data.chosenTruck.optimization_type_id != undefined){
            if(this.data.chosenTruck.optimization_type_id == item.optimization_type_id){
              this.ElForm.controls['routeType'].setValue(item);
            }
          }
        });
      }   
    });
   }

   onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
   }
   
	private subscribeSitesList = (sitesList: any[]) => {
    this.sitesListData = sitesList;   
    _.each(this.sitesListData, (item) => {
      if(this.data.chosenTruck.site_id != undefined){
        if(this.data.chosenTruck.site_id == item.site_id){
          this.ElForm.controls['siteName'].setValue(item);
        }
      }else{
        if(this.data.chosenTruck.site_name == item.site_name){
          this.ElForm.controls['siteName'].setValue(item);
        }
      }
    });
    
    if (this.sitesListData.length > 0){
      _.each(this.sitesListData, (item) => {
        if(this.data.chosenTruck.site_id != undefined){
          if(this.data.chosenTruck.site_id == item.site_id){
            this.ElForm.controls['siteName'].setValue(item);
          }
        }else{
          if(this.data.chosenTruck.site_name == item.site_name){
            this.ElForm.controls['siteName'].setValue(item);
          }
        }
      });
    }
  }

  closeModal = () => {
    this.dialogRef.close();
  }

  saveWorkplan = () => {
    if(!this.ElForm.valid){
			return;
		}
    let request = {};    
    request["tablet_id_list"] = [Number(this.ElForm.getRawValue()['truckId'])];
    request["max_capacity_in_bins"] = Number(this.ElForm.getRawValue()['maxNumOfBinsToCollect']);
    request["max_capacity_in_liters"] = Number(this.ElForm.getRawValue()['truckCapacity']);
    request["new_site_id"] = this.ElForm.getRawValue()['siteName'].site_id;    
    request["assigned_truck_license_plate"] = this.ElForm.getRawValue()['truckLicensePlate'];
    request["route_optimization_type_id"] = this.ElForm.getRawValue()['routeType'].optimization_type_id;
    request["tablet_teams_allocation_id"] = this.data.chosenTruck.tablet_teams_allocation_id;
    request["is_default_tablet"] = this.data.chosenTruck.is_default_tablet;
    request["is_delete"] = 0; 
    request["tablet_name"] = '';
    request["tablet_daily_capacity_list"] = [];
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customSunday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customMonday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customTuesday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customWednesday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customThursday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customFriday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    request["tablet_daily_capacity_list"].push({max_capacity_in_bins:this.ElForm.getRawValue()['customSaturday'],max_capacity_in_liters:this.ElForm.getRawValue()['truckCapacity']});
    this.apiService.UpdateTrucksTabletsInfo(request).subscribe((v:any) => {    
      if(v.updateStatus){
        let userGeneratedWorkplans = {
          site_id:0
        }
        this.apiService.getTrucksTabletsInfo(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));		
        this.closeModal();
      }
   });    
  }

}
