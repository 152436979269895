@if(!isMobile){
  <div>
    <div class="padded inventory-page" rtlDiv>
      <div class="inventory-header" rtlDiv>
        <div class='inventory-header-left'>
          <div class='header-bold' rtlDiv>{{
            'SIDEBAR.INVENTORY' | translate }}
          </div>
        </div>
      </div>
      <mat-divider class="top-divider"></mat-divider>
    </div>

    <div class="inventory-body">
      <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" [dir]="currentLang ? (currentLang == 'iw' ? 'rtl' : 'ltr') : 'auto'">
        <mat-tab>
          <ng-template mat-tab-label>
            @if(usageType == 1){
              <span>{{'SITE_MANAGMENT.BINS' | translate}}</span>
            }
            @else{
              <span>{{'SITE_MANAGMENT.TANKS' | translate}}</span>
            }
          </ng-template>
          <mat-card>
            <div class='spinner-container' [hidden]="!spinnerActive">
              <div class="spinner"></div>
            </div>
            <mat-card-content [hidden]="spinnerActive">
              <div class='search-input-container' rtlDiv>
                <div class="search-frame">
                  <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                  <input class='search-input' type="text" (input)="applyFilter($event.target)" placeholder="{{
                    'OTHER.SEARCH' | translate }}" id="bin-search-input">
                  </div>
                  <div class='table-export-container' rtlDiv>
                    <div class='table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                      <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                      <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                    </div>
                    @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6 || user_role_id == 4) {
                      <div class="manage-bins-btn-container" [ngClass]="{'disableBtn' : selection.selected.length < 2 , 'enableBtn' : selection.selected.length > 1}" (click)="openManageModalForMulti()" [hidden]="spinnerActive" rtlDiv>
                        <a class="manage-bins-btn" rtlDiv>
                          @if (usageType == 1) {
                            <span>{{ 'MANAGE_BIN.MANAGE_BINS' | translate }}</span>
                          }
                          @if (usageType != 1) {
                            <span>{{ 'MANAGE_BIN.MANAGE_TANKS' | translate }}</span>
                          }
                          <span>
                            <img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/>
                          </span>
                        </a>
                      </div>
                    }
                  </div>
                </div>
                <div class="dflex-days">
                  <div class="width100">
                    <table class="table" mat-table #table [dataSource]="getTableData()" matSort matSortStart="asc"
                      matSortActive="bin_name" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc">
                      <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                          <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                          </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                          </mat-checkbox>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <th [hidden]="!binNameCol && usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="bin_name" class="space th-title"> {{ 'LIST_COLUMNS.NAME' | translate }} </th>
                        <td [hidden]="!binNameCol && usageType == 2" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                          <div class="flex-th-value">
                            <a [routerLink]="['/binLiveInfoPage']" [queryParams]="{pageName:'inventory',binId:element.bin_id}"  title="{{element ? element.bin_name : ''}}" class="binNameHref"> {{element ? element.bin_name : ''}}</a>
                            @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                              <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn" type="button">
                                <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                              </button>
                            }
                            @if (element.isOpenTicket) {
                              <div>
                                <img src="assets/images/binInfoPage/open_ticket_indication.svg" alt="" />
                              </div>
                            }
                          </div>
                        </td>
                      </ng-container>

                        <ng-container matColumnDef="site_name">
                          <th [hidden]="!binSiteCol" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="space th-title"> {{ 'TRUCK_MAP.SITE' | translate }} </th>
                          <td [hidden]="!binSiteCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.site_name: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="id">
                          <th [hidden]="!binIdCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="space th-title"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                          <td [hidden]="!binIdCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.bin_id: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="device_id">
                          <th [hidden]="!binDeviceIdCol" mat-header-cell *matHeaderCellDef mat-sort-header="device_id" class="space th-title"> {{ 'SITE_MANAGMENT.DEVICE_ID' | translate }} </th>
                          <td [hidden]="!binDeviceIdCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.device_id: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                          <th [hidden]="!binTypeCol" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="space th-title"> {{ usageType == 1 ? (translationsObj ? translationsObj.LIST_COLUMNS.BIN_TYPE : '') : (translationsObj ? translationsObj.LIST_COLUMNS.TANK_TYPE : '')}} </th>
                          <td [hidden]="!binTypeCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.bin_type: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="address">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="address" class="space th-title"> {{ 'LIST_COLUMNS.ADDRESS'| translate }} </th>
                          <td mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.address: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="neighborhood">
                          <th [hidden]="!neighborhood || usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="space th-title">{{
                            'LIST_COLUMNS.NEIGHBORHOOD' | translate
                          }}</th>
                          <td [hidden]="!neighborhood || usageType == 2" class="space td-value" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.neighborhood: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cluster_id">
                          <th [hidden]="usageType == 1" mat-header-cell *matHeaderCellDef mat-sort-header="cluster_id" class="space th-title"> {{
                            'LIST_COLUMNS.DESCRIPTION' | translate
                          }} </th>
                          <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.description: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                          <th [hidden]="usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="description" class="space th-title"> {{
                            'LIST_COLUMNS.DESCRIPTION' | translate
                          }} </th>
                          <td [hidden]="usageType == 2" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.description: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="size">
                          <th [hidden]="usageType == 1" mat-header-cell *matHeaderCellDef mat-sort-header="size" class="space th-title">
                            {{'LIST_COLUMNS.QUARTER_ACRE' | translate}}
                          </th>
                          <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.size: ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="installation_Date">
                          <th [hidden]="!binInstallDateCol" mat-header-cell *matHeaderCellDef mat-sort-header="installation_DateTime_value" class="space th-title"> {{
                            'LIST_COLUMNS.INSTALLATION_DATE' | translate
                          }} </th>
                          <td [hidden]="!binInstallDateCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                            <span>{{element ? element.bin_installation_date : ''}}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="last_collection">
                          <th [hidden]="usageType != 1" mat-header-cell *matHeaderCellDef mat-sort-header="last_collection" class="space th-title"> {{
                            'LIST_COLUMNS.LAST_COLLECTION' |
                          translate }} </th>
                          <td [hidden]="usageType != 1" mat-cell *matCellDef="let element" class="space td-value">
                            @if (element.collection_mode != 1) {
                              <span title="{{element.last_collection}}">{{element.calcDiff_lastCollection}}</span>
                            }
                            @if (element.collection_mode == 1) {
                              <hr class="dashed-hr">
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="last_refill">
                          <th [hidden]="usageType == 1" mat-header-cell *matHeaderCellDef mat-sort-header="last_refill" class="space th-title"> {{
                            'LIST_COLUMNS.LAST_REFILL' |
                          translate }} </th>
                          <td [hidden]="usageType == 1" mat-cell *matCellDef="let element" class="space td-value">
                            @if (element.collection_mode != 1) {
                              <span>{{element.calcDiff_lastRefill}}</span>
                            }
                            @if (element.collection_mode == 1) {
                              <hr class="dashed-hr">
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="last_update">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="last_update" class="space th-title"> {{
                            'LIST_COLUMNS.LAST_UPDATE' |
                          translate }} </th>
                          <td mat-cell *matCellDef="let element" class="space td-value">
                            @if (element.collection_mode != 1) {
                              <span title="{{element.last_update}}">{{element.calcDiff_lastUpdate}}</span>
                            }
                            @if (element.collection_mode == 1) {
                              <hr class="dashed-hr">
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="space th-title">
                            {{'LIST_COLUMNS.STATUS' | translate }}
                          </th>
                          <td mat-cell *matCellDef="let element" class="space">
                            @if (element.collection_mode != 1) {
                              <div class="progress-flex">
                                <div class="table-status-progress-bar-wrapper progress">
                                  <div class="table-status-progress-line progress-bar" [ngStyle]="getProgressBarStyleInternal(element)"></div>
                                </div>
                                <div class='table-status-progress-bar-title' rtlDiv>{{element ? element.bin_capacity_to_display: ''}}</div>
                              </div>
                            }
                            @if (element.collection_mode == 1) {
                              <hr class="dashed-hr">
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="avg_rate">
                          <th [hidden]="!binAvgRateCol" mat-header-cell *matHeaderCellDef mat-sort-header="avg_rate" class="space th-title">
                            @if (usageType == 1) {
                              <span>{{'LIVE_INFO.EMPTY_RATE' | translate}}</span>
                            }
                            @if (usageType == 2) {
                              <span>{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</span>
                            }
                          </th>
                          <td [hidden]="!binAvgRateCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                            @if (usageType == 1) {
                              <span>{{element.avg_rate | number:'1.0-2'}}{{element.avg_rate != null ? '%' : ''}} </span>
                            }
                            @if (usageType == 2) {
                              <span>{{element.avg_rate | number:'2.1-2'}}{{element.avg_rate != null ? 'L' : ''}} </span>
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="avg_usage_rate">
                          <th [hidden]="!binAvgUsageRateCol || usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="avg_usage_rate" class="space th-title">
                            {{ 'LIVE_INFO.AVG_RATE' | translate }}
                          </th>
                          <td [hidden]="!binAvgUsageRateCol || usageType == 2" class="space td-value" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element?.avg_usage_rate != -1 ? element?.avg_usage_rate + '%': ''}}</td>
                        </ng-container>

                        <ng-container matColumnDef="collection_frequency">
                          <th [hidden]="!binCollectionFrequencyCol || usageType == 2" mat-header-cell *matHeaderCellDef mat-sort-header="collection_frequency" class="space th-title">{{
                            'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate
                          }}</th>
                          <td [hidden]="!binCollectionFrequencyCol || usageType == 2" class="space td-value" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element?.collection_frequency != -1 ? element?.collection_frequency : ''}} </td>
                        </ng-container>

                        <ng-container matColumnDef="emptying_dunam">
                          <th [hidden]="usageType == 1 || !avgDailyRateDunamCol" mat-header-cell *matHeaderCellDef mat-sort-header="emptying_dunam" class="space th-title">
                          {{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }} </th>
                          <td [hidden]="usageType == 1 || !avgDailyRateDunamCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                            @if (currentLang == 'iw') {
                              <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                <span>ל/ד</span>
                              } </span>
                            }
                            @if (currentLang != 'iw') {
                              <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                <span>L/D</span>
                              }</span>
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="predicted_time_for_max_allowed_capacity">
                          <th [hidden]="!binPredictedTimeForMaxAllowedCapacityCol" mat-header-cell *matHeaderCellDef mat-sort-header="predicted_time_for_max_allowed_capacity_value" class="space th-title"> {{
                            'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate
                          }} </th>
                          <td [hidden]="!binPredictedTimeForMaxAllowedCapacityCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                            <span>{{element ? element.predicted_time_for_max_allowed_capacity : ''}}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="black_status">
                          <th [hidden]="!binBlackStatusCol" mat-header-cell *matHeaderCellDef mat-sort-header="over_flow_dates" class="space th-title">
                          {{'MANAGE_BIN.BLACK_STATUS' | translate }} </th>
                          <td [hidden]="!binBlackStatusCol" mat-cell *matCellDef="let element" class="space td-value" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                            @if (showLoadingForCurrentCapacityAnalysis) {
                              <ng-lottie [options]="options"></ng-lottie>
                            }
                            @if (!showLoadingForCurrentCapacityAnalysis && element.over_flow_dates != 0) {
                              <span rtlDiv>{{element.over_flow_dates}}</span>
                            }
                            @if (!showLoadingForCurrentCapacityAnalysis && element.over_flow_dates >= element.analysis_time_span_in_days && element.over_flow_dates != 0) {
                              <span>+</span>
                            }
                            @if (element.number_of_bins_in_cluster > 1 && !showLoadingForCurrentCapacityAnalysis && element.over_flow_dates != 0) {
                              <span> ({{element.cluster_avg.toFixed(0)}}{{usageType == 1 ? '%' : 'L'}})</span>
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="collection_mode">
                          <th [hidden]="!binCollectionModeCol" mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="space th-title"> {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }} </th>
                          <td [hidden]="!binCollectionModeCol" mat-cell *matCellDef="let element" class="space td-value">
                            <img class='search-input-img' title="{{element ? getTitle(element) : ''}}" src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="collection_days">
                          <th [hidden]="!binCollectionDaysCol" mat-header-cell *matHeaderCellDef mat-sort-header="active_collection_days" class="space th-title"> {{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }} </th>
                          <td [hidden]="!binCollectionDaysCol" mat-cell *matCellDef="let element" class="space td-value">
                            @if (showLoadingForCollectionDays) {
                              <ng-lottie [options]="options"></ng-lottie>
                            }
                            @if (!showLoadingForCollectionDays && element.collection_days) {
                              <div class="dflex-days">
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].sunday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].sunday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].sunday,'text-day-notSelected': !element.collection_days[0].sunday}">{{ 'NEW_WORKPLAN.SUNDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].monday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].monday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].monday,'text-day-notSelected': !element.collection_days[0].monday}">{{ 'NEW_WORKPLAN.MONDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].tuesday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].tuesday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].tuesday,'text-day-notSelected': !element.collection_days[0].tuesday}">{{ 'NEW_WORKPLAN.TUESDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].wednesday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].wednesday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].wednesday,'text-day-notSelected': !element.collection_days[0].wednesday}">{{ 'NEW_WORKPLAN.WEDNESDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].thursday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].thursday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].thursday,'text-day-notSelected': !element.collection_days[0].thursday}">{{ 'NEW_WORKPLAN.THURSDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].friday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].friday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].friday,'text-day-notSelected': !element.collection_days[0].friday}">{{ 'NEW_WORKPLAN.FRIDAY_COLLECTION' | translate }}</div>
                                </div>
                                <div class="grayCircle-container">
                                  @if (element.collection_days[0].saturday) {
                                    <img src="assets/images/inventory/grayCircle.svg" alt=""/>
                                  }
                                  @if (!element.collection_days[0].saturday) {
                                    <img src="assets/images/inventory/whiteCircle.svg" alt=""/>
                                  }
                                  <div [ngClass]="{'text-day': element.collection_days[0].saturday,'text-day-notSelected': !element.collection_days[0].saturday}">{{ 'NEW_WORKPLAN.SATURDAY_COLLECTION' | translate }}</div>
                                </div>
                              </div>
                            }
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="manage">
                          <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="manage"> </th>
                          <td mat-cell *matCellDef="let element" class="manage-bins">
                            @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6 || user_role_id == 4) {
                              <div class="manage-bin-button hide-manage-bin" (click)="openManageBinModal(element)" rtlDiv>
                                @if (usageType == 1) {
                                  <span>{{'TITLES.MANAGE_BIN' | translate }}</span>
                                }
                                @if (usageType != 1) {
                                  <span>{{'TITLES.MANAGE_TANK' | translate }}</span>
                                }
                              </div>
                            }
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr class="bins-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                      <div [hidden]="spinnerActive || dataTableLength == 0">
                        <app-pagination [chosenBins]="filteredBinsData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
                      </div>
                    </div>
                    <div class="add-col">
                      <div>
                        <img (click)="showDropDown()" class="enableBtn" rtlDiv id="dropdownMenuButton" data-bs-toggle="dropdown" src="assets/images/dashboard/addCol.svg" alt=""/>
                        <div class="dropdown-menu list-items" rtlDiv aria-labelledby="dropdownMenuButton">
                          <div class="inner-dropdown">
                            @if (usageType == 1) {
                              <div class="flexOnly" rtlDiv>
                                <input class="inputChx" rtlDiv [(ngModel)]="isNeighborhoodChecked" [checked]="neighborhood" (change)="toggleEditable(isNeighborhoodChecked)" type="checkbox" id="scalesNeighborhood" name="scalesNeighborhood">
                                <label for="scalesNeighborhood" class="txt">{{ 'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</label>
                              </div>
                            }
                            <div class="flexOnly" rtlDiv [hidden]="usageType == 1">
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinNameColChecked" [checked]="binNameCol" (change)="toggleEditableName(isBinNameColChecked)" type="checkbox" id="scalesName" name="scalesName">
                              <label for="scalesName" class="txt">{{ 'LIST_COLUMNS.NAME' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinSiteColChecked" [checked]="binSiteCol" (change)="toggleEditableSite(isBinSiteColChecked)" type="checkbox" id="scalesSite" name="scalesSite">
                              <label for="scalesSite" class="txt">{{ 'TRUCK_MAP.SITE' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinIdColChecked" [checked]="binIdCol" (change)="toggleEditableId(isBinIdColChecked)" type="checkbox" id="scalesId" name="scalesId">
                              <label for="scalesId" class="txt">{{ 'LIST_COLUMNS.ID' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinTypeColChecked" [checked]="binTypeCol" (change)="toggleEditableType(isBinTypeColChecked)" type="checkbox" id="scalesType" name="scalesType">
                              @if (usageType == 1) {
                                <label for="scalesType" class="txt">{{ 'LIST_COLUMNS.BIN_TYPE' | translate }}</label>
                              }
                              @if (usageType != 1) {
                                <label for="scalesType" class="txt">{{ 'LIST_COLUMNS.TANK_TYPE' | translate }}</label>
                              }
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinInstallDateColChecked" [checked]="binInstallDateCol" (change)="toggleEditableInstallation(isBinInstallDateColChecked)" type="checkbox" id="scalesInstallation" name="scalesInstallation">
                              <label for="scalesInstallation" class="txt">{{ 'LIST_COLUMNS.INSTALLATION_DATE' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinAvgRateColChecked" [checked]="binAvgRateCol" (change)="toggleEditableAvgRate(isBinAvgRateColChecked)" type="checkbox" id="scalesAvgRate" name="scalesAvgRate">
                              @if (usageType == 1) {
                                <label for="scalesAvgRate" class="txt">{{ 'LIVE_INFO.EMPTY_RATE' | translate }}</label>
                              }
                              @if (usageType == 2) {
                                <label for="scalesAvgRate" class="txt">{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</label>
                              }
                            </div>
                            @if (usageType == 1) {
                              <div class="flexOnly" rtlDiv>
                                <input class="inputChx" rtlDiv [(ngModel)]="isBinAvgUsageRateChecked" [checked]="binAvgUsageRateCol" (change)="toggleEditableAvgUsageRate(isBinAvgUsageRateChecked)" type="checkbox" id="scalesAvgUsageRate" name="scalesAvgUsageRate">
                                <label for="scalesAvgUsageRate" class="txt">{{ 'LIVE_INFO.AVG_RATE' | translate }}</label>
                              </div>
                            }
                            @if (usageType == 1) {
                              <div class="flexOnly" rtlDiv>
                                <input class="inputChx" rtlDiv [(ngModel)]="isBinCollectionFrequencyChecked" [checked]="binCollectionFrequencyCol" (change)="toggleEditableCollectionFrequency(isBinCollectionFrequencyChecked)" type="checkbox" id="scalesCollectionFrequency" name="scalesCollectionFrequency">
                                <label for="scalesCollectionFrequency" class="txt">{{ 'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</label>
                              </div>
                            }
                            @if (usageType == 2) {
                              <div class="flexOnly" rtlDiv>
                                <input class="inputChx" rtlDiv [(ngModel)]="isAvgDailyRateDunamColChecked" [checked]="avgDailyRateDunamCol" (change)="toggleEditableAvgDailyRateDunam(isAvgDailyRateDunamColChecked)" type="checkbox" id="scalesAvgDailyRateDunam" name="scalesAvgDailyRateDunam">
                                <label for="scalesAvgDailyRateDunam" class="txt">{{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</label>
                              </div>
                            }
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinPredictedTimeForMaxAllowedCapacityChecked" [checked]="binPredictedTimeForMaxAllowedCapacityCol" (change)="toggleEditablePredictedTimeForMaxAllowedCapacity(isBinPredictedTimeForMaxAllowedCapacityChecked)" type="checkbox" id="scalesPredictedTimeForMaxAllowedCapacity" name="scalesPredictedTimeForMaxAllowedCapacity">
                              <label for="scalesPredictedTimeForMaxAllowedCapacity" class="txt">{{ 'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate }}</label>
                            </div>
                            <div class="flexOnly flex-gap" rtlDiv>
                              <div class="flex-black-status">
                                <input class="inputChx" rtlDiv [(ngModel)]="isBlackStatusChecked" [checked]="binBlackStatusCol" (change)="toggleEditableBlackStatus(isBlackStatusChecked)" type="checkbox" id="scalesBlackStatus" name="scalesBlackStatus">
                                <label rtlDiv for="scalesBlackStatus" class="txt">{{ 'MANAGE_BIN.BLACK_STATUS' | translate }} ({{blackStatusValue}}%)</label>
                              </div>
                              <div rtlDiv>
                                <form class="blackStatus" [formGroup]="ElForm" rtlDiv>
                                  @if (!editBlackStatusFlag) {
                                    <div class="balck-status-input-frame">
                                      <input min="90" max="100" class="black-status-input" type="number" matInput formControlName="blackStatusBin" rtlDiv>
                                      <img [ngClass]="{'noClick': ElForm.getRawValue()['blackStatusBin'] < 90 || ElForm.getRawValue()['blackStatusBin'] > 100,'enableBtn': ElForm.getRawValue()['blackStatusBin'] >= 90 && ElForm.getRawValue()['blackStatusBin'] <= 100}" (click)="submitBlackStatus()" src="assets/images/inventory/submit-V.svg" alt=""/>
                                    </div>
                                  }
                                  @if (editBlackStatusFlag) {
                                    <div class="balck-status-edit-frame" (click)="editBlackStatus();$event.stopPropagation()">
                                      <div>{{ 'TITLES.EDIT' | translate }}</div>
                                    </div>
                                  }
                                </form>
                              </div>
                            </div>                      
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinCollectionModeChecked" [checked]="binCollectionModeCol" (change)="toggleEditableCollectionMode(isBinCollectionModeChecked)" type="checkbox" id="scalesCollectionMode" name="scalesCollectionMode">
                              <label for="scalesType" class="txt">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinCollectionDaysChecked" [checked]="binCollectionDaysCol" (change)="toggleEditableCollectionDays(isBinCollectionDaysChecked)" type="checkbox" id="scalesCollectionDays" name="scalesCollectionDays">
                              <label for="scalesCollectionDays" class="txt">{{ 'MANAGE_BIN.BIN_COLLECTION_DAYS' | translate }}</label>
                            </div>
                            <div class="flexOnly" rtlDiv>
                              <input class="inputChx" rtlDiv [(ngModel)]="isBinDeviceIdColChecked" [checked]="binDeviceIdCol" (change)="toggleEditableDeviceId(isBinDeviceIdColChecked)" type="checkbox" id="scalesDeviceId" name="scalesDeviceId">
                              <label for="scalesDeviceId" class="txt">{{ 'SITE_MANAGMENT.DEVICE_ID' | translate }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-tab>
            @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6 || user_role_id == 4) && usageType == 1) {
              <mat-tab label="{{'NEW_WORKPLAN.TRUCKS' | translate}}">
                <mat-card>
                  <div class='spinner-container' [hidden]="!spinnerTrucksActive">
                    <div class="spinner"></div>
                  </div>
                  <mat-card-content [hidden]="spinnerTrucksActive">
                    <div class='search-input-container' rtlDiv>
                      <div class="search-frame">
                        <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                      <input class='search-input' type="text" (input)="applyTrucksFilter($event.target)" placeholder="{{
                        'OTHER.SEARCH' | translate }}" id="truck-search-input">
                        </div>
                        <div class="btns-container">
                          <div class='table-export-container' rtlDiv>
                            <div class='table-export-btns-wrapper' (click)="exportTrucksToCsv()" rtlDiv>
                              <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                              <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                            </div>
                          </div>
                          @if(user_role_id == 1 || user_role_id == 2 || user_role_id == 5) {
                            <div class="addBtn-frame" (click)="openAddTruckModal()">
                              <span class="addBtn-txt">{{ 'TITLES.ADD_TRUCK' | translate }}</span>
                            </div>
                          }
                          @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5) && truckSelection.selected.length > 1) {
                            <div class="manage-bins-btn-container" (click)="openDeleteTrucksModal(truckSelection.selected)" [hidden]="spinnerTrucksActive" rtlDiv>
                              <a class="delete-clusters" rtlDiv>
                                <img src="assets/images/bins/green_bin.svg" rtlDiv/>
                                <span>{{'TITLES.DELETE' | translate }} ({{truckSelection.selected.length}}) {{'NEW_WORKPLAN.TRUCKS' | translate}}</span>
                              </a>
                            </div>
                          }
                        </div>
                      </div>
                      <table class="table" mat-table #truckTable [dataSource]="getTrucksData()" matSort matSortStart="asc"
                        matSortActive="truck_volume" matSortDisableClear (matSortChange)="sortTrucksData($event)" matSortDirection="desc">
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterTrucksToggle() : null"
                              [checked]="truckSelection.hasValue() && isTrucksAllSelected()"
                              [indeterminate]="truckSelection.hasValue() && !isTrucksAllSelected()">
                            </mat-checkbox>
                          </th>
                          <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? truckSelection.toggle(row) : null"
                              [checked]="truckSelection.isSelected(row)">
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="truck_id">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="truck_id" class="space th-title">
                          {{ 'TITLES.TRUCK_ID' | translate }} </th>
                          <td mat-cell *matCellDef="let element"> <span title="{{ 'NEW_WORKPLAN.TRUCK' | translate }} {{element ? element.truck_id : ''}}" class="space td-value">{{ 'NEW_WORKPLAN.TRUCK' | translate }} {{element ? element.truck_id : ''}}</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="driver_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="driver_name" class="space th-title">
                          {{ 'TITLES.DRIVER_NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.driver_name: ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="site_name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="space th-title">
                          {{ 'TITLES.SITE_NAME' | translate }} </th>
                          <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_name: ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="truck_volume">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="truck_volume" class="space th-title">
                          {{'TITLES.TRUCK_CAPACITY' | translate }}</th>
                          <td class="space td-value" mat-cell *matCellDef="let element"> {{element ? element.truck_volume: ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="max_capacity">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="max_capacity" class="space th-title">
                          {{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</th>
                          <td class="space td-value" mat-cell *matCellDef="let element"> {{element ? element.max_capacity: ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="license_plate">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header="license_plate" class="space th-title">
                          {{'TITLES.LICENSE_PLATE' | translate }}</th>
                          <td class="space td-value" mat-cell *matCellDef="let element"> {{element ? element.license_plate: ''}} </td>
                        </ng-container>
                        <ng-container matColumnDef="delete">
                          <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="delete"> </th>
                          <td mat-cell *matCellDef="let element" class="manage-bins">
                            @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5) {
                              <div class="manage-truck-button hide-manage-truck" (click)="openDeleteTrucksModal(element)" rtlDiv>
                              {{'TITLES.DELETE' | translate }}</div>
                            }
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                          <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="edit"> </th>
                          <td mat-cell *matCellDef="let element" class="manage-bins">
                            @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 4) {
                              <div class="manage-truck-button hide-manage-truck" (click)="openManageTruckModal(element)" rtlDiv>
                              {{'TITLES.EDIT' | translate }}</div>
                            }
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedTrucksColumns"></tr>
                        <tr class="trucks-row" mat-row *matRowDef="let row; columns: displayedTrucksColumns;"></tr>
                      </table>
                      @if (trucksTableLength > 0) {
                        <div>
                          <app-pagination [chosenBins]="filteredTrucksData" (pageChange)='onTrucksPageChange($event)' [filesPerPage]="10"></app-pagination>
                        </div>
                      }
                    </mat-card-content>
                  </mat-card>
                </mat-tab>
              }
              @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && usageType == 1) {
                <mat-tab label="{{'NEW_WORKPLAN.SITES' | translate}}">
                  <mat-card>
                    <div class='spinner-container' [hidden]="!spinnerSitesActive">
                      <div class="spinner"></div>
                    </div>
                    <mat-card-content [hidden]="spinnerSitesActive">
                      <div class='search-input-container' rtlDiv>
                        <div class="search-frame">
                          <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                        <input class='search-input' type="text" (input)="applySitesFilter($event.target)" placeholder="{{
                          'OTHER.SEARCH' | translate }}" id="site-search-input">
                          </div>
                          <div class='table-export-container' rtlDiv>
                            <div class='table-export-btns-wrapper' (click)="exportSitesToCsv()" rtlDiv>
                              <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                              <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                            </div>
                          </div>
                        </div>
                        <table class="table" mat-table [dataSource]="getSitesData()" matSort matSortStart="asc"
                          matSortActive="site_name" matSortDisableClear (matSortChange)="sortSitesData($event)" matSortDirection="desc">
                          <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                              <mat-checkbox (change)="$event ? masterSitesToggle() : null"
                                [checked]="siteSelection.hasValue() && isSitesAllSelected()"
                                [indeterminate]="siteSelection.hasValue() && !isSitesAllSelected()">
                              </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? siteSelection.toggle(row) : null"
                                [checked]="siteSelection.isSelected(row)">
                              </mat-checkbox>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="site_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="space th-title">
                            {{ 'TITLES.SITE_NAME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_name: ''}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Distribution_method">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Distribution_method" class="space th-title">
                            {{ 'LIST_COLUMNS.DISTRIBUTION_METHOD' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value">
                              @if (currentLang != 'iw') {
                                <span>{{element ? element.preferred_bin_distribution_method_name: ''}}</span>
                              }
                              @if (currentLang == 'iw') {
                                <span>{{element ? element.preferred_bin_distribution_method_name_hebrew: ''}}</span>
                              }
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Plan_start_time">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Plan_start_time" class="space th-title">
                            {{ 'LIST_COLUMNS.PLAN_START_TIME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element && element.preferred_plan_start_time ? element.preferred_plan_start_time.split(":")[0]+":"+element.preferred_plan_start_time.split(":")[1]: ''}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Plan_finish_time">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Plan_finish_time" class="space th-title">
                            {{ 'LIST_COLUMNS.PLAN_FINISH_TIME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element && element.preferred_plan_finish_time ? element.preferred_plan_finish_time.split(":")[0]+":"+element.preferred_plan_finish_time.split(":")[1]: ''}} </td>
                          </ng-container>
                          <ng-container matColumnDef="max_allowed_capacity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="max_allowed_capacity" class="space th-title">
                            {{ 'MANAGE_SITE.MAXIMUM_VOLUME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_max_allowed_capacity: ''}}% </td>
                          </ng-container>
                          <ng-container matColumnDef="Max_uncollected_days">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Max_uncollected_days" class="space th-title">
                            {{ 'LIST_COLUMNS.MAX_UNCOLLECTED_DAYS' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.days_gap_for_no_collection_alert: ''}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Avg_capacity_for_future_workplan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Avg_capacity_for_future_workplan" class="space th-title">
                            {{ 'LIST_COLUMNS.AVG_CAP_FUTURE_WORKPLAN' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.avg_capacity_in_percent_future_work_plan_alert: ''}}% </td>
                          </ng-container>
                          <ng-container matColumnDef="Max_days_gap_for_future_workplan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Max_days_gap_for_future_workplan" class="space th-title">
                            {{ 'LIST_COLUMNS.MAX_DAYS_GAP_FOR_FUTURE_WORKPLAN' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_days_gap_for_future_work_plan: ''}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Set_Fixed_Days_To_Collect">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Set_Fixed_Days_To_Collect" class="space th-title">
                            {{ 'MANAGE_SITE.SET_FIXED_DAYS_TO_COLLECT' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value">
                              @if (currentLang != 'iw') {
                                <span>{{element ? element.customer_work_plan_collection_schedule_policy_name: ''}}</span>
                              }
                              @if (currentLang == 'iw') {
                                <span>{{element ? element.customer_work_plan_collection_schedule_policy_name_hebrew: ''}}</span>
                              }
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Set_Max_Volume">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="Set_Max_Volume" class="space th-title">
                            {{ 'MANAGE_SITE.SET_MAX_VOLUME' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class="space td-value">
                              @if (element && element.is_use_site_max_allowed_capacity == 1) {
                                <span>{{ 'MANAGE_SITE.MAX_VOLUME_SITE' | translate }}</span>
                              }
                              @if (element && element.is_use_site_max_allowed_capacity != 1) {
                                <span>{{ 'MANAGE_SITE.MAX_VOLUME_BIN' | translate }}</span>
                              }
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="edit">
                            <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="edit"> </th>
                            <td mat-cell *matCellDef="let element" class="manage-bins">
                              @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                <div class="manage-site-button hide-manage-site" (click)="openManageSiteModal(element)" rtlDiv>
                                {{'TITLES.MANAGE_SITE' | translate }}</div>
                              }
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="displayedSitesColumns"></tr>
                          <tr class="sites-row" mat-row *matRowDef="let row; columns: displayedSitesColumns;"></tr>
                        </table>
                        @if (sitesTableLength > 0) {
                          <div>
                            <app-pagination [chosenBins]="filteredSitesData" (pageChange)='onSitesPageChange($event)' [filesPerPage]="10"></app-pagination>
                          </div>
                        }
                      </mat-card-content>
                    </mat-card>
                  </mat-tab>
                }
                @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && usageType == 1) {
                  <mat-tab label="{{'TITLES.USERS' | translate}}">
                    <mat-card>
                      <div class='spinner-container' [hidden]="!spinnerUsersActive">
                        <div class="spinner"></div>
                      </div>
                      <mat-card-content [hidden]="spinnerUsersActive">
                        <div class='search-input-container' rtlDiv>
                          <div class="search-frame">
                            <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                          <input class='search-input' type="text" (input)="applyUsersFilter($event.target)" placeholder="{{
                            'OTHER.SEARCH' | translate }}" id="user-search-input">
                            </div>
                            <div class='table-export-container' rtlDiv>
                              <div class='table-export-btns-wrapper' (click)="exportUsersToCsv()" rtlDiv>
                                <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                                <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                              </div>
                              @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                <div class="manage-bins-btn-container enableBtn" (click)="openAddUserModal()" [hidden]="spinnerUsersActive" rtlDiv>
                                  <a class="manage-bins-btn" rtlDiv>
                                    <span>{{ 'LIST_COLUMNS.ADD_USER' | translate }}</span>
                                    <span>
                                      <img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/>
                                    </span>
                                  </a>
                                </div>
                              }
                            </div>
                          </div>
                          <table class="table" mat-table [dataSource]="getUsersData()" matSort matSortStart="asc"
                            matSortActive="user_id" matSortDisableClear (matSortChange)="sortUsersData($event)" matSortDirection="desc">
                            <ng-container matColumnDef="select">
                              <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterUsersToggle() : null"
                                  [checked]="userSelection.hasValue() && isUsersAllSelected()"
                                  [indeterminate]="userSelection.hasValue() && !isUsersAllSelected()">
                                </mat-checkbox>
                              </th>
                              <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? userSelection.toggle(row) : null"
                                  [checked]="userSelection.isSelected(row)">
                                </mat-checkbox>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="first_name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="first_name" class="space th-title">
                              {{ 'LIST_COLUMNS.FIRST_NAME' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.first_name: ''}} </td>
                            </ng-container>
                            <ng-container matColumnDef="last_name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="last_name" class="space th-title">
                              {{ 'LIST_COLUMNS.LAST_NAME' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.last_name: ''}} </td>
                            </ng-container>
                            <ng-container matColumnDef="user_email">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="user_email" class="space th-title">
                              {{ 'LIST_COLUMNS.EMAIL' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.user_email_address: ''}} </td>
                            </ng-container>
                            <ng-container matColumnDef="user_phone">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="user_phone" class="space th-title">
                              {{ 'LIST_COLUMNS.PHONE' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.user_phone_number: ''}} </td>
                            </ng-container>
                            <ng-container matColumnDef="user_company">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="user_company" class="space th-title">
                              {{ 'LIST_COLUMNS.COMPANY' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.user_company: ''}} </td>
                            </ng-container>
                            <ng-container matColumnDef="user_permission">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="user_role_id" class="space th-title">
                              {{ 'LIST_COLUMNS.PERMISSION' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value">
                                @if (currentLang != 'iw') {
                                  <span>{{element ? element.user_role_name: ''}}</span>
                                }
                                @if (currentLang == 'iw') {
                                  <span>{{element ? element.user_role_name_hebrew: ''}}</span>
                                }
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="user_alert">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header="user_alert" class="space th-title">
                              {{ 'MANAGE_SITE.ALERTS' | translate }} </th>
                              <td mat-cell *matCellDef="let element" class="space td-value">
                                <img class='search-input-img' title="" src="{{element ? getUserAlertsImg(element.user_alert_info) : ''}}" alt="" />
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="edit">
                              <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="edit"> </th>
                              <td mat-cell *matCellDef="let element" class="manage-bins">
                                @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                  <div class="manage-site-button hide-manage-site" (click)="openManageUserModal(element)" rtlDiv>
                                  {{'TITLES.EDIT' | translate }}</div>
                                }
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="delete">
                              <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="delete"> </th>
                              <td mat-cell *matCellDef="let element" class="manage-bins">
                                @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                  <div class="manage-site-button hide-manage-site" (click)="openDeleteUsersModal(element)" rtlDiv>
                                  {{'TITLES.DELETE' | translate }}</div>
                                }
                              </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedUsersColumns"></tr>
                            <tr class="sites-row" mat-row *matRowDef="let row; columns: displayedUsersColumns;"></tr>
                          </table>
                          @if (usersTableLength > 0) {
                            <div>
                              <app-pagination [chosenBins]="filteredUsersData" (pageChange)='onUsersPageChange($event)' [filesPerPage]="10"></app-pagination>
                            </div>
                          }
                        </mat-card-content>
                      </mat-card>
                    </mat-tab>
                  }
                  @if ((user_role_id == 4 || user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && usageType == 1) {
                    <mat-tab label="{{'TITLES.CLUSTERS' | translate}}">
                      <mat-card>
                        <div class='spinner-container' [hidden]="!spinnerClustersActive">
                          <div class="spinner"></div>
                        </div>
                        <mat-card-content [hidden]="spinnerClustersActive">
                          <div class='search-input-container' rtlDiv>
                            <div class="search-frame">
                              <img class='search-input-img' src="assets/images/sidemenu/table_search_icon.png" alt="" />
                            <input class='search-input' type="text" (input)="applyClustersFilter($event.target)" placeholder="{{
                              'OTHER.SEARCH' | translate }}" id="cluster-search-input">
                              </div>
                              <div class='table-export-container' rtlDiv>
                                <div class='table-export-btns-wrapper' (click)="exportClustersToCsv()" rtlDiv>
                                  <img class='table-export-img excel' src="assets/images/dashboard/Excel.svg" alt="" />
                                  <div class='table-export-title' rtlDiv>{{ 'OTHER.EXCEL' | translate }}</div>
                                </div>
                                @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                  <div class="manage-bins-btn-container enableBtn" (click)="openAddClusterModal()" [hidden]="spinnerClustersActive" rtlDiv>
                                    <a class="manage-bins-btn" rtlDiv>
                                      <span>{{ 'LIST_COLUMNS.ADD_CLUSTER' | translate }}</span>
                                      <span>
                                        <img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/>
                                      </span>
                                    </a>
                                  </div>
                                }
                                @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && clusterSelection.selected.length > 1) {
                                  <div class="manage-bins-btn-container" (click)="openDeleteClustersModal(clusterSelection.selected)" [hidden]="spinnerClustersActive" rtlDiv>
                                    <a class="delete-clusters" rtlDiv>
                                      <img src="assets/images/bins/green_bin.svg" rtlDiv/>
                                      <span>{{'TITLES.DELETE' | translate }} ({{clusterSelection.selected.length}}) {{'TITLES.CLUSTERS' | translate}}</span>
                                    </a>
                                  </div>
                                }
                                @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                  <div class="manage-bins-btn-container" [ngClass]="{'disableBtn' : clusterSelection.selected.length < 2 , 'enableBtn' : clusterSelection.selected.length > 1}" (click)="openManageClustersModal()" [hidden]="spinnerClustersActive" rtlDiv>
                                    <a class="manage-bins-btn" rtlDiv>
                                      <span>{{ 'LIST_COLUMNS.MANAGE_CLUSTERS' | translate }}</span>
                                      <span>
                                        <img class="arow_manage" src="assets/images/dashboard/Arrow_right.svg" rtlDiv/>
                                      </span>
                                    </a>
                                  </div>
                                }
                              </div>
                            </div>
                            <table class="table" mat-table [dataSource]="getClustersData()" matSort matSortStart="asc"
                              matSortActive="cluster_name" matSortDisableClear (matSortChange)="sortClustersData($event)" matSortDirection="desc">
                              <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                  <mat-checkbox (change)="$event ? masterClustersToggle() : null"
                                    [checked]="clusterSelection.hasValue() && isClustersAllSelected()"
                                    [indeterminate]="clusterSelection.hasValue() && !isClustersAllSelected()">
                                  </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row" class="list-checkbox chb-space" rtlDiv>
                                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? clusterSelection.toggle(row) : null"
                                    [checked]="clusterSelection.isSelected(row)">
                                  </mat-checkbox>
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="cluster_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="cluster_id" class="space th-title">
                                {{ 'LIST_COLUMNS.CLUSTERID' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.cluster_id: ''}} </td>
                              </ng-container>
                              <ng-container matColumnDef="cluster_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="cluster_name" class="space th-title">
                                {{ 'LIST_COLUMNS.CLUSTER_NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.cluster_name: ''}} </td>
                              </ng-container>
                              <ng-container matColumnDef="site_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="space th-title">
                                {{ 'TITLES.SITE_NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value"> {{element ? element.site_name: ''}} </td>
                              </ng-container>
                              <ng-container matColumnDef="cluster_type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="cluster_type_id" class="space th-title">
                                {{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value">
                                  @if (currentLang != 'iw') {
                                    <span>{{element ? element.cluster_type_name: ''}}</span>
                                  }
                                  @if (currentLang == 'iw') {
                                    <span>{{element ? element.cluster_type_name_hebrew: ''}}</span>
                                  }
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="min_capacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="min_capacity" class="space th-title">
                                {{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value">
                                  {{element ? element.min_required_capacity_for_collection: ''}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="max_capacity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="max_capacity" class="space th-title">
                                {{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value">
                                  {{element ? element.cluster_max_allowed_capacity: ''}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="bins_number">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="number_of_bins_in_cluster" class="space th-title">
                                {{ 'LIST_COLUMNS.CLUSTER_SIZE' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="space td-value">
                                  {{element ? element.number_of_bins_in_cluster: ''}}
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="edit"> </th>
                                <td mat-cell *matCellDef="let element" class="manage-bins">
                                  @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                    <div class="manage-site-button hide-manage-site" (click)="openManageClusterModal(element)" rtlDiv>
                                    {{'TITLES.EDIT' | translate }}</div>
                                  }
                                </td>
                              </ng-container>
                              <ng-container matColumnDef="delete">
                                <th mat-header-cell *matHeaderCellDef disabled="true" mat-sort-header="delete"> </th>
                                <td mat-cell *matCellDef="let element" class="manage-bins">
                                  @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                    <div class="manage-site-button hide-manage-site" (click)="openDeleteClustersModal(element)" rtlDiv>
                                    {{'TITLES.DELETE' | translate }}</div>
                                  }
                                </td>
                              </ng-container>
                              <tr mat-header-row *matHeaderRowDef="displayedClustersColumns"></tr>
                              <tr class="sites-row" mat-row *matRowDef="let row; columns: displayedClustersColumns;"></tr>
                            </table>
                            @if (clustersTableLength > 0) {
                              <div>
                                <app-pagination [chosenBins]="filteredClustersData" (pageChange)='onClustersPageChange($event)' [filesPerPage]="10"></app-pagination>
                              </div>
                            }
                          </mat-card-content>
                        </mat-card>
                      </mat-tab>
                    }
                  </mat-tab-group>
                </div>
              </div>
            }

            @if (isMobile) {
              <div class="inventory-mobile-content">
                <div class="mobile-top-container">
                  <div class="mobile-inventory-header">
                    <div class="mobile-left-title">{{ 'SIDEBAR.MOBILE_INVENTORY' | translate }}</div>
                  </div>
                  <div class="mobile-separate">
                    <mat-divider class="mobile-line"></mat-divider>
                  </div>
                </div>
                <div class="mobile-group-tabs">
                  <mat-tab-group mat-stretch-tabs="false" animationDuration="0ms" [dir]="currentLang ? (currentLang == 'iw' ? 'rtl' : 'ltr') : 'auto'">
                    <mat-tab>
                      <ng-template mat-tab-label>
                        @if(usageType == 1){
                          <span>{{'SITE_MANAGMENT.BINS' | translate}}</span>
                        }
                        @else{
                          <span>{{'SITE_MANAGMENT.TANKS' | translate}}</span>
                        }
                      </ng-template>
                      <ng-template matTabContent>
                        <div class="card mobile-card">
                          <div class="card-body">
                            <div class="mobile-search-excel">
                              <div class='mobile-search-container'>
                                <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                                <input class='mobile-input' type="text" (keyup)="applyFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                                  id="mobile-bin-search-input" rtlDiv>
                                </div>
                                <div class='mobile-table-export-container' rtlDiv>
                                  <div class='mobile-table-export-btns-wrapper' (click)="exportToCsv()" rtlDiv>
                                    <img class='mobile-table-export-img excel' src="assets/images/sidemenu/mobile-excel.svg" alt="" />
                                  </div>
                                </div>
                              </div>
                              <div class="full-width" [hidden]="!spinnerActive">
                                <div class="spinner-border mobile-spinner">
                                  <div class="spinner"></div>
                                </div>
                              </div>
                              <div [hidden]="spinnerActive">
                                <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getTableData()" multiTemplateDataRows
                                  matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortData($event)" matSortDirection="desc"
                                  matSortActive="bin_name">

                                  <ng-container matColumnDef="name">
                                    <th [hidden]="!inventorySelectedValues.isBinNameChecked" class="mobile-th" mat-sort-header="bin_name" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.NAME' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinNameChecked" class="mobile-details-td" mat-cell *matCellDef="let element">
                                      <div class="flex-th-value mobile-tooltip">
                                        <span (click)="goToBinLivePage(element)" [ngClass]="{'blur-txt': element.collection_mode == 1}" title="{{element ? element.bin_name : ''}}" class="cut-txt mobile-id-details-value"> {{element ? element.bin_name : ''}}</span>
                                        @if (element.number_of_bins_in_cluster > 1 && element.chosenCluster) {
                                          <button (click)="changeSubmitClickedStatus(element)" class="tooltip-btn display-flex" type="button">
                                            <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" />
                                          </button>
                                        }
                                        @if (element.isOpenTicket) {
                                          <div class="tooltip-btn display-flex">
                                            <img src="assets/images/binInfoPage/open_ticket_indication.svg" alt="" />
                                          </div>
                                        }
                                      </div>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="cluster_id">
                                    <th [hidden]="!inventorySelectedValues.isClusterIdChecked" class="mobile-th" mat-sort-header="cluster_id" mat-header-cell *matHeaderCellDef>{{ 'LIST_COLUMNS.DESCRIPTION' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isClusterIdChecked" class="mobile-details-td" mat-cell *matCellDef="let element" (click)="goToBinLivePage(element)"> <span title="{{element ? element.description : ''}}" class="cut-txt"> {{element ? element.description : ''}}</span> </td>
                                  </ng-container>

                                  <ng-container matColumnDef="status">
                                    <th [hidden]="!inventorySelectedValues.isStatusChecked" mat-sort-header="status" class="mobile-th" rtlDiv  mat-header-cell *matHeaderCellDef>
                                    {{ 'LIST_COLUMNS.STATUS' | translate }}</th>
                                    <td [hidden]="!inventorySelectedValues.isStatusChecked" class="mobile-details-td" mat-cell *matCellDef="let element">
                                      @if (element.collection_mode == 1) {
                                        <hr class="mobile-title-hr">
                                      }
                                      @if (element.collection_mode != 1) {
                                        <div rtlDiv class="mobile-status-value" [ngStyle]="getProgressBarStyleInternal(element)">
                                          <span class="spanSize">{{element ? element.bin_capacity_to_display: ''}}</span>
                                        </div>
                                      }
                                    </td>
                                  </ng-container>
                                  
                                  <ng-container matColumnDef="address">
                                    <th [hidden]="!inventorySelectedValues.isAddressChecked" mat-header-cell *matHeaderCellDef mat-sort-header="address" class="mobile-th"> {{ 'LIST_COLUMNS.ADDRESS'| translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isAddressChecked" class="mobile-details-td" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> 
                                      <span class="mobile-address-details-value-waze">
                                        {{element ? element.address: ''}} 
                                        <a class="wazeFrame" href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'><img class="mobile-wazeIcon" src="assets/images/dashboard/mobile-waze.svg" alt=""></a>
                                      </span>                                      
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="description">
                                    <th [hidden]="!inventorySelectedValues.isBinDescChecked" mat-header-cell *matHeaderCellDef mat-sort-header="description" class="mobile-th"> {{'LIST_COLUMNS.DESCRIPTION' | translate}} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinDescChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.description: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="size">
                                    <th [hidden]="!inventorySelectedValues.isQuarterAcreChecked" mat-header-cell *matHeaderCellDef mat-sort-header="size" class="mobile-th">{{'LIST_COLUMNS.QUARTER_ACRE' | translate}}</th>
                                    <td [hidden]="!inventorySelectedValues.isQuarterAcreChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.size: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="last_collection">
                                    <th [hidden]="!inventorySelectedValues.isLastCollectionChecked" mat-header-cell *matHeaderCellDef mat-sort-header="last_collection" class="mobile-th"> {{'LIST_COLUMNS.LAST_COLLECTION' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isLastCollectionChecked" mat-cell *matCellDef="let element" class="mobile-details-td">
                                      @if (element.collection_mode != 1) {
                                        <span title="{{element.last_collection}}">{{element.calcDiff_lastCollection}}</span>
                                      }
                                      @if (element.collection_mode == 1) {
                                        <hr class="dashed-hr">
                                      }
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="last_refill">
                                    <th [hidden]="!inventorySelectedValues.isLastRefillChecked" mat-header-cell *matHeaderCellDef mat-sort-header="last_refill" class="mobile-th"> {{'LIST_COLUMNS.LAST_REFILL' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isLastRefillChecked" mat-cell *matCellDef="let element" class="mobile-details-td">
                                      @if (element.collection_mode != 1) {
                                        <span>{{element.calcDiff_lastRefill}}</span>
                                      }
                                      @if (element.collection_mode == 1) {
                                        <hr class="dashed-hr">
                                      }
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="last_update">
                                    <th [hidden]="!inventorySelectedValues.isLastUpdateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="last_update" class="mobile-th"> {{'LIST_COLUMNS.LAST_UPDATE' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isLastUpdateChecked" mat-cell *matCellDef="let element" class="mobile-details-td">
                                      @if (element.collection_mode != 1) {
                                        <span title="{{element.last_update}}">{{element.calcDiff_lastUpdate}}</span>
                                      }
                                      @if (element.collection_mode == 1) {
                                        <hr class="dashed-hr">
                                      }
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="neighborhood">
                                    <th [hidden]="!inventorySelectedValues.isNeighborhoodChecked" mat-header-cell *matHeaderCellDef mat-sort-header="neighborhood" class="mobile-th">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate }}</th>
                                    <td [hidden]="!inventorySelectedValues.isNeighborhoodChecked" class="mobile-details-td" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.neighborhood: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="site_name">
                                    <th [hidden]="!inventorySelectedValues.isBinSiteColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="site_name" class="mobile-th"> {{ 'TRUCK_MAP.SITE' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinSiteColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.site_name: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="id">
                                    <th [hidden]="!inventorySelectedValues.isBinIdColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_id" class="mobile-th"> {{ 'LIST_COLUMNS.ID' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinIdColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.bin_id: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="type">
                                    <th [hidden]="!inventorySelectedValues.isBinTypeColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="bin_type" class="mobile-th"> {{ usageType == 1 ? (translationsObj ? translationsObj.LIST_COLUMNS.BIN_TYPE : '') : (translationsObj ? translationsObj.LIST_COLUMNS.TANK_TYPE : '')}} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinTypeColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.bin_type: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="installation_Date">
                                    <th [hidden]="!inventorySelectedValues.isBinInstallDateColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="installation_DateTime_value" class="mobile-th"> {{'LIST_COLUMNS.INSTALLATION_DATE' | translate}} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinInstallDateColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                                      <span>{{element ? element.bin_installation_date : ''}}</span>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="avg_rate">
                                    <th [hidden]="!inventorySelectedValues.isBinAvgRateColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="avg_rate" class="mobile-th">
                                      @if (usageType == 1) {
                                        <span>{{'LIVE_INFO.EMPTY_RATE' | translate}}</span>
                                      }
                                      @if (usageType == 2) {
                                        <span>{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</span>
                                      }
                                    </th>
                                    <td [hidden]="!inventorySelectedValues.isBinAvgRateColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                                      @if (usageType == 1) {
                                        <span>{{element.avg_rate | number:'1.0-2'}}{{element.avg_rate != null ? '%' : ''}}</span>
                                      }
                                      @if (usageType == 2) {
                                        <span>{{element.avg_rate | number:'2.1-2'}}{{element.avg_rate != null ? 'L' : ''}} </span>
                                      }
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="avg_usage_rate">
                                    <th [hidden]="!inventorySelectedValues.isBinAvgUsageRateChecked" mat-header-cell *matHeaderCellDef mat-sort-header="avg_usage_rate" class="mobile-th">{{ 'LIVE_INFO.AVG_RATE' | translate }}</th>
                                    <td [hidden]="!inventorySelectedValues.isBinAvgUsageRateChecked" class="mobile-details-td" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element.avg_usage_rate != null ? element.avg_usage_rate + '%': ''}}</td>
                                  </ng-container>4

                                  <ng-container matColumnDef="collection_frequency">
                                    <th [hidden]="!inventorySelectedValues.isBinCollectionFrequencyChecked" mat-header-cell *matHeaderCellDef mat-sort-header="collection_frequency" class="mobile-th">{{'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate}}</th>
                                    <td [hidden]="!inventorySelectedValues.isBinCollectionFrequencyChecked" class="mobile-details-td" mat-cell *matCellDef="let element" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.collection_frequency: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="emptying_dunam">
                                    <th [hidden]="!inventorySelectedValues.isAvgDailyRateDunamColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="emptying_dunam" class="mobile-th">
                                    {{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isAvgDailyRateDunamColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                                      @if (currentLang == 'iw') {
                                        <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                          <span>ל/ד</span>
                                        } </span>
                                      }
                                      @if (currentLang != 'iw') {
                                        <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                          <span>L/D</span>
                                        }</span>
                                      }
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="predicted_time_for_max_allowed_capacity">
                                    <th [hidden]="!inventorySelectedValues.isBinPredictedTimeForMaxAllowedCapacityCol" mat-header-cell *matHeaderCellDef mat-sort-header="predicted_time_for_max_allowed_capacity_value" class="mobile-th"> {{'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate}} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinPredictedTimeForMaxAllowedCapacityCol" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}">
                                      <span>{{element ? element.predicted_time_for_max_allowed_capacity : ''}}</span>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="collection_mode">
                                    <th [hidden]="!inventorySelectedValues.isBinCollectionModeChecked" mat-header-cell *matHeaderCellDef mat-sort-header="collection_mode" class="mobile-th"> {{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinCollectionModeChecked" mat-cell *matCellDef="let element" class="mobile-details-td">
                                      <img class='search-input-img' title="{{element ? getTitle(element) : ''}}" src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="device_id">
                                    <th [hidden]="!inventorySelectedValues.isBinDeviceIdColChecked" mat-header-cell *matHeaderCellDef mat-sort-header="device_id" class="mobile-th"> {{ 'SITE_MANAGMENT.DEVICE_ID' | translate }} </th>
                                    <td [hidden]="!inventorySelectedValues.isBinDeviceIdColChecked" mat-cell *matCellDef="let element" class="mobile-details-td" [ngClass]="{'blur-txt': element.collection_mode == 1}"> {{element ? element.device_id: ''}} </td>
                                  </ng-container>

                                  <ng-container matColumnDef="arrowDown">
                                    <th class="mobile-th" rtlDiv mat-header-cell *matHeaderCellDef>
                                      <img (click)="openColumnManaging()" rtlDiv src="assets/images/dashboard/addCol.svg" alt=""/>       
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                      <div rtlDiv class="mobile-status-value">
                                        @if (expandedElement !== element) {
                                          <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                        }
                                        @if (expandedElement === element) {
                                          <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                        }
                                      </div>
                                    </td>
                                  </ng-container>
                                  <ng-container matColumnDef="expandedDetail">
                                    <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" rtlDiv>
                                      <div class="mobile-row-details mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="mobile-element-diagram" rtlDiv>
                                          @if(!inventorySelectedValues.isBinSiteColChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ 'TRUCK_MAP.SITE' | translate }}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element.site_name}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinIdColChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ 'LIST_COLUMNS.ID' | translate }}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element.bin_id}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinTypeColChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ usageType == 1 ? (translationsObj ? translationsObj.LIST_COLUMNS.BIN_TYPE : '') : (translationsObj ? translationsObj.LIST_COLUMNS.TANK_TYPE : '')}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element ? element.bin_type: ''}} </div>
                                            </div>
                                          }                         
                                          @if(!inventorySelectedValues.isAddressChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ 'LIST_COLUMNS.ADDRESS' | translate }}</div>
                                              <div class="mobile-address-details-value-waze">
                                                <span [ngClass]="{'blur-txt': element.collection_mode == 1}">{{element.address}}</span>
                                                <a class="wazeFrame" href='https://waze.com/ul?ll={{element.lat}},{{element.lon}}'><img class="mobile-wazeIcon" src="assets/images/dashboard/mobile-waze.svg" alt=""></a>
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isNeighborhoodChecked && usageType == 1){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.NEIGHBORHOOD' | translate}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element ? element.neighborhood: ''}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isClusterIdChecked && !inventorySelectedValues.isBinDescChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.DESCRIPTION' | translate}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element.description}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinNameChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.NAME' | translate}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element.bin_name}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isQuarterAcreChecked && usageType == 2){
                                            <div class="dFlex">
                                              <div class="mobile-id-details-title">{{'LIST_COLUMNS.QUARTER_ACRE' | translate}}</div>
                                              <div class="mobile-id-details-value"> {{element.size}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinInstallDateColChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.INSTALLATION_DATE' | translate}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value">{{element.bin_installation_date}}</div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isLastCollectionChecked && usageType == 1){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.LAST_COLLECTION' | translate }}</div>
                                              <div class="mobile-id-details-value">
                                                @if (element.collection_mode == 1) {
                                                  <hr class="mobile-dashed-hr">
                                                }
                                                @if (element.collection_mode != 1) {
                                                  <span>{{parseDateFormat(element.last_collection)}} </span>
                                                }
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isLastRefillChecked && usageType == 2){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.LAST_REFILL' | translate }}</div>
                                              <div class="mobile-id-details-value">
                                                @if (element.collection_mode == 1) {
                                                  <hr class="mobile-dashed-hr">
                                                }
                                                @if (element.collection_mode != 1) {
                                                  <span>{{parseDateFormat(element.last_refill)}} </span>
                                                }
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isLastUpdateChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.LAST_UPDATE' | translate }}</div>
                                              <div class="mobile-id-details-value">
                                                @if (element.collection_mode == 1) {
                                                  <hr class="mobile-dashed-hr">
                                                }
                                                @if (element.collection_mode != 1) {
                                                  <span>{{parseDateFormat(element.last_update)}}</span>
                                                }
                                              </div>
                                            </div>
                                          }    
                                          
                                          @if(!inventorySelectedValues.isStatusChecked){
                                            <div class="dFlex">
                                              <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.STATUS' | translate }}</div>
                                              <div class="mobile-id-details-value">
                                                @if (element.collection_mode == 1) {
                                                  <hr class="mobile-title-hr">
                                                }
                                                @if (element.collection_mode != 1) {
                                                  <div rtlDiv [ngStyle]="getProgressBarStyleInternal(element)">
                                                    <span class="spanSize">{{element ? element.bin_capacity_to_display: ''}}</span>
                                                  </div>
                                                }
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinAvgRateColChecked){
                                            <div class="dFlex">
                                              @if (usageType == 1) {
                                                <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIVE_INFO.EMPTY_RATE' | translate }}</div>
                                              }
                                              @if (usageType == 2) {
                                                <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ 'FILLINGS.AVG_DAILY_RATE' | translate }}</div>
                                              }
                                              @if (usageType == 1) {
                                                <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value">{{element.avg_rate | number:'1.0-2'}}{{element.avg_rate != null ? '%' : ''}}</div>
                                              }
                                              @if (usageType == 2) {
                                                <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value">{{element.avg_rate | number:'1.0-2'}}{{element.avg_rate != null ? 'L' : ''}}</div>
                                              }
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinAvgUsageRateChecked && usageType == 1){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{ 'LIVE_INFO.AVG_RATE' | translate }}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element.avg_usage_rate != null ? element.avg_usage_rate + '%': ''}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinCollectionFrequencyChecked && usageType == 1){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'MANAGE_BIN.MONTHLY_COLLECTION_FREQ' | translate }}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value"> {{element ? element.collection_frequency: ''}} </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isAvgDailyRateDunamColChecked){
                                            <div class="dFlex" [hidden]="usageType == 1">
                                              <div class="mobile-id-details-title">{{'LIVE_INFO.AVG_DAILY_EMPTYING_RATE_DUNAM' | translate }}</div>
                                              <div class="mobile-id-details-value">
                                                @if (currentLang == 'iw') {
                                                  <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                                    <span>ל/ד</span>
                                                  } </span>
                                                }
                                                @if (currentLang != 'iw') {
                                                  <span rtlDiv>{{element.emptying_dunam}} @if (element.emptying_dunam != null) {
                                                    <span>L/D</span>
                                                  }</span>
                                                }
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinPredictedTimeForMaxAllowedCapacityCol){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'LIST_COLUMNS.FULLNESS_FORECAST_DATE' | translate}}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value">{{element.predicted_time_for_max_allowed_capacity}}</div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinCollectionModeChecked){
                                            <div class="dFlex">
                                              <div class="mobile-id-details-title">{{ 'MANAGE_BIN.INCLUDE_IN_WORKPLAN' | translate }}</div>
                                              <div class="mobile-id-details-value"> 
                                                <img class='search-input-img' title="{{element ? getTitle(element) : ''}}" src="{{element ? getImg(element.collection_mode) : ''}}" alt="" />
                                              </div>
                                            </div>
                                          }
                                          @if(!inventorySelectedValues.isBinDeviceIdColChecked){
                                            <div class="dFlex">
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-title">{{'SITE_MANAGMENT.DEVICE_ID' | translate }}</div>
                                              <div [ngClass]="{'blur-txt': element.collection_mode == 1}" class="mobile-id-details-value">{{element.device_id}}</div>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </td>
                                  </ng-container>
                                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                                    (click)="expandedElement = expandedElement === element ? null : element">
                                  </tr>
                                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                                </table>
                                @if (dataTableLength > 0) {
                                  <div>
                                    <app-pagination [chosenBins]="filteredBinsData" (pageChange)='onPageChange($event)' [filesPerPage]="10"></app-pagination>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </mat-tab>
                      @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6 || user_role_id == 4) && usageType == 1) {
                        <mat-tab label="{{'NEW_WORKPLAN.TRUCKS' | translate}}">
                          <ng-template matTabContent>
                            <div class="card mobile-card">
                              <div class="card-body">
                                <div class="mobile-search-excel">
                                  <div class='mobile-search-container'>
                                    <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                                    <input class='mobile-input' type="text" (keyup)="applyTrucksFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                                      id="mobile-truck-search-input" rtlDiv>
                                    </div>
                                    <div class="padding-img dropdown">
                                      <div class="dropdown-toggle" data-bs-toggle="dropdown">
                                        <img src="assets/images/sidemenu/menu-dots.svg" alt="" rtlDiv />
                                      </div>
                                      <div class="dropdown-menu mobile-dropdown-list" rtlDiv>
                                        <div class="mobile-menuItem">
                                          <div class="menu-row" (click)="exportTrucksToCsv()" rtlDiv>
                                            <img src="assets/images/sidemenu/mobile-excel.svg" alt="" rtlDiv />
                                            <span class="mobile-item-txt">{{ 'OTHER.EXCEL' | translate }}</span>
                                          </div>
                                          @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5) {
                                            <div class="menu-row" (click)="openAddTruckModal()" rtlDiv>
                                              <img src="assets/images/sidemenu/mobile-add.svg" alt="" rtlDiv />
                                              <span class="mobile-item-txt">{{ 'TITLES.ADD_TRUCK' | translate }}</span>
                                            </div>
                                          }
                                          @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5) && truckSelection.selected.length > 1) {
                                            <div class="menu-row" (click)="openDeleteTrucksModal(truckSelection.selected)" rtlDiv>
                                              <img src="assets/images/sidemenu/red-bin.svg" alt="" rtlDiv />
                                              <span class="mobile-item-txt-red">{{'TITLES.DELETE' | translate }} ({{truckSelection.selected.length}}) {{'NEW_WORKPLAN.TRUCKS' | translate}}</span>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="full-width" [hidden]="!spinnerTrucksActive">
                                    <div class="spinner-border mobile-spinner">
                                      <div class="spinner"></div>
                                    </div>
                                  </div>
                                  <div [hidden]="spinnerTrucksActive">
                                    <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getTrucksData()" multiTemplateDataRows
                                      matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortTrucksData($event)" matSortDirection="desc"
                                      matSortActive="truck_id">
                                      <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                          <mat-checkbox (change)="$event ? masterTrucksToggle() : null"
                                            [checked]="truckSelection.hasValue() && isTrucksAllSelected()"
                                            [indeterminate]="truckSelection.hasValue() && !isTrucksAllSelected()">
                                          </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row" class="list-checkbox" rtlDiv>
                                          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? truckSelection.toggle(row) : null"
                                            [checked]="truckSelection.isSelected(row)">
                                          </mat-checkbox>
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="truck_id">
                                        <th class="mobile-th" mat-header-cell *matHeaderCellDef mat-sort-header="truck_id">
                                        {{ 'TITLES.TRUCK_ID' | translate }} </th>
                                        <td mat-cell *matCellDef="let element">
                                          <span title="{{ 'NEW_WORKPLAN.TRUCK' | translate }} {{element ? element.truck_id : ''}}" class="cut-txt mobile-id-details-value">{{ 'NEW_WORKPLAN.TRUCK' | translate }} {{element ? element.truck_id : ''}}</span>
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="max_capacity">
                                        <th mat-header-cell rtlDiv *matHeaderCellDef mat-sort-header="max_capacity" class="mobile-th">
                                        {{'TITLES.MAX_NUM_OF_BINS_TO_COLLECT' | translate }}</th>
                                        <td mat-cell *matCellDef="let element" class="mobile-details-td">
                                          <div rtlDiv class="mobile-id-details-value">
                                            <span>{{element ? element.max_capacity: ''}}</span>
                                          </div>
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="arrowDown">
                                        <th class="mobile-th" mat-sort-header="arrowDown" rtlDiv mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                          <div rtlDiv class="mobile-status-value">
                                            @if (expandedElement !== element) {
                                              <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                            }
                                            @if (expandedElement === element) {
                                              <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                            }
                                          </div>
                                        </td>
                                      </ng-container>
                                      <ng-container matColumnDef="expandedDetail">
                                        <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="truckscolumnsToDisplay.length" rtlDiv>
                                          <div class="mobile-row-Truckdetails mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                            <div class="mobile-element-diagram" rtlDiv>
                                              <div class="dFlex">
                                                <div class="mobile-id-details-title">{{ 'TITLES.SITE_NAME' | translate }}</div>
                                                <div class="mobile-id-details-value"> {{element.site_name}} </div>
                                              </div>
                                              <div class="dFlex">
                                                <div class="mobile-id-details-title">{{ 'TITLES.DRIVER_NAME' | translate }}</div>
                                                <div class="mobile-id-details-value"> {{element.driver_name}} </div>
                                              </div>
                                              <div class="dFlex">
                                                <div class="mobile-id-details-title">{{ 'TITLES.TRUCK_CAPACITY' | translate }}</div>
                                                <div class="mobile-id-details-value"> {{element.truck_volume}} </div>
                                              </div>
                                              <div class="dFlex">
                                                <div class="mobile-id-details-title">{{ 'TITLES.LICENSE_PLATE' | translate }}</div>
                                                <div class="mobile-id-details-value"> {{element.license_plate}} </div>
                                              </div>
                                              @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 4) {
                                                <div class="actions-bar">
                                                  <div class="mobile-id-details-value">{{ 'SITE_MANAGMENT.ACTIONS' | translate }}:</div>
                                                  <div class="mobile-manage-truck-button" (click)="openManageTruckModal(element)" rtlDiv>
                                                    <div>
                                                      {{'TITLES.EDIT' | translate }}
                                                    </div>
                                                    <img src="assets/images/sidemenu/edit-pen.svg" alt=""/>
                                                  </div>                                                                        
                                                  @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5) {
                                                    <div class="mobile-red-action-button" (click)="openDeleteTrucksModal(element)" rtlDiv>
                                                      <div>{{'TITLES.DELETE' | translate }}</div>
                                                      <img src="assets/images/sidemenu/red-bin.svg" alt=""/>
                                                    </div>
                                                  }
                                                </div>
                                              }
                                            </div>
                                          </div>
                                        </td>
                                      </ng-container>
                                      <tr mat-header-row *matHeaderRowDef="truckscolumnsToDisplay"></tr>
                                      <tr mat-row *matRowDef="let element; columns: truckscolumnsToDisplay;"
                                        (click)="expandedElement = expandedElement === element ? null : element">
                                      </tr>
                                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                                    </table>
                                    @if (trucksTableLength > 0) {
                                      <div>
                                        <app-pagination [chosenBins]="filteredTrucksData" (pageChange)='onTrucksPageChange($event)' [filesPerPage]="10"></app-pagination>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </mat-tab>
                        }
                        @if ((user_role_id == 4 || user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && usageType == 1) {
                          <mat-tab label="{{'TITLES.CLUSTERS' | translate}}">
                            <ng-template matTabContent>
                              <div class="card mobile-card">
                                <div class="card-body">
                                  <div class="mobile-search-excel">
                                    <div class='mobile-search-container'>
                                      <img class='mobile-search-img' src="assets/images/sidemenu/table_search_icon.png" alt="" rtlDiv />
                                      <input class='mobile-input' type="text" (keyup)="applyClustersFilter($event.target)" placeholder="{{'OTHER.SEARCH' | translate}}"
                                        id="mobile-clusters-search-input" rtlDiv>
                                      </div>
                                      <div class="padding-img dropdown">
                                        <div class="dropdown-toggle" data-bs-toggle="dropdown">
                                          <img src="assets/images/sidemenu/menu-dots.svg" alt="" rtlDiv />
                                        </div>
                                        <div class="dropdown-menu mobile-dropdown-list" rtlDiv>
                                          <div class="mobile-menuItem">
                                            <div class="menu-row" (click)="exportClustersToCsv()" rtlDiv>
                                              <img src="assets/images/sidemenu/mobile-excel.svg" alt="" rtlDiv />
                                              <span class="mobile-item-txt">{{ 'OTHER.EXCEL' | translate }}</span>
                                            </div>
                                            @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                              <div class="menu-row" (click)="openAddClusterModal()" rtlDiv>
                                                <img src="assets/images/sidemenu/mobile-add.svg" alt="" rtlDiv />
                                                <span class="mobile-item-txt">{{ 'LIST_COLUMNS.ADD_CLUSTER' | translate }}</span>
                                              </div>
                                            }
                                            @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && clusterSelection.selected.length > 1) {
                                              <div class="menu-row" (click)="openManageClustersModal()" rtlDiv>
                                                <img src="assets/images/binInfoPage/clusterIndication.svg" alt="" rtlDiv />
                                                <span class="mobile-item-txt">{{ 'LIST_COLUMNS.MANAGE_CLUSTERS' | translate }}</span>
                                              </div>
                                            }
                                            @if ((user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) && clusterSelection.selected.length > 1) {
                                              <div class="menu-row" (click)="openDeleteClustersModal(clusterSelection.selected)" rtlDiv>
                                                <img src="assets/images/sidemenu/red-bin.svg" alt="" rtlDiv />
                                                <span class="mobile-item-txt-red">{{'TITLES.DELETE' | translate }} ({{clusterSelection.selected.length}}) {{'TITLES.CLUSTERS' | translate}}</span>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="full-width" [hidden]="!spinnerClustersActive">
                                      <div class="spinner-border mobile-spinner">
                                        <div class="spinner"></div>
                                      </div>
                                    </div>
                                    <div [hidden]="spinnerClustersActive">
                                      <table rtlDiv class="mobile-table" #table mat-table [dataSource]="getClustersData()" multiTemplateDataRows
                                        matSort matSortStart="asc" matSortDisableClear (matSortChange)="sortClustersData($event)" matSortDirection="desc"
                                        matSortActive="cluster_name">
                                        <ng-container matColumnDef="select">
                                          <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterClustersToggle() : null"
                                              [checked]="clusterSelection.hasValue() && isClustersAllSelected()"
                                              [indeterminate]="clusterSelection.hasValue() && !isClustersAllSelected()">
                                            </mat-checkbox>
                                          </th>
                                          <td mat-cell *matCellDef="let row" class="list-checkbox" rtlDiv>
                                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? clusterSelection.toggle(row) : null"
                                              [checked]="clusterSelection.isSelected(row)">
                                            </mat-checkbox>
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="cluster_id">
                                          <th class="mobile-th" mat-header-cell *matHeaderCellDef mat-sort-header="cluster_id">
                                          {{ 'LIST_COLUMNS.CLUSTERID' | translate }} </th>
                                          <td mat-cell *matCellDef="let element">
                                            <span class="mobile-id-details-value">{{element ? element.cluster_id: ''}}</span>
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="cluster_name">
                                          <th class="mobile-th" rtlDiv mat-header-cell *matHeaderCellDef mat-sort-header="cluster_name">
                                          {{ 'LIST_COLUMNS.CLUSTER_NAME' | translate }} </th>
                                          <td mat-cell *matCellDef="let element" class="mobile-details-td">
                                            <div rtlDiv class="mobile-status-value">
                                              <span class="mobile-id-details-value">{{element ? element.cluster_name: ''}}</span>
                                            </div>
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="arrowDown">
                                          <th class="mobile-th" mat-sort-header="arrowDown" rtlDiv mat-header-cell *matHeaderCellDef></th>
                                          <td mat-cell *matCellDef="let element">
                                            <div rtlDiv class="mobile-status-value">
                                              @if (expandedElement !== element) {
                                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_closed.svg" alt=""/>
                                              }
                                              @if (expandedElement === element) {
                                                <img rtlDiv class="mobile-status-img" src="assets/images/dashboard/arrow_opened.svg" alt=""/>
                                              }
                                            </div>
                                          </td>
                                        </ng-container>
                                        <ng-container matColumnDef="expandedDetail">
                                          <td class="mobile-details-td" mat-cell *matCellDef="let element" [attr.colspan]="truckscolumnsToDisplay.length" rtlDiv>
                                            <div class="mobile-row-Truckdetails mobile-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                              <div class="mobile-element-diagram" rtlDiv>
                                                <div class="dFlex">
                                                  <div class="mobile-id-details-title">{{ 'TITLES.SITE_NAME' | translate }}</div>
                                                  <div class="mobile-id-details-value"> {{element ? element.site_name: ''}} </div>
                                                </div>
                                                <div class="dFlex">
                                                  <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.CLUSTER_TYPE' | translate }}</div>
                                                  <div class="mobile-id-details-value">
                                                    @if (currentLang != 'iw') {
                                                      <span>{{element ? element.cluster_type_name: ''}}</span>
                                                    }
                                                    @if (currentLang == 'iw') {
                                                      <span>{{element ? element.cluster_type_name_hebrew: ''}}</span>
                                                    }
                                                  </div>
                                                </div>
                                                <div class="dFlex">
                                                  <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.MIN_ALLOWED_CAPACITY' | translate }}</div>
                                                  <div class="mobile-id-details-value"> {{element ? element.min_required_capacity_for_collection: ''}} </div>
                                                </div>
                                                <div class="dFlex">
                                                  <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.MAX_ALLOWED_CAPACITY' | translate }}</div>
                                                  <div class="mobile-id-details-value"> {{element ? element.cluster_max_allowed_capacity: ''}} </div>
                                                </div>
                                                <div class="dFlex">
                                                  <div class="mobile-id-details-title">{{ 'LIST_COLUMNS.CLUSTER_SIZE' | translate }}</div>
                                                  <div class="mobile-id-details-value"> {{element ? element.number_of_bins_in_cluster: ''}} </div>
                                                </div>
                                                @if (user_role_id == 1 || user_role_id == 2 || user_role_id == 5 || user_role_id == 6) {
                                                  <div class="actions-bar">
                                                    <div class="mobile-id-details-value">{{ 'SITE_MANAGMENT.ACTIONS' | translate }}:</div>
                                                    <div class="mobile-manage-truck-button" (click)="openManageClusterModal(element)" rtlDiv>
                                                      <div>{{'TITLES.EDIT' | translate }}</div>
                                                      <img src="assets/images/sidemenu/edit-pen.svg" alt=""/>
                                                    </div>
                                                    <div class="mobile-red-action-button" (click)="openDeleteClustersModal(element)" rtlDiv>
                                                      <div>{{'TITLES.DELETE' | translate }}</div>
                                                      <img src="assets/images/sidemenu/red-bin.svg" alt=""/>
                                                    </div>
                                                  </div>
                                                }
                                              </div>
                                            </div>
                                          </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="clusterscolumnsToDisplay"></tr>
                                        <tr mat-row *matRowDef="let element; columns: clusterscolumnsToDisplay;"
                                          (click)="expandedElement = expandedElement === element ? null : element">
                                        </tr>
                                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="mobile-detail-row"></tr>
                                      </table>
                                      @if (clustersTableLength > 0) {
                                        <div>
                                          <app-pagination [chosenBins]="filteredClustersData" (pageChange)='onClustersPageChange($event)' [filesPerPage]="10"></app-pagination>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </mat-tab>
                          }
                        </mat-tab-group>
                      </div>
                    </div>
                  }