import { Component, Inject, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { TranslatorService } from '../../../services/translator_service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../../directives/rtldiv-directive.directive';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ApiStore } from '../../../web-services/api/api.store';
import moment from 'moment';
import { ApiService } from '../../../web-services/api/api.service';
import _ from 'lodash';
import { ApiQuery } from '../../../web-services/api/api.query';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTabsModule } from '@angular/material/tabs';
import { PaginationComponent } from '../../pagination/pagination.component';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-change-bin-to-collect-modal',
  standalone: true,
  imports: [TranslateModule, RTLDivDirectiveDirective,CommonModule,NgbModule,MatInputModule,FormsModule,MatTableModule,
    MatSortModule,MatCheckboxModule,MatTabsModule,PaginationComponent
  ],
  templateUrl: './change-bin-to-collect-modal.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
      ])
    ],
  styleUrl: './change-bin-to-collect-modal.component.scss'
})
export class ChangeBinToCollectModalComponent {
	displayedColumns = ['select', 'name', 'address','neighborhood','status','collectionMode'];
	displayedColumnsMobile = ['select', 'name', 'status', 'arrowDown'];
	currentLang;
	translationsObj;
	isMobile: Boolean;
	is_view_unexecuted_work_plans:any = 0;
	usageType = 1;
  	tempFilter = '';
	filterTables ='';
	filteredData:Array<object> = [];
	filteredAddBinsData:Array<object> = [];
  	spinnerUnassignedBins : boolean = true;
	dataSource;
	dataSourceAddBins;
	selectionAddBins= new SelectionModel<Element>(true, []);
	selection = new SelectionModel<Element>(true, []);
  	addBinsTable : Array<object> = [];
	binsDataTable : Array<object> = [];
	binsData;
	binsDataAddBins;
 	unAssignBinsBackUp : Array<object> = [];
	unAssignBinsList: any[];
	@ViewChild(MatSort,{static: false}) sort: MatSort;
	selectedTab:number = 1;
	startIndexTableDataAllBins = 0;
	endIndexTableDataAllBins = 7;
	startIndexTableData = 0;
	endIndexTableData = 7;
	expandedElement: any;

  constructor(private responsiveService: ResponsiveService,private translator: TranslatorService,
	private dialogRef: MatDialogRef<ChangeBinToCollectModalComponent>,private apiStore:ApiStore,private apiService:ApiService,
  	private apiQuery:ApiQuery,@Inject(MAT_DIALOG_DATA) public data: any) {
		this.dialogRef.disableClose = true;
		this.translator.currentLangEmitter$
		.subscribe(async value=>{						
			this.translationsObj = await this.translator.getTranslation(value).toPromise();							 
			this.currentLang = value;		   
		})
	}

  ngOnInit() {
    this.onResize();
	this.responsiveService.checkWidth();
    this.selectionAddBins.clear();
    this.selection.clear();

    this.apiQuery.user$.subscribe(user => {
      if (!user) return;
  
      if(user["usage_type_id"] != 1){
        this.usageType = user["usage_type_id"];
      }
      this.is_view_unexecuted_work_plans = user["is_view_unexecuted_work_plans"];			
    });

    this.apiQuery.unAssignBins$.subscribe((unAssignBins:any) => {
      this.spinnerUnassignedBins = true;
      if(unAssignBins.length == 0){return;}
      this.spinnerUnassignedBins = false;
      const binData = _.filter(unAssignBins, (bin) => {
        return bin["bin_latitude"] != null && bin["bin_longitude"] != null && 
        bin["bin_latitude"] != 0 && bin["bin_longitude"] != 0;
      });
      this.subscribeUnAssignBins(binData);
    });
    this.initializeBinsTable();
  }

  onResize() {
		this.responsiveService.getMobileStatus().subscribe(isMobile => {
		  this.isMobile = isMobile;
		});
	}
  
  applyWorkplanChanges(){
		this.apiStore.update({ selectedWorkplan: this.data.currentSite });
		this.tempFilter = '';
		this.dataSource.filter = '';
		this.filterTables = '';
		this.removeBinFromWorkplan();
		this.addBinsToWorkplan();
		this.dialogRef.close();
	}

  removeBinFromWorkplan = () => {
		let removedBins = this.data.backUptruckBins.filter(item1 => 
			!this.data.truckBins.some(item2 => (item2["bin_id"] == item1["bin_id"])));			

		const binsToAdd: any = removedBins;
		if (_.isEmpty(binsToAdd)) return;
		const binListId = _.map(binsToAdd, 'bin_id');
		const dataToSend = {
			general_plan_id: this.data.dailyWorkplanDetails.plan_id,
			bin_id_list: binListId,
			remove_reason_id : 6
		};

		this.apiService.removeBinFromExistingWorkPlan(dataToSend).subscribe((v:any) => { 
			let userGeneratedWorkplans = {
				site_id:0
			}	
			let start_date = moment().subtract(2, 'months').format('YYYY-MM-DD HH:MM:59');
			let end_date = moment().format('YYYY-MM-DD HH:MM:59');
			this.apiStore.update({ allFilteredDailyWorkPlan: [] });
			this.apiStore.update({ filteredDailyWorkPlan: [] });
			this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites"))); 
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
			this.apiStore.update({ dailyWorkPlanCalanderData: [] });
			this.apiService.getDailyWorkPlanCalanderData(start_date,end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));   				
		});   
	}

  addBinsToWorkplan = () => {
		let includedBins = this.unAssignBinsBackUp.filter(item1 => 
			!this.unAssignBinsList.some(item2 => (item2["bin_id"] == item1["bin_id"])))			

		const binsToAdd: any = includedBins;
		if (_.isEmpty(binsToAdd)) return;
		const binListId = _.map(binsToAdd, 'bin_id');
		const dataToSend = {
			general_plan_id: this.data.dailyWorkplanDetails.plan_id,
			bin_id_list: binListId,
			tablet_id: this.data.currentTruck.tablet_id
		};
		this.apiService.addBinToExistingWorkPlan(dataToSend).subscribe((v:any) => {     			
			let userGeneratedWorkplans = {
				site_id:0
			}	
			let start_date = moment().subtract(2, 'months').format('YYYY-MM-DD HH:MM:59');
			let end_date = moment().format('YYYY-MM-DD HH:MM:59');
			this.apiStore.update({ allFilteredDailyWorkPlan: [] });
			this.apiStore.update({ filteredDailyWorkPlan: [] });
			this.apiService.getfilteredActivePlans(JSON.parse(sessionStorage.getItem("chosenSites"))); 
			this.apiStore.update({ allWorkPlanScheduler: [] });
			this.apiStore.update({ workPlanScheduler: [] });
			this.apiService.getfilteredWorkPlanScheduler(userGeneratedWorkplans,JSON.parse(sessionStorage.getItem("chosenSites")));
			this.apiStore.update({ allDailyWorkPlanCalanderData: [] });
			this.apiStore.update({ dailyWorkPlanCalanderData: [] });
			this.apiService.getDailyWorkPlanCalanderData(start_date,end_date,this.is_view_unexecuted_work_plans,JSON.parse(sessionStorage.getItem("chosenSites")));  			 		
		});   
	}

  cancelModal(){
		this.dataSource.filter = '';
    	this.dialogRef.close();
	}

  getAddBinsTableData = () => {
		const tempSearch = new MatTableDataSource<any>(this.addBinsTable);
		tempSearch.filter = this.tempFilter;
		this.filteredAddBinsData = tempSearch.filteredData;
		this.dataSourceAddBins = tempSearch.filteredData.length > 0 ?
			new MatTableDataSource<any>(tempSearch.filteredData) : new MatTableDataSource<any>([]);
		return this.dataSourceAddBins;
	}

	getAddBinsTableDataMobile = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.addBinsTable);
		tempSearch.filter = this.tempFilter;
		this.filteredAddBinsData = tempSearch.filteredData;
		for (let index = this.startIndexTableDataAllBins; index <= this.endIndexTableDataAllBins; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSourceAddBins = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		return this.dataSourceAddBins;
	}

  getTableData = () => {	 
		const tempSearch = new MatTableDataSource<any>(this.binsDataTable);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		this.dataSource = tempSearch.filteredData.length > 0 ? new MatTableDataSource<any>(tempSearch.filteredData) : new MatTableDataSource<any>([]);
		this.dataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.dataSource;
	}

	getTableDataMobile = () => {
		const tableData = [];
		const tempSearch = new MatTableDataSource<any>(this.binsDataTable);
		tempSearch.filter = this.tempFilter;
		this.filteredData = tempSearch.filteredData;
		for (let index = this.startIndexTableData; index <= this.endIndexTableData; index++) {
			if (tempSearch.filteredData[index]) {
				tableData.push(tempSearch.filteredData[index]);
			}
		}
		this.dataSource = tableData.length > 0 ? new MatTableDataSource<any>(tableData) : new MatTableDataSource<any>([]);		
		this.dataSource["AllData"] = new MatTableDataSource<any>(tempSearch.filteredData);
		return this.dataSource;
	}

  applyFilter = (filterValue) => {
    filterValue = filterValue.value;
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.tempFilter = filterValue;
		this.dataSource.filter = filterValue;
	}

  moveBinToOutside(){
		if(this.selection.selected.length == 0){
			return;
		}else{		
			_.each(this.selection.selected, (b) => {
				this.unAssignBinsList = [...this.unAssignBinsList,b];
			});

			let res = this.data.truckBins.filter(item1 => 
				!this.selection.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
			this.data.truckBins = res;

			this.initializeAddBinsTable();
			this.initializeBinsTable();	
			this.selection.clear();
			this.selectionAddBins.clear();
		}
	}

	moveBinToInside(){
		if(this.selectionAddBins.selected.length == 0){
			return;
		}else{
			_.each(this.selectionAddBins.selected, (b) => {
				this.data.truckBins = [...this.data.truckBins,b];	
			});

			let res = this.unAssignBinsList.filter(item1 => 
				!this.selectionAddBins.selected.some(item2 => (item2["bin_id"] == item1["bin_id"])))			
				this.unAssignBinsList = res;

			this.initializeBinsTable();	
			this.initializeAddBinsTable();
			this.selection.clear();
			this.selectionAddBins.clear();
		}
	}

  masterToggle = () => {
		this.isAllSelected() ?
			this.selection.clear() :
			this.dataSource["AllData"].filteredData.forEach(row => this.selection.select(row));
	}

  masterAddBinsToggle = () => {
		this.isAllAddBinsSelected() ?
			this.selectionAddBins.clear() :
			this.dataSourceAddBins.filteredData.forEach(row => this.selectionAddBins.select(row));
	}

  isAllAddBinsSelected = (): boolean => {
		const numSelected = this.selectionAddBins.selected.length;
		const numRows = this.dataSourceAddBins.data.length;
		return numSelected === numRows;
	}

  getImg(value){			
		if(value == 2){
			return "assets/images/dashboard/selected.svg";
		}else{
			return "assets/images/dashboard/notSelected.svg";
		}
	}

	getTitle(elm){
		if(elm.collection_mode == 1){
			return this.currentLang == 'iw' ? elm.removed_bin_reason_type_name_hebrew : elm.removed_bin_reason_type_name;
		}
	}

  isAllSelected = (): boolean => {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource["AllData"].data.length;
		return numSelected === numRows;
	}

  initializeBinsTable = () => {
		this.binsDataTable = [];		
		const binsFiltered = _.map(this.data.truckBins, b => {
			return {
				bin_name: b.bin_name,
				bin_id: b.bin_id,
				bin_collected : b.is_collected ? b.is_collected : 0,
				bin_address : b.Bin_location ? b.Bin_location.bin_address : b.bin_address,
				bin_neighborhood : b.Bin_location ? (b.Bin_location.bin_neighbourhood ? b.Bin_location.bin_neighbourhood : '') : b.bin_neighborhood,
				bin_status : b.bin_status ? b.bin_status : (b.Last_updated_fill_level_sample ? b.Last_updated_fill_level_sample.fill_level_in_precent : 0),
				collection_mode : b.collection_mode,
				removed_bin_reason_type_name : b.removed_bin_reason_type_name,
				removed_bin_reason_type_name_hebrew : b.removed_bin_reason_type_name_hebrew
			};
		});
		this.binsData = binsFiltered;		
		_.each(this.binsData, (item) => {
			if (item.bin_collected != 1 && item.bin_collected != 2) {
				this.binsDataTable.push(item);
			}
		});		
		this.dataSource = new MatTableDataSource<any>(binsFiltered);
		this.dataSource.sort = this.sort;
		this.sortData({ direction: 'desc', active: 'bin_name' });
	}

  initializeAddBinsTable = () => {	
		this.addBinsTable = [];						
		const binsFiltered = _.map(this.unAssignBinsList, b => {
			return {
				bin_name: b.bin_name,
				bin_id: b.bin_id,
				bin_status: b.bin_status ? b.bin_status : (b.current_fill_level_in_percent ? b.current_fill_level_in_percent : 0),
				site_id:b.site_id ? b.site_id : this.data.currentSite.plan.site_id,
				bin_address: b.bin_address,
				bin_neighborhood : (b.bin_neighbourhood ? b.bin_neighbourhood : (b.bin_neighborhood ? b.bin_neighborhood : '')),
				collection_mode : b.collection_mode,
				removed_bin_reason_type_name : b.removed_bin_reason_type_name,
				removed_bin_reason_type_name_hebrew : b.removed_bin_reason_type_name_hebrew
			};
		});
		this.binsDataAddBins = binsFiltered;		
		_.each(this.binsDataAddBins, (item) => {			
			if(item && this.data.currentSite && this.data.currentSite.plan){
				if (item.site_id == this.data.currentSite.plan.site_id) {				
					this.addBinsTable.push(item);
				}
			}
		});
		this.dataSourceAddBins = new MatTableDataSource<any>(binsFiltered);
		this.dataSourceAddBins.sort = this.sort;
		this.sortAddBinsData({ direction: 'desc', active: 'bin_name' });
	}

  subscribeUnAssignBins = (unAssignBins) => {
		this.unAssignBinsBackUp = _.isEmpty(unAssignBins[0]) ? [] : unAssignBins;
		this.unAssignBinsList = _.isEmpty(unAssignBins[0]) ? [] : unAssignBins;
		this.initializeAddBinsTable();
	}

  sortAddBinsData(sort: Sort) {
		const data = this.addBinsTable.slice();
		if (!sort.active || sort.direction === '') {
			this.addBinsTable = data;
			return;
		}
		this.addBinsTable = data.sort((a, b) => {
			const isAsc = sort.direction === 'desc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

  sortData(sort: Sort) {
		const data = this.binsDataTable.slice();
		if (!sort.active || sort.direction === '') {
			this.binsDataTable = data;
			return;
		}
		this.binsDataTable = data.sort((a, b) => {
			const isAsc = sort.direction === 'desc';
			return sort.active ? this.compare(a[sort.active], b[sort.active], isAsc) : 0;
		});
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
	
	myTabSelectedIndexChange(event){
		this.selectedTab = event;
	}

	onAllBinsTablePageChange = (dataTableIndexes: any) => {
		this.startIndexTableDataAllBins = dataTableIndexes.startIndex;
		this.endIndexTableDataAllBins = dataTableIndexes.endIndex;
		this.getAddBinsTableDataMobile();
	}

	onTablePageChange = (dataTableIndexes: any) => {
		this.startIndexTableData = dataTableIndexes.startIndex;
		this.endIndexTableData = dataTableIndexes.endIndex;
		this.getTableDataMobile();
	}
}
