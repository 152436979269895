import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild, ViewRef } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatorService } from '../../services/translator_service';
import { ResponsiveService } from '../../services/responsive.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RTLDivDirectiveDirective } from '../../directives/rtldiv-directive.directive';
import { ApiQuery } from '../../web-services/api/api.query';
import { MatCheckboxModule } from '@angular/material/checkbox';
import _ from 'lodash';
import { MatListModule, MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-generic-columns-managing-modal',
  standalone: true,
  imports: [CommonModule,TranslateModule,RTLDivDirectiveDirective,FormsModule,ReactiveFormsModule,MatCheckboxModule,
    MatListModule
  ],
  templateUrl: './generic-columns-managing-modal.component.html',
  styleUrl: './generic-columns-managing-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericColumnsManagingModalComponent {
  isMobile: boolean;
	translationsObj;
	currentLang;
	usageType = 1;
	user_role_id = 0;
	@ViewChild(MatSelectionList,{static: false}) inventoryGroup: MatSelectionList;
  @ViewChild(MatSelectionList,{static: false}) collectionsGroup: MatSelectionList;
  @ViewChild(MatSelectionList,{static: false}) ticketManagementCheckOrReplacementGroup: MatSelectionList;
  @ViewChild(MatSelectionList,{static: false}) ticketManagementInstallationGroup: MatSelectionList;
  @ViewChild(MatSelectionList,{static: false}) ticketManagementCancellationGroup: MatSelectionList;
  @ViewChild(MatSelectionList,{static: false}) workplanListGroup: MatSelectionList;
  inventorySelectedOptionsAndNotDisabled: boolean = true;
  collectionsSelectedOptionsAndNotDisabled: boolean = true;
  ticketManagementCheckOrReplacementSelectedOptionsAndNotDisabled: boolean = true;
  ticketManagementInstallationSelectedOptionsAndNotDisabled: boolean = true;
  ticketManagementCancellationSelectedOptionsAndNotDisabled: boolean = true;
  workplanListSelectedOptionsAndNotDisabled: boolean = true;

  inventorySelectedValues;
  collectionsSelectedValues;
  ticketManagementCheckOrReplacementSelectedValues; 
  ticketManagementInstallationSelectedValues;
  ticketManagementCancellationSelectedValues;
  workplanListSelectedValues;

  constructor(private responsiveService: ResponsiveService,private dialogRef: MatDialogRef<GenericColumnsManagingModalComponent>,
    private translator: TranslatorService,private fb: FormBuilder,private apiQuery:ApiQuery,
    @Inject(MAT_DIALOG_DATA) public data: any,private cd: ChangeDetectorRef) {
    this.dialogRef.disableClose = true;
    this.translator.currentLangEmitter$
    .subscribe(async value=>{						
      this.translationsObj = await this.translator.getTranslation(value).toPromise()			 
      this.currentLang = value;	
    });     
   }

  ngOnInit() {
    this.onResize();
    this.responsiveService.checkWidth();

    this.apiQuery.user$.subscribe((user) => {
			if (!user) return;

			if(user["usage_type_id"] != 1){
				this.usageType = user["usage_type_id"];
			}
      this.user_role_id = user["user_role_id"];
    });

    if(this.data.page == 'inventory'){
      this.inventorySelectedValues = this.data.displayedColumns;
    }else if(this.data.page == 'collections'){
      this.collectionsSelectedValues = this.data.displayedColumns;
    }else if(this.data.page == 'ticketManagementCheckOrReplacement'){
      this.ticketManagementCheckOrReplacementSelectedValues = this.data.displayedColumns;
    }else if(this.data.page == 'ticketManagementInstallation'){
      this.ticketManagementInstallationSelectedValues = this.data.displayedColumns;
    }else if(this.data.page == 'ticketManagementCancellation'){
      this.ticketManagementCancellationSelectedValues = this.data.displayedColumns;
    }else if(this.data.page == 'workplanList'){
      this.workplanListSelectedValues = this.data.displayedColumns;
    }
  }

  onResize() {
    this.responsiveService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

  ngOnDestroy(){
		if (!(this.cd as ViewRef).destroyed) {			
			this.cd.detectChanges();
		}
	}

  closeModal(){
		this.dialogRef.close();
    let data = {
      values: this.data.displayedColumns
    }
    this.dialogRef.close(data);
	}

  onInventorySelectionChange(event?: any){
    this.inventorySelectedOptionsAndNotDisabled = this.inventoryGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }

  onCollectionsSelectionChange(event?: any){
    this.collectionsSelectedOptionsAndNotDisabled = this.collectionsGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }  

  onTicketManagementCheckOrReplacementSelectionChange(event?: any){
    this.ticketManagementCheckOrReplacementSelectedOptionsAndNotDisabled = this.ticketManagementCheckOrReplacementGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }

  onTicketManagementInstallationSelectionChange(event?: any){
    this.ticketManagementInstallationSelectedOptionsAndNotDisabled = this.ticketManagementInstallationGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }

  onTicketManagementCancellationSelectionChange(event?: any){
    this.ticketManagementCancellationSelectedOptionsAndNotDisabled = this.ticketManagementCancellationGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }

  onWorkplanListSelectionChange(event?: any){
    this.workplanListSelectedOptionsAndNotDisabled = this.workplanListGroup.selectedOptions.selected.filter(option => option.selected && !option.disabled).length == 1;
  }

  applyChanges(){    
    if(this.data.page == 'inventory'){
      if(!this.inventorySelectedOptionsAndNotDisabled){
        return;
      }
      let inventroyValues = this.inventoryGroup.selectedOptions.selected.map(
        option => option.value
      );     
      this.inventorySelectedValues = _.mapValues(this.inventorySelectedValues, () => false); 
      _.each(inventroyValues, (value) => {
        this.inventorySelectedValues[value] = true;
      });
      let data = {
        values: this.inventorySelectedValues
      }
      this.dialogRef.close(data);
    }else if(this.data.page == 'collections'){
      if(!this.collectionsSelectedOptionsAndNotDisabled){
        return;
      }
      let collectionsValues = this.collectionsGroup.selectedOptions.selected.map(
        option => option.value
      );
      this.collectionsSelectedValues = _.mapValues(this.collectionsSelectedValues, () => false); 
      _.each(collectionsValues, (value) => {
        this.collectionsSelectedValues[value] = true;
      });
      let data = {
        values: this.collectionsSelectedValues
      }
      this.dialogRef.close(data);
    }else if(this.data.page == 'ticketManagementCheckOrReplacement'){
      if(!this.ticketManagementCheckOrReplacementSelectedOptionsAndNotDisabled){
        return;
      }
      let ticketManagementValues = this.ticketManagementCheckOrReplacementGroup.selectedOptions.selected.map(
        option => option.value
      );
      this.ticketManagementCheckOrReplacementSelectedValues = _.mapValues(this.ticketManagementCheckOrReplacementSelectedValues, () => false); 
      _.each(ticketManagementValues, (value) => {
        this.ticketManagementCheckOrReplacementSelectedValues[value] = true;
      });
      let data = {
        values: this.ticketManagementCheckOrReplacementSelectedValues
      }
      this.dialogRef.close(data);
    }else if(this.data.page == 'ticketManagementInstallation'){
      if(!this.ticketManagementInstallationSelectedOptionsAndNotDisabled){
        return;
      }
      let ticketManagementValues = this.ticketManagementInstallationGroup.selectedOptions.selected.map(
        option => option.value
      );
      this.ticketManagementInstallationSelectedValues = _.mapValues(this.ticketManagementInstallationSelectedValues, () => false); 
      _.each(ticketManagementValues, (value) => {
        this.ticketManagementInstallationSelectedValues[value] = true;
      });
      let data = {
        values: this.ticketManagementInstallationSelectedValues
      }
      this.dialogRef.close(data);
    }else if(this.data.page == 'ticketManagementCancellation'){
      if(!this.ticketManagementCancellationSelectedOptionsAndNotDisabled){
        return;
      }
      let ticketManagementValues = this.ticketManagementCancellationGroup.selectedOptions.selected.map(
        option => option.value
      );
      this.ticketManagementCancellationSelectedValues = _.mapValues(this.ticketManagementCancellationSelectedValues, () => false); 
      _.each(ticketManagementValues, (value) => {
        this.ticketManagementCancellationSelectedValues[value] = true;
      });
      let data = {
        values: this.ticketManagementCancellationSelectedValues
      }
      this.dialogRef.close(data);
    }else if(this.data.page == 'workplanList'){
      if(!this.workplanListSelectedOptionsAndNotDisabled){
        return;
      }
      let workplanValues = this.workplanListGroup.selectedOptions.selected.map(
        option => option.value
      );
      this.workplanListSelectedValues = _.mapValues(this.workplanListSelectedValues, () => false); 
      _.each(workplanValues, (value) => {
        this.workplanListSelectedValues[value] = true;
      });
      let data = {
        values: this.workplanListSelectedValues
      }
      this.dialogRef.close(data);
    }    
  }
}
